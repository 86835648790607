function getSliceColor(p) {
  if (p >= 70 && p <= 100) {
    return "#4BC876";
  } else if (p >= 50 && p <= 69) {
    return "orange";
  } else {
    return "red";
  }
}

export default getSliceColor;

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const REGISTER = 'REGISTER'

export const API_CALL_IN_PROGRESS = 'API_CALL_IN_PROGRESS'
export const API_CALL_NOT_IN_PROGRESS = 'API_CALL_NOT_IN_PROGRESS'

export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export const GETMESSAGES = 'GETMESSAGES'
export const SETMESSAGES = 'SETMESSAGES'

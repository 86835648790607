import { combineReducers } from 'redux'

import authReducer from './auth.reducer'
import messagesReducer from './messages.reducer'
import apiCallStatusReducer from './apiCallStatus.reducer'

const rootReducer = combineReducers({
	authReducer,
	messagesReducer,
	apiCallStatusReducer,
})

export default rootReducer

import { API_CALL_NOT_IN_PROGRESS, SETMESSAGES } from '../types/index'
import TicketService from '../../api-services/ticket.service'

export const listMessagesByTicket = params => dispatch => {
	return TicketService.listMessagesByTicket(params)
		.then(data => {
			dispatch({ type: SETMESSAGES, payload: data.supportTicketMessages })
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
		})
		.catch(err => {
			dispatch({ type: API_CALL_NOT_IN_PROGRESS })
			throw err
		})
}

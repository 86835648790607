import { Modal } from 'bootstrap'
import { useParams } from 'react-router-dom'
import Progressbar from 'react-js-progressbar'
import './grader_report/potentialInboxing.scss'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import getSliceColor from './grader_report/getColor'
import OverallResult from './grader_report/OverallResult'
import UserService from '../../../api-services/user.service'
import EmailBodyInsight from './grader_report/EmailBodyInsight'
import ProvidersResults from './grader_report/ProvidersResults'
import ProgressBarHorizontal from './grader_report/ProgressBarHorizontal'
import SubjectHeadlineInsight from './grader_report/SubjectHeadlineInsight'

const GraderStats = () => {
	const { id } = useParams()
	const [loading, setLoading] = useState(true)
	const [usageData, setUsageData] = useState([])
	const [usageCount, setUsageCount] = useState(0)
	const [graderReport, setGraderReport] = useState({})

	const modalInstanceSnapshot = document.getElementById('graderReportModal')

	const fetchData = async () => {
		setLoading(true)
		const response = await UserService.getGraderStatsByUser(id)

		if (response.success) {
			setLoading(false)
			setUsageData(response.user_potential_inbox_list)
			setUsageCount(response.user_Potential_inbox_count)
		}
	}

	const openModal = () => {
		var modalInstance = Modal.getOrCreateInstance(modalInstanceSnapshot)
		modalInstance.show()
	}

	const closeModal = () => {
		var modalInstance = Modal.getInstance(modalInstanceSnapshot)
		modalInstance.hide()
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='card mb-3 flex-grow-1 mr-2'>
					<div className='row g-0'>
						<div className='col-12'>
							<div className='card-body pl-0 py-4'>
								{/* <h5 className='card-title mb-0 font-weight-bold'>
									{usageCount}
								</h5> */}

								<h5 className='card-text text-center text-muted'>
									Grader Used {usageCount} times
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
			{loading ? (
				<div
					style={{
						height: '30px',
						marginLeft: '50%',
						marginBottom: '20px',
					}}
				>
					<FadeLoader width={5} height={20} loading={true} color={'#0092FB'} />
				</div>
			) : (
				<div className='row'>
					<div className='table-responsive'>
						<table className='table table-sm'>
							<thead>
								<tr>
									<th>
										Type
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
									<th className='text-center'>
										Category
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
									<th className='text-center'>
										Report
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{usageData.length != 0
									? usageData.map((data, i) => (
											<tr key={i}>
												<td>{data.email_type}</td>
												<td className='text-center'>{data.email_category}</td>
												<td className='text-center'>
													<button
														className='btn btn-info ml-2 btn-circle btn-sm'
														onClick={() => {
															setGraderReport(JSON.parse(data.report))
															openModal()
														}}
													>
														<i className='fas fa-eye'></i>
													</button>
												</td>
											</tr>
									  ))
									: ''}
							</tbody>
						</table>
					</div>
				</div>
			)}
			<div
				tabindex='-1'
				class='modal fade'
				aria-hidden='true'
				id='graderReportModal'
				aria-labelledby='graderReportModal'
			>
				<div class='modal-dialog' style={{ maxWidth: '100%' }}>
					<div class='modal-content'>
						<div class='modal-header d-flex align-items-center'>
							<h5 class='modal-title' id='graderReportModal'>
								Report Snapshot
							</h5>
							<i
								style={{
									cursor: 'pointer',
								}}
								onClick={closeModal}
								className='far fa-times'
							></i>
						</div>
						<div class='modal-body'>
							<div className='container-fluid'>
								<div className='row header-bar mt-4 p-3 mr-0 bg-white'>
									<div className='col-3 pl-0'>
										<div className='card flex-row py-3 analyzer-card'>
											<div
												className='card-body p-0'
												style={{ display: 'table' }}
											>
												<div className='text-center'>
													<Progressbar
														size={100}
														pathWidth={30}
														trailWidth={50}
														clockwise={false}
														pathShadow='none'
														trailColor='#F2F2F2'
														pathLinecap={'round'}
														animation={{ ease: 'linear' }}
														input={
															Object.keys(graderReport).length === 0
																? 0
																: graderReport.summary !== undefined
																? graderReport.summary.details[0].percentage
																: ''
														}
														pathColor={getSliceColor(
															Object.keys(graderReport).length === 0
																? 0
																: graderReport.summary !== undefined
																? graderReport.summary.details[0].percentage
																: ''
														)}
														textStyle={{
															fontSize: '44px',
															fontWeight: '800',
															fill: getSliceColor(
																Object.keys(graderReport).length === 0
																	? 0
																	: graderReport.summary !== undefined
																	? graderReport.summary.details[0].percentage
																	: ''
															),
														}}
													></Progressbar>
												</div>
												<div className='overallResult px-4 mb-4'>
													<ProgressBarHorizontal
														report={
															Object.keys(graderReport).length === 0
																? null
																: graderReport.summary !== undefined
																? graderReport.summary.details[0]
																: ''
														}
													/>
													<ProgressBarHorizontal
														report={
															Object.keys(graderReport).length === 0
																? null
																: graderReport.summary !== undefined
																? graderReport.summary.details[1]
																: ''
														}
													/>
													<ProgressBarHorizontal
														report={
															Object.keys(graderReport).length === 0
																? null
																: graderReport.summary !== undefined
																? graderReport.summary.details[2]
																: ''
														}
													/>
													<ProgressBarHorizontal
														report={
															Object.keys(graderReport).length === 0
																? null
																: graderReport.summary !== undefined
																? graderReport.summary.details[3]
																: ''
														}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='col-9'>
										{graderReport.length == 0 ? (
											<div
												className='text-center'
												style={{ marginTop: '150px' }}
											>
												<FadeLoader
													width={5}
													height={20}
													color={'#0092FB'}
													loading={graderReport.length === 0 ? true : false}
												/>
											</div>
										) : (
											<>
												<div id='plan-card' className='px-4 bg-light rounded'>
													<div className='d-flex p-2 my-3'>
														<div className='icon-bg d-flex'>
															<img
																src='/assets/img/analyzer-overall-result.png'
																alt=''
															/>
														</div>
														<div className='ml-3 mt-3'>
															<b>
																Overall % of Inboxing On The Following Providers
															</b>
														</div>
													</div>
													{Object.keys(graderReport).length === 0 ? (
														<></>
													) : (
														<OverallResult
															percentage={
																graderReport.potentialInBoxing !== undefined
																	? graderReport.potentialInBoxing.overallResult
																			.percentage
																	: ''
															}
														/>
													)}
													{Object.keys(graderReport).length === 0 ? (
														<></>
													) : (
														<ProvidersResults
															potentialInboxingReport={
																graderReport.potentialInBoxing !== undefined
																	? graderReport.potentialInBoxing.overallResult
																			.details
																	: ''
															}
														/>
													)}
												</div>
												<div
													id='plan-card'
													className='px-3 py-1 mt-3 bg-light rounded'
												>
													{Object.keys(graderReport).length === 0 ? (
														<></>
													) : (
														<SubjectHeadlineInsight
															subjectHeadLineInsight={
																graderReport.contentInsight !== undefined
																	? graderReport.contentInsight
																			.subjectHeadlineInsight
																	: ''
															}
														/>
													)}
												</div>
												<div
													id='plan-card'
													className='px-3 py-1 mt-3 bg-light rounded'
												>
													{Object.keys(graderReport).length === 0 ? (
														<></>
													) : (
														<EmailBodyInsight
															bodyStructureinsight={
																graderReport.contentInsight !== undefined
																	? graderReport.contentInsight
																			.bodyStructureInsight.overallResult
																	: ''
															}
														/>
													)}
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GraderStats

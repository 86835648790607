import API from './api'

const CroneJobsService = {
	getDropdownItems: () => {
		return API.get(`/admin/warmupcronjobs`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				console.log('User service err', err)
				throw err
			})
	},
	sendButtonUrl: cmd => {
		return API.post(`admin/warmupcronjobs/${cmd}`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				console.log('User service err', err)
				throw err
			})
	},
}

export default CroneJobsService

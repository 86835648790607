import API from './api'

const SystemFeaturesService = {
	list: () => {
		return API.get(`/admin/systemfeature`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				console.log('User service err', err)
				throw err
			})
	},
	get: id => {
		return API.get(`/admin/systemfeature/${id}`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				console.log('User service err', err)
				throw err
			})
	},
}

export default SystemFeaturesService

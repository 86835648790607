import FaqModal from './FaqModal'
import { Modal } from 'bootstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import FaqService from '../../api-services/faq.service'
import SystemFeaturesService from '../../api-services/systemFeatures.service'

const Faq = () => {
	const [faqs, setFaqs] = useState([])
	const [error, setError] = useState('')
	const [errors, setErrors] = useState({})
	const [faqForm, setFaqForm] = useState({
		tag: '',
		status: 1,
		answer: '',
		question: '',
	})
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [usageFlag, setUsageFlag] = useState('add')
	const [btnLoading, setBtnLoading] = useState(false)
	const [systemFeatures, setSystemFeatures] = useState([])
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const modalInstance = document.getElementById('faqModal')

	const fetchFaqs = async () => {
		setLoading(true)
		try {
			let tempMetaDataObj = { per_page: 0, total: 0 }

			const response = await FaqService.list()
			tempMetaDataObj.total = response.faqs.total
			tempMetaDataObj.per_page = response.faqs.per_page

			setFaqs(response.faqs.data)

			setMetaData(tempMetaDataObj)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}

	const fetchSystemFeatures = async () => {
		try {
			const response = await SystemFeaturesService.list()

			setSystemFeatures(response.systemFeature)
		} catch (error) {
			console.log(error)
		}
	}

	const closeModal = () => {
		const modal = Modal.getInstance(modalInstance)

		modal.hide()
	}

	const openModal = () => {
		const modal = Modal.getOrCreateInstance(modalInstance)

		modal.show()
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)
		try {
			let tempMetaDataObj = { per_page: 0, total: 0 }

			const response = await FaqService.list(pageNumber)
			tempMetaDataObj.total = response.faqs.total
			tempMetaDataObj.per_page = response.faqs.per_page

			setFaqs(response.faqs.data)
			setMetaData(tempMetaDataObj)
			setActivePage(pageNumber)
			if (response.length !== 0) {
				setLoading(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleFaqFormChange = e => {
		const { name, value } = e.target

		setFaqForm({ ...faqForm, [name]: value })
	}

	const handleEditFaq = async event => {
		event.preventDefault()
		setLoading(true)

		let tempFaqForm = faqForm

		delete tempFaqForm.created_at
		delete tempFaqForm.updated_at

		try {
			const response = await FaqService.update(tempFaqForm)

			if (response.success) {
				toast.success(response.message)
				fetchFaqs()
				closeModal()
			}
		} catch (e) {
			console.log(e)

			if (e.response.status === 422) {
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	const handleAddNewFaq = async event => {
		event.preventDefault()
		setLoading(true)

		try {
			const response = await FaqService.add(faqForm)

			if (response.success) {
				toast.success(response.message)
				fetchFaqs()
				closeModal()
			}
		} catch (e) {
			console.log(e)

			if (e.response.status === 422) {
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	// const disablePlan = async plan => {
	// 	let tempPlan = {}

	// 	tempPlan = plan

	// 	tempPlan.is_disabled = !tempPlan.is_disabled

	// 	const response = await PlanService.disable(plan.id, {
	// 		is_disabled: tempPlan.is_disabled,
	// 	})

	// 	if (response.success) fetchFaqs()
	// }

	useEffect(() => {
		fetchFaqs()
		fetchSystemFeatures()

		return () => {
			setFaqs([])
			setFaqForm({})
			setLoading(false)
		}
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>FAQ</li>
						</ol>
					</nav>
				</div>
			</div>

			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row'>
						<div className='col-sm-6'>
							<h4 className='m-0 font-weight-bold text-primary'>FAQs</h4>
						</div>
						<div className='col text-right'>
							<button
								type='button'
								onClick={() => {
									setFaqForm({
										tag: '',
										status: 1,
										answer: '',
										question: '',
									})
									setUsageFlag('add')
									openModal()
								}}
								className='btn btn-sm c-btn'
								style={{
									outline: 'none',
									backgroundColor: '#0994F8',
								}}
							>
								<i class='far fa-plus mr-2'></i>
								Add New FAQ
							</button>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									height: '30px',
									marginLeft: '50%',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									width={5}
									height={20}
									loading={true}
									color={'#0092FB'}
								/>
							</div>
						) : faqs.length === 0 ? (
							<div className='text-center starter-inboxes'>
								<p
									className='sp mt-1'
									style={{ fontSize: '20px', lineHeight: '30px' }}
								>
									<b>No FAQs Found</b>
								</p>
							</div>
						) : (
							<>
								<div className='table-responsive'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th>Actions</th>
												<th>
													ID
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th>
													Tag
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th>
													Status
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th>Question</th>
												<th>Answer</th>
											</tr>
										</thead>
										{faqs.map((faq, i) => (
											<tbody key={i}>
												<tr>
													<td className='dns_email_item'>
														{/* <button className='btn btn-primary ml-2 btn-circle btn-sm'>
															<i className='fas fa-pencil-alt'></i>
														</button> */}
														<button
															className='btn btn-info ml-2 btn-circle btn-sm'
															onClick={() => {
																setFaqForm(faq)
																setUsageFlag('edit')
																openModal()
															}}
														>
															<i className='fas fa-eye'></i>
														</button>
														&nbsp;&nbsp;
														{/* <button
															className={`btn btn-sm ${
																faq.is_disabled === 1
																	? 'btn-success'
																	: 'btn-danger'
															}`}
															onClick={() => disablePlan(plan)}
															disabled={btnLoading}
														>
															{plan.is_disabled === 1 ? 'Activate' : 'Disable'}
														</button> */}
													</td>
													<td className='dns_email_item'>{faq.id}</td>
													<td className='dns_email_item'>{faq.tag}</td>
													<td className='dns_email_item'>
														{faq.status ? 'Active' : 'Vaulted'}
													</td>
													<td className='dns_email_item'>{faq.question}</td>
													<td className='dns_email_item'>{faq.answer}</td>
												</tr>
											</tbody>
										))}
									</table>
								</div>
								<div className='float-right mr-4'>
									<Pagination
										itemClass='page-item'
										linkClass='page-link'
										pageRangeDisplayed={4}
										activePage={activePage}
										totalItemsCount={metaData.total}
										itemsCountPerPage={metaData.per_page}
										onChange={pageNumber => handlePageChange(pageNumber)}
									/>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<FaqModal
				loading={loading}
				faqForm={faqForm}
				usageFlag={usageFlag}
				closeModal={closeModal}
				handleEditFaq={handleEditFaq}
				systemFeatures={systemFeatures}
				handleAddNewFaq={handleAddNewFaq}
				handleFaqFormChange={handleFaqFormChange}
			/>
		</>
	)
}

export default Faq

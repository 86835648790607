import { Modal } from 'bootstrap'
import PlanModal from './PlanModal'
import React, { useState, useEffect } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import { Link, useParams } from 'react-router-dom'
import PlanService from '../../api-services/plan.service'
import SystemFeaturesService from '../../api-services/systemFeatures.service'

const ViewPlan = () => {
	const { id } = useParams()

	const [plan, setPlan] = useState({})
	const [planForm, setPlanForm] = useState({
		name: '',
		price: '',
		group_tag: '',
		systemFeature: [],
		duration_in_days: '',
	})
	const [loading, setLoading] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [systemFeatures, setSystemFeatures] = useState([])

	const modalInstance = document.getElementById('planModal')

	const closeModal = () => {
		const modal = Modal.getInstance(modalInstance)

		modal.hide()
	}

	const openModal = () => {
		const modal = Modal.getOrCreateInstance(modalInstance)

		modal.show()
	}

	const fetchPlanDetails = async () => {
		setLoading(true)
		try {
			const response = await PlanService.get(id)

			let tempPlanObj = {
				name: '',
				price: '',
				systemFeature: [],
				is_disabled: false,
				duration_in_days: '',
			}

			tempPlanObj.name = response.Package.name
			tempPlanObj.price = response.Package.price
			tempPlanObj.group_tag = response.Package.group_tag
			tempPlanObj.is_disabled = response.Package.is_disabled
			tempPlanObj.duration_in_days = response.Package.duration_in_days
			if (response.Package.packagefeatures !== undefined)
				response.Package.packagefeatures.forEach((feature, i) => {
					tempPlanObj.systemFeature.push({
						id: feature.system_feature_id,
						max_allowed_count: feature.max_allowed_count,
					})
				})

			setPlanForm(tempPlanObj)
			setPlan(response.Package)
			setLoading(false)
		} catch (error) {
			setLoading(false)

			console.log(error)
		}
	}

	const fetchSystemFeatures = async () => {
		try {
			let tempArr = []
			const response = await SystemFeaturesService.list()

			setSystemFeatures(response.systemFeature)
		} catch (error) {
			console.log(error)
		}
	}

	const handleEditPlan = async () => {
		setUpdating(true)
		try {
			let tempObj = planForm

			delete tempObj.name

			setPlanForm(tempObj)

			const response = await PlanService.update(id, planForm)

			if (response.success) {
				fetchPlanDetails()
				closeModal()
			}
		} catch (error) {
			setLoading(false)
			setUpdating(false)
		} finally {
			setLoading(false)
			setUpdating(false)
		}
	}

	const handlePlanFormChange = event => {
		event.preventDefault()

		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		if (name === 'undefined' || name === undefined) {
			let found = false

			planForm.systemFeature.forEach(systemFeature => {
				if (
					systemFeature.max_allowed_count !== 0 &&
					systemFeature.id === parseInt(target.dataset.id) &&
					!found
				) {
					found = true
					let tempArr = []

					tempArr = planForm.systemFeature

					// setPlanForm({
					// 	...planForm,
					// 	systemFeature: tempArr.filter(
					// 		feature => feature.id !== parseInt(target.dataset.id)
					// 	),
					// })

					//New Logic

					tempArr.forEach(singleItem => {
						if (singleItem.id === parseInt(target.dataset.id)) {
							singleItem.max_allowed_count = 0
						}
					})

					setPlanForm({ ...planForm, systemFeature: tempArr })
				}
			})

			if (!found) {
				let tempArr = []
				tempArr = planForm.systemFeature

				// tempArr.push({ id: parseInt(target.dataset.id), max_allowed_count: 0 })

				// setPlanForm({ ...planForm, systemFeature: tempArr })

				//New Logic

				tempArr.forEach(singleItem => {
					if (singleItem.id === parseInt(target.dataset.id)) {
						singleItem.max_allowed_count = 1
					}
				})

				setPlanForm({ ...planForm, systemFeature: tempArr })
			}
		} else if (name === 'max_allowed_count') {
			planForm.systemFeature.forEach(systemFeature => {
				if (systemFeature.id === parseInt(target.dataset.id)) {
					let tempArr = []
					let filteredArr = []

					tempArr = planForm.systemFeature

					filteredArr = tempArr.filter(
						feature => feature.id !== parseInt(target.dataset.id)
					)

					filteredArr.push({
						id: parseInt(target.dataset.id),
						max_allowed_count: parseInt(target.value),
					})

					setPlanForm({
						...planForm,
						systemFeature: filteredArr,
					})
				}
			})
		} else if (name === 'group_tag') {
			setPlanForm({ ...planForm, [name]: value })
			if (value === 'Life Time') {
				setPlanForm({ ...planForm, duration_in_days: 0 })
			} else if (value === 'Trial') {
				setPlanForm({ ...planForm, duration_in_days: 30 })
			}
		} else {
			setPlanForm({ ...planForm, [name]: value })
		}
	}

	useEffect(() => {
		fetchPlanDetails()
		fetchSystemFeatures()

		return () => {
			setPlan([])
			setLoading(false)
		}
	}, [])

	return (
		<>
			{loading ? (
				<div
					style={{
						height: '30px',
						marginTop: '25%',
						marginLeft: '50%',
						marginBottom: '20px',
					}}
				>
					<FadeLoader loading={true} color={'#0092FB'} height={20} width={5} />
				</div>
			) : (
				<>
					<div className='row mt-4'>
						<div className='col'>
							<nav aria-label='breadcrumb'>
								<ol className='breadcrumb'>
									<li className='breadcrumb-item'>
										<Link
											to={{
												pathname: `/`,
											}}
										>
											Home
										</Link>
									</li>
									<li className='breadcrumb-item'>
										<Link
											to={{
												pathname: `/plans`,
											}}
										>
											Plans
										</Link>
									</li>
									<li className='breadcrumb-item active' aria-current='page'>
										{id}
									</li>
								</ol>
							</nav>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='card'>
								<div className='card-header d-flex align-items-center justify-content-between'>
									<h4 className='mb-0' style={{ fontWeight: '600' }}>
										{plan.name}
									</h4>
									<button onClick={openModal} className='btn btn-primary'>
										<i class='far fa-edit mr-1'></i> Edit Plan
									</button>
								</div>
								<div className='card-body'>
									<div className='row p-3'>
										<div className='col card p-3'>
											<div className='row d-flex align-items-center justify-content-center'>
												<h4 style={{ fontWeight: '700', color: '#4C71DE' }}>
													Plan Name
												</h4>
												<h4 className='ml-2'>{plan.name}</h4>
											</div>
										</div>
										<div className='col card p-3 ml-3'>
											<div className='row d-flex align-items-center justify-content-center'>
												<h4 style={{ fontWeight: '700', color: '#4C71DE' }}>
													Plan Price
												</h4>
												<h4 className='ml-2'>$ {plan.price}</h4>
											</div>
										</div>
									</div>
									<div className='d-flex'>
										{plan.packagefeatures !== undefined ? (
											plan.packagefeatures.length === 0 ? (
												<p className='text-center'>No Features in this Plan</p>
											) : (
												<div className='col'>
													<div className='row mb-2'>
														<div className='col'>
															<h5
																className='mb-0'
																style={{ fontWeight: '600', color: '#4C71DE' }}
															>
																System Features Allowed in {plan.name}
															</h5>
														</div>
													</div>
													<div className='d-flex flex-row align-items-center'>
														<table className='table table-sm'>
															<thead>
																<tr>
																	<th scope='col'>Feature</th>
																	<th scope='col' className='text-center'>
																		Allowed Usage
																	</th>
																</tr>
															</thead>
															<tbody>
																{plan.packagefeatures.map((feature, i) => (
																	<tr key={i}>
																		<th
																			scope='row'
																			className='dns_email_item py-2'
																		>
																			{feature.system_feature.name}
																		</th>
																		<td className='dns_email_item py-2 text-center'>
																			{feature.max_allowed_count}
																			{feature.system_feature_id === 1
																				? ' / month'
																				: ' / day'}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													</div>
												</div>
											)
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<PlanModal
				usageFlag='edit'
				planForm={planForm}
				updating={updating}
				closeModal={closeModal}
				setPlanForm={setPlanForm}
				handleEditPlan={handleEditPlan}
				systemFeatures={systemFeatures}
				handlePlanFormChange={handlePlanFormChange}
			/>
		</>
	)
}

export default ViewPlan

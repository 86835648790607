import React, { useEffect } from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const addData = (chart, data, series) => {
	/* Set real data */
	chart.data = data

	/* Hide series and show again to play the animation */
	series.hide(0)
	series.show()
}

const DonutChart = props => {
	let id = props.id
	let statsFor = props.statsFor

	useEffect(() => {
		var data = [
			{
				category: `${
					statsFor === 'user'
						? 'Active Users'
						: statsFor === 'ticket'
						? 'Open Tickets'
						: statsFor === 'campaign'
						? 'Active Campaigns'
						: ''
				}`,
				rate: props.active,
				color: statsFor === 'ticket' ? '#ff3300' : '#1CC88A',
			},
			{
				category: `${
					statsFor === 'user'
						? 'Inactive Users'
						: statsFor === 'ticket'
						? 'Closed Tickets'
						: statsFor === 'campaign'
						? 'Inactive Campaigns'
						: ''
				}`,
				rate: props.inactive,
				color: statsFor === 'ticket' ? '#1CC88A' : '#ff3300',
			},
		]

		/* Create chart */
		var chart = am4core.create(id, am4charts.PieChart)
		// chart.radius = am4core.percent(60);
		chart.innerRadius = am4core.percent(40)

		/* Dummy innitial data data */
		chart.data = [
			{
				rate: 100.0,
				opacity: 0.3,
				disabled: true,
				category: 'Chart Data',
				strokeDasharray: '4,4',
				color: am4core.color('#dadada'),
			},
		]

		/* Create series */
		var series = chart.series.push(new am4charts.PieSeries())
		series.numberFormatter = new am4core.NumberFormatter()
		series.dataFields.value = 'rate'
		series.dataFields.category = 'category'

		// series.slices.template.stroke = am4core.color('#fff')
		// series.slices.template.strokeWidth = 1
		// series.slices.template.strokeOpacity = 1
		series.slices.template.cursorOverStyle = [
			{
				property: 'cursor',
				value: 'pointer',
			},
		]

		series.labels.template.disabled = true

		/* Setup slice appearance */
		var slice = series.slices.template
		slice.propertyFields.fill = 'color'
		// slice.propertyFields.fillOpacity = "opacity";
		// slice.propertyFields.stroke = "color";
		// slice.propertyFields.strokeDasharray = "strokeDasharray";
		// slice.propertyFields.tooltipText = "tooltip";

		series.labels.template.propertyFields.disabled = 'disabled'
		series.ticks.template.propertyFields.disabled = 'disabled'

		/* This creates initial animation */
		series.hiddenState.properties.opacity = 1
		series.hiddenState.properties.endAngle = -90
		series.hiddenState.properties.startAngle = -90

		let shadow = series.slices.template.filters.push(
			new am4core.DropShadowFilter()
		)
		shadow.opacity = 0

		let hoverState = series.slices.template.states.getKey('hover')

		let hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter())
		hoverShadow.opacity = 0.4
		hoverShadow.blur = 4

		chart.legend = new am4charts.Legend()
		chart.legend.itemContainers.template.clickable = false
		chart.legend.itemContainers.template.focusable = false
		chart.legend.itemContainers.template.cursorOverStyle =
			am4core.MouseCursorStyle.default
		chart.legend.valueLabels.template.text = '{rate}'
		chart.legend.maxWidth = 400
		chart.legend.marginLeft = 0
		chart.legend.marginRight = 0

		if (props.active > 0 || props.inactive > 0) {
			addData(chart, data, series)
		}
	}, [props.active, props.inactive])

	return (
		<div>
			<div
				id={id}
				style={{ height: props.height, width: '100%', position: 'relative' }}
			></div>
		</div>
	)
}

export default DonutChart

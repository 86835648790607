import './potentialInboxing.scss'
import getSliceColor from './getColor'
import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const EmailBodyCardNew = ({
	msg,
	name,
	help,
	color,
	suggested,
	percentage,
	description,
	customPercentage,
}) => {
	const index = ''
	const [clicked, setClicked] = useState(false)

	const toggle = index => {
		if (clicked === index) {
			return setClicked(null)
		}
		setClicked(index)
	}

	return (
		<div className='AccordionSection'>
			<div className='AccContainer'>
				<div className='Wrap d-flex'>
					<div className='d-flex'>
						<div onClick={() => toggle(index)} key={index}>
							<div
								className='accordion-icon'
								style={{
									color: color,
									background: '#ffffff',
									filter: percentage === 'block' ? `blur(${'5px'})` : '',
								}}
							>
								{/* <div className='col-3 ml-2 my-2 text-center'> */}
								{percentage >= 70 && percentage <= 100 ? (
									<i className='far fa-smile smiley-face'></i>
								) : percentage >= 50 && percentage <= 69 ? (
									<i className='far fa-meh smiley-face'></i>
								) : percentage >= 0 && percentage <= 49 ? (
									<i className='far fa-frown smiley-face'></i>
								) : percentage === 'block' ? (
									customPercentage >= 70 && customPercentage <= 100 ? (
										<i className='far fa-smile smiley-face'></i>
									) : customPercentage >= 50 && customPercentage <= 69 ? (
										<i className='far fa-meh smiley-face'></i>
									) : customPercentage >= 0 && customPercentage <= 49 ? (
										<i className='far fa-frown smiley-face'></i>
									) : (
										<i className='far fa-frown smiley-face'></i>
									)
								) : (
									<i className='far fa-frown smiley-face'></i>
								)}
								{/* </div> */}
							</div>
							<p>
								<b>{name}</b>
							</p>
						</div>
					</div>
					<div
						key={index}
						onClick={() => toggle(index)}
						className='right-part flex-grow-1'
					>
						<div className='accordion-right d-flex justify-content-end mt-3'>
							<button
								style={{
									width: '70px',
									outline: 'none',
									backgroundColor: color,
								}}
							>
								{color === 'green' ? 'Optimal' : 'Poor'}
							</button>
							<b>{clicked === index ? <FiChevronUp /> : <FiChevronDown />}</b>
						</div>
					</div>
				</div>
				{clicked === index ? (
					<div className='Dropdown'>
						<div className='content'>
							<div
								style={{
									filter: percentage === 'block' ? `blur(${'5px'})` : '',
								}}
							>
								{description === 'block' ? (
									<span>
										{name === 'Word Count'
											? msg
											: // "Your email contains 120 word(s) which is Optimal Amount for Ecommerce Emails. Optimal range is beetween 50 to 350 words."
											name === 'Number of Paragraphs'
											? msg
											: // "Your email contains 8 Paragraphs(s) this is Optimal amount for Ecommerce Emails. Optimal range is beetween 15 to 25 paragraphs."
											name === 'Average Paragraph Length'
											? msg
											: // "Your average paragraph length is 16 word(s) this is Optimal amount for Ecommerce Emails. Optimal range is beetween 1 to 350 words."
											name === 'Reading Level'
											? msg
											: // "Your email current reading level is at the Post Graduate (Poor) Level"
											name === "Ratio of I's and You's"
											? msg
											: // "Your current ratio is 10% based on the I's and You's in email. Ratio of I's must be greater than You's."
											name === "Punctuations and Emoji's ratio"
											? msg
											: // "Your email contains 1 Emoji(s) and 0 exclamations mark(s) This is Optimal. Total number of exclamation and emojis must be between 1 to 7."
											name === 'Image to Text Ratio'
											? msg
											: // "Your current email image to text ratio is 53% . keep your image to text ratio between 20% - 40% images and 80% - 60% text."
											name === 'Number of Links'
											? msg
											: // "Your email contains 6 link(s). Optimal number of links should be between 3 to 10."
											  ''}
									</span>
								) : (
									<span
										dangerouslySetInnerHTML={{
											__html: description,
										}}
									></span>
								)}
							</div>
							<div
								className='progress'
								style={{
									filter: percentage === 'block' ? `blur(${'5px'})` : '',
								}}
							>
								<div
									className='progress-bar'
									role='progressbar'
									style={{
										borderRadius: '15px',
										width: `${
											percentage === 'block'
												? Math.floor(Math.random() * (90 - 10) + 10)
												: percentage
										}%`,
										backgroundColor: getSliceColor(
											`${
												percentage === 'block'
													? Math.floor(Math.random() * (90 - 10) + 10)
													: percentage
											}`
										),
									}}
									aria-valuemin='0'
									aria-valuemax='100'
								>
									{percentage === 'block'
										? Math.floor(Math.random() * (90 - 10) + 10)
										: percentage}
								</div>
							</div>
							<div className='d-flex flex-column mt-3'>
								{help === undefined ? <></> : <b>Actions to Improve Score</b>}
								<span>{help}</span>
								{suggested === undefined ? (
									<></>
								) : (
									<div className='m-2'>
										<b>Example of Optimal Email Content</b>
										<div
											style={{
												border: '0.4px solid #dee1e6',
												borderRadius: '3px',
											}}
											className='mt-1 p-1'
										>
											<span
												dangerouslySetInnerHTML={{
													__html: suggested,
												}}
											></span>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				) : null}
			</div>
		</div>
		// <div className='col-12 mb-3'>
		// 	<div className='card flex-row py-3 insight-card analyzer-card'>
		// <div
		// 	className='col-3 ml-2 my-2 text-center'
		// 	style={{ filter: percentage === 'block' ? `blur(${'5px'})` : '' }}
		// >
		// 	{percentage >= 70 && percentage <= 100 ? (
		// 		<i className='far fa-smile smiley-face'></i>
		// 	) : percentage >= 50 && percentage <= 69 ? (
		// 		<i className='far fa-meh smiley-face'></i>
		// 	) : percentage >= 0 && percentage <= 49 ? (
		// 		<i className='far fa-frown smiley-face'></i>
		// 	) : percentage === 'block' ? (
		// 		customPercentage >= 70 && customPercentage <= 100 ? (
		// 			<i className='far fa-smile smiley-face'></i>
		// 		) : customPercentage >= 50 && customPercentage <= 69 ? (
		// 			<i className='far fa-meh smiley-face'></i>
		// 		) : customPercentage >= 0 && customPercentage <= 49 ? (
		// 			<i className='far fa-frown smiley-face'></i>
		// 		) : (
		// 			<i className='far fa-frown smiley-face'></i>
		// 		)
		// 	) : (
		// 		<i className='far fa-frown smiley-face'></i>
		// 	)}
		// </div>
		// 		<div className='card-body d-flex flex-column'>
		// 			<div className=''>
		// 				<div>
		// 					<b
		// 						className='card-title h5 h4-sm'
		// 						style={{
		// 							color: color,
		// 							fontWeight: 'bold',
		// 							fontSize: '20px',
		// 							fontFamily: 'Avenir LT Pro',
		// 						}}
		// 					>
		// 						{name}
		// 					</b>
		// 				</div>
		// <div
		// 	style={{ filter: percentage === 'block' ? `blur(${'5px'})` : '' }}
		// >
		// 	{description === 'block' ? (
		// 		<span>
		// 			{name === 'Word Count'
		// 				? msg
		// 				: // "Your email contains 120 word(s) which is Optimal Amount for Ecommerce Emails. Optimal range is beetween 50 to 350 words."
		// 				name === 'Number of Paragraphs'
		// 				? msg
		// 				: // "Your email contains 8 Paragraphs(s) this is Optimal amount for Ecommerce Emails. Optimal range is beetween 15 to 25 paragraphs."
		// 				name === 'Average Paragraph Length'
		// 				? msg
		// 				: // "Your average paragraph length is 16 word(s) this is Optimal amount for Ecommerce Emails. Optimal range is beetween 1 to 350 words."
		// 				name === 'Reading Level'
		// 				? msg
		// 				: // "Your email current reading level is at the Post Graduate (Poor) Level"
		// 				name === "Ratio of I's and You's"
		// 				? msg
		// 				: // "Your current ratio is 10% based on the I's and You's in email. Ratio of I's must be greater than You's."
		// 				name === "Punctuations and Emoji's ratio"
		// 				? msg
		// 				: // "Your email contains 1 Emoji(s) and 0 exclamations mark(s) This is Optimal. Total number of exclamation and emojis must be between 1 to 7."
		// 				name === 'Image to Text Ratio'
		// 				? msg
		// 				: // "Your current email image to text ratio is 53% . keep your image to text ratio between 20% - 40% images and 80% - 60% text."
		// 				name === 'Number of Links'
		// 				? msg
		// 				: // "Your email contains 6 link(s). Optimal number of links should be between 3 to 10."
		// 				  ''}
		// 		</span>
		// 	) : (
		// 		<span
		// 			dangerouslySetInnerHTML={{
		// 				__html: description,
		// 			}}
		// 		></span>
		// 	)}
		// </div>
		// <div
		// 	className='progress'
		// 	style={{ filter: percentage === 'block' ? `blur(${'5px'})` : '' }}
		// >
		// 	<div
		// 		className='progress-bar'
		// 		role='progressbar'
		// 		style={{
		// 			borderRadius: '15px',
		// 			width: `${
		// 				percentage === 'block'
		// 					? Math.floor(Math.random() * (90 - 10) + 10)
		// 					: percentage
		// 			}%`,
		// 			backgroundColor: getSliceColor(
		// 				`${
		// 					percentage === 'block'
		// 						? Math.floor(Math.random() * (90 - 10) + 10)
		// 						: percentage
		// 				}`
		// 			),
		// 		}}
		// 		aria-valuemin='0'
		// 		aria-valuemax='100'
		// 	>
		// 		{percentage === 'block'
		// 			? Math.floor(Math.random() * (90 - 10) + 10)
		// 			: percentage}
		// 	</div>
		// </div>
		// 			</div>
		// 			<div
		// 				id='accordionExample'
		// 				className='accordion mt-2'
		// 				style={{ borderRadius: '3px' }}
		// 			>
		// 				<div className='accordion-item' style={{ borderRadius: '3px' }}>
		// 					<h2 class='accordion-header' style={{ borderRadius: '3px' }}>
		// 						<button
		// 							data-toggle='collapse'
		// 							style={{
		// 								width: '100%',
		// 								border: 'none',
		// 								outline: 'none',
		// 							}}
		// 							data-target={`#${name.replace(/ /g, '-')}`}
		// 							class='accordion-button d-flex align-items-center p-2'
		// 						>
		// 							<span style={{ fontSize: '18px' }}>Suggestions</span>
		// 						</button>
		// 					</h2>
		// 					<div
		// 						data-parent='#accordionExample'
		// 						id={`${name.replace(/ /g, '-')}`}
		// 						class='accordion-collapse collapse show'
		// 					>
		// 						<div class='accordion-body'>{help}</div>
		// 					</div>
		// 				</div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
	)
}

export default EmailBodyCardNew

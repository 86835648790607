import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5wc from '@amcharts/amcharts5/wc'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

am5.addLicense('ch-custom-attribution')
const WordCloud = ({ words }) => {
	function initiateWordCloud(options, styling, id = 'wordCloud') {
		let root = am5.Root.new(id)
		root.setThemes([am5themes_Animated.new(root)])
		let series = root.container.children.push(
			am5wc.WordCloud.new(root, {
				...options,
			})
		)

		series.labels.template.setAll({
			fontFamily: 'Courier New',
		})
		series.data.setAll(words)

		series.labels.template.setAll({ ...styling })
		// setInterval(function () {
		//   am5.array.each(series.dataItems, function (dataItem) {
		//     let value = Math.random() * 65;
		//     value = value - Math.random() * value;
		//     dataItem.set("value", value);
		//     dataItem.set("valueWorking", value);
		//   });
		// }, 20000);
	}

	useEffect(() => {
		const options = {
			maxCount: 100,
			minWordLength: 2,
			maxFontSize: am5.percent(35),
			categoryField: 'word',
			valueField: 'location',
		}
		const styling = {
			paddingTop: 5,
			paddingBottom: 5,
			paddingLeft: 5,
			paddingRight: 5,
			fontFamily: 'Courier New',
			fill: am5.color(0x0994f8),
		}
		initiateWordCloud(options, styling, 'wordCloud')
	}, [])

	return <div id='wordCloud' style={{ width: '100%', height: '400px' }}></div>
}

export default WordCloud

// import LogsPanel from './LogsPanel'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'
import Pagination from 'react-js-pagination'
import ReactFileReader from 'react-file-reader'
import FadeLoader from 'react-spinners/FadeLoader'
import React, { useState, useEffect } from 'react'
import EmailAccountService from '../../api-services/emailAccount.service'
import EmailAccountProviderService from '../../api-services/EmailAccountProvider.service'

const Loading = ({ type, color }) => (
	<ReactLoading type={type} color={color} height={30} width={30} />
)

const EmailContentList = () => {
	const [logsId, setLogsId] = useState()
	const [show, setShow] = useState(false)
	const [search, setSearch] = useState(false)
	const [metaData, setMetaData] = useState([])
	const [protocol, setProtocol] = useState('')
	const [loading, setLoading] = useState(false)
	const [isDisable, setIsDisable] = useState(0)
	const [isUpdate, setIsupdate] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [activePage, setActivePage] = useState(1)
	const [searchEmail, setSearchEmail] = useState('')
	const [lastLogViewId, setLastLogViewId] = useState(0)
	const [emailAccounts, setEmailAccounts] = useState([])
	const [testButtonId, setTestButtonId] = useState(null)
	const [connectionPurpose, setConnectionPurpose] = useState('')
	const [emailAccountProviders, setEmailAccountProviders] = useState([])
	const [emailAccountProviderId, setEmailAccountProviderId] = useState(0)
	const [emailAccount, setEmailAccount] = useState({
		host: '',
		port: 993,
		username: '',
		password: '',
		type: 'system',
		protocol: 'imap',
		encryption: 'ssl',
		email_account_provider_id: 1,
		connection_purpose: 'warm-up',
	})

	const handlePageChange = async pageNumber => {
		setLoading(true)
		const response = await EmailAccountService.listemailContent(pageNumber)
		setEmailAccounts(response.data)
		setMetaData(response.meta)
		setActivePage(pageNumber)
		if (response.length !== 0) {
			setLoading(false)
		}
		setLoading(false)
	}

	useEffect(() => {
		;(async () => {
			try {
				setIsLoading(true)
				const accounts = await EmailAccountService.listemailContent()
				setEmailAccounts(accounts.data)
				setMetaData(accounts.meta)
				setIsLoading(false)
			} catch (e) {
				setIsLoading(false)
				// Log & handle error
			}
		})()
	}, [search])

	const handleLogView = accId => {
		const lastLogView = document.getElementById(`log-${lastLogViewId}`)

		if (lastLogView) {
			lastLogView.style.display = 'none'
		}

		const currentLogView = document.getElementById(`log-${accId}`)
		currentLogView.style.display = 'block'

		setLastLogViewId(accId)

		if (accId == lastLogViewId && currentLogView.style.display == 'block') {
			currentLogView.style.display = 'none'
			setLastLogViewId(0)
		}
	}

	const testImapConnection = async id => {
		try {
			setIsLoading(true)
			setTestButtonId(id)
			const response = await EmailAccountService.test(id)
			toast.success(response.message, {
				autoClose: 5000,
				draggable: true,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			setIsLoading(false)
		} catch (err) {}
	}

	const handleOpen = id => {
		setLogsId(id)
		if (show) {
			setShow(false)
		} else {
			setShow(true)
		}
	}

	const listEmailAccounts = acc => {
		return (
			<tr key={acc.id}>
				{/* <td>
					<button
						data-toggle='modal'
						onClick={() => {
							setIsupdate(true)
							setEmailAccount(acc)
						}}
						data-target='#accountModal'
						className='btn btn-sm btn-success'
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='16'
							height='16'
							fill='currentColor'
							className='bi bi-pencil-square'
							viewBox='0 0 16 16'
							style={{ cursor: 'pointer' }}
						>
							<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
							<path
								fillRule='evenodd'
								d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
							/>
						</svg>
					</button>
				</td> */}
				<td>{acc.id}</td>
				<td>{acc.subject_line}</td>
				<td>{acc.sent_body}</td>
				<td>{acc.reply_body}</td>
			</tr>
		)
	}

	const handleChange = event => {
		const { name, value } = event.target

		setEmailAccount({
			...emailAccount,
			[name]: value,
		})
	}

	const handleFiles = async files => {
		try {
			setIsLoading(true)
			const response = await EmailAccountService.importEmailContent(
				files.fileList[0]
			)
			const accounts = await EmailAccountService.listemailContent()
			setEmailAccounts(accounts.data)
			setMetaData(accounts.meta)
			toast.success(response.message, {
				autoClose: 5000,
				draggable: true,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			setIsLoading(false)
		} catch (e) {
			// Log & handle error
		}
	}

	const searchEmailChange = e => {
		e.preventDefault()
		setSearchEmail(e.target.value)
		setSearch(searchEmail !== '' ? true : false)
	}

	const handleSearchEmail = async () => {
		try {
			setIsLoading(true)
			const accounts = await EmailAccountService.listemailContent(
				1,
				searchEmail
			)
			setEmailAccounts(accounts.data)
			setMetaData(accounts.meta)
			setIsLoading(false)
		} catch (e) {
			setIsLoading(false)
			// Log & handle error
		}
	}

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Email Content Services</li>
						</ol>
					</nav>
				</div>
			</div>
			<div className='row my-3'>
				<div className='col form-group'>
					<label htmlFor='protocol' className='label-search'>
						Enter Subject Line
					</label>
					<div className='input-group mb-3'>
						<input
							type='text'
							className='form-control'
							onChange={searchEmailChange}
							aria-describedby='button-addon2'
							aria-label="Recipient's username"
						/>
					</div>
				</div>
				<div className='col search_btn'>
					<button
						type='button'
						id='button-addon2'
						onClick={handleSearchEmail}
						className='btn btn-outline-secondary'
					>
						Search
					</button>
				</div>
			</div>
			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row'>
						<div className='col-sm-3'>
							<h5 className='m-0 font-weight-bold text-primary'>
								Email Content Services
							</h5>
						</div>
						<div className='col-sm-1'>
							{isLoading == true ? (
								<Loading type='spin' color='#4E73DF'></Loading>
							) : (
								''
							)}
						</div>
						<div className='col-sm-8 text-right'>
							<ReactFileReader
								base64={true}
								fileTypes={['.csv']}
								multipleFiles={false}
								handleFiles={handleFiles}
							>
								<button className='btn btn-success btn-sm float-right'>
									Import CSV File
								</button>
							</ReactFileReader>
						</div>
						{/* <div className='col-sm-3 text-right'>
							<button
								className='btn btn-sm btn-success'
								data-toggle='modal'
								data-target='#accountModal'
								onClick={() => setIsupdate(false)}
							>
								Add Email Account
							</button>
						</div> */}
					</div>
				</div>
				{loading == true ? (
					<div
						style={{
							marginLeft: '50%',
							height: '30px',
							marginBottom: '50px',
							marginTop: '20px',
						}}
					>
						<FadeLoader
							loading={true}
							color={'#0092FB'}
							height={20}
							width={5}
						/>
					</div>
				) : (
					<div className='card-body'>
						<div className='table-responsive'>
							<table
								className='table table-bordered table-hover'
								id='dataTable'
								width='100%'
								cellSpacing='0'
							>
								<thead>
									<tr>
										<th>Id</th>
										<th>Subject Line</th>
										<th>Sent Body</th>
										<th>Reply Body</th>
									</tr>
								</thead>
								<tfoot>
									<tr>
										<th>Id</th>
										<th>Subject Line</th>
										<th>Sent Body</th>
										<th>Reply Body</th>
									</tr>
								</tfoot>
								<tbody>{emailAccounts.map(listEmailAccounts)}</tbody>
							</table>
						</div>
						<div className='float-right mr-4'>
							<Pagination
								itemClass='page-item'
								linkClass='page-link'
								pageRangeDisplayed={4}
								activePage={activePage}
								totalItemsCount={metaData.total}
								itemsCountPerPage={metaData.per_page}
								onChange={pageNumber => handlePageChange(pageNumber)}
							/>
						</div>
					</div>
				)}
			</div>
			<div
				className='modal bd-example-modal-lg'
				id='accountModal'
				tabIndex='-1'
				role='dialog'
			>
				<div className='modal-dialog modal-lg' role='document'>
					<div className='modal-content'>
						<form>
							<div className='modal-header'>
								<h5 className='modal-title'>Add New Email Account</h5>
								<div className='col-sm-1'>
									{isLoading == true ? (
										<Loading type='spin' color='#4E73DF'></Loading>
									) : (
										''
									)}
								</div>
								<button
									type='button'
									className='close'
									data-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>&times;</span>
								</button>
							</div>
							<div className='modal-body'>
								{/* Inbox Provider */}
								<div className='form-group'>
									<label htmlFor='email_account_provider_id'>
										Select Inbox Provider *
									</label>
									<select
										className='form-control'
										name='email_account_provider_id'
										id='email_account_provider_id'
										onChange={handleChange}
										value={emailAccount.email_account_provider_id}
									>
										{emailAccountProviders.map(accProvider => (
											<option key={accProvider.id} value={accProvider.id}>
												{accProvider.name}
											</option>
										))}
									</select>
								</div>
								{/* Connection Purpose */}
								<div className='form-group'>
									<label htmlFor='connection_purpose'>Connection Purpose</label>
									<select
										className='form-control'
										name='connection_purpose'
										id='connection_purpose'
										onChange={handleChange}
										value={emailAccount.connection_purpose}
									>
										<option value='warm-up' selected>
											Warm-up
										</option>
										<option value='spam-checker'>Spam Checker</option>
									</select>
								</div>
								{/* username & password */}
								<div className='form-group row'>
									<div className='col'>
										<label htmlFor='username'>Username / Email *</label>
										<input
											type='text'
											value={emailAccount.username}
											className='form-control'
											name='username'
											id='username'
											onChange={handleChange}
										/>
									</div>
									<div className='col'>
										<label htmlFor='password'>Password *</label>
										<input
											type='password'
											value={emailAccount.password}
											className='form-control'
											name='password'
											id='password'
											onChange={handleChange}
										/>
									</div>
								</div>
								{/* host & protocol */}
								<div className='form-group row'>
									<div className='col'>
										<label htmlFor='host'>Host *</label>
										<input
											type='text'
											value={emailAccount.host}
											className='form-control'
											name='host'
											id='host'
											onChange={handleChange}
										/>
									</div>
									<div className='col'>
										<label htmlFor='protocol'>Protocol *</label>
										<select
											className='form-control'
											name='protocol'
											id='protocol'
											onChange={handleChange}
											value={emailAccount.protocol}
										>
											<option value='imap' selected>
												IMAP
											</option>
											<option value='pop3'>POP3</option>
										</select>
									</div>
								</div>
								{/* port & encryption */}
								<div className='form-group row'>
									<div className='col'>
										<label htmlFor='port'>Port *</label>
										<input
											type='number'
											className='form-control'
											name='port'
											value={emailAccount.port}
											id='port'
											onChange={handleChange}
										/>
									</div>
									<div className='col'>
										<label htmlFor='encryption'>Encryption *</label>
										<select
											className='form-control'
											name='encryption'
											id='encryption'
											onChange={handleChange}
											value={emailAccount.encryption}
										>
											<option value='ssl' selected>
												SSL
											</option>
											<option value='tls'>TLS</option>
										</select>
									</div>
								</div>
							</div>
							<div className='modal-footer'>
								<button type='sumbit' className='btn btn-primary'>
									{isUpdate ? 'Update' : 'Add'}
								</button>
								<button
									type='button'
									className='btn btn-secondary'
									data-dismiss='modal'
								>
									Cancel
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			{/* {show ? (
				<LogsPanel
					id={logsId}
					accounts={emailAccounts}
					show={show}
					setShow={setShow}
				/>
			) : (
				''
			)} */}
		</>
	)
}

export default EmailContentList

import React from 'react'
import './Refferal.scss'

const RefferalStats = ({ stats }) => {
	return (
		<>
			<div className='container-fluid px-0'>
				<div className='d-flex align-items-center'>
					<div className='card mb-3 flex-grow-1 mr-2'>
						<div className='row g-0'>
							<div className='col-md-4 pl-4 d-flex justify-content-center align-items-center'>
								<div
									className='first-icon align-items-sm-center d-flex justify-content-center'
									style={{ backgroundColor: 'rgb(226, 244, 229)' }}
								>
									<img src='/img/refferals.png' alt='name' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='card-body pl-0 py-4'>
									<h5 className='card-title mb-0 font-weight-bold'>
										{stats.length === 0 ? 0 : stats.UserTotalAffiliates}
									</h5>

									<p className='card-text text-muted '>Referrals</p>
								</div>
							</div>
						</div>
					</div>
					<div className='card mb-3 flex-grow-1 mr-2'>
						<div className='row g-0'>
							<div className='col-md-4 pl-4 d-flex justify-content-center align-items-center'>
								<div
									className='first-icon align-items-sm-center d-flex justify-content-center'
									style={{ backgroundColor: 'rgb(244, 243, 226)' }}
								>
									<img src='/img/clicks.png' alt='name' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='card-body pl-0 py-4'>
									<h5 className='card-title mb-0 font-weight-bold'>
										{stats.length == 0 ? 0 : stats['TotalClicks']}
									</h5>

									<p className='card-text text-muted '>Clicks</p>
								</div>
							</div>
						</div>
					</div>
					<div className='card mb-3 flex-grow-1 mr-2'>
						<div className='row g-0'>
							<div className='col-md-4 pl-4 d-flex justify-content-center align-items-center'>
								<div
									className='first-icon align-items-sm-center d-flex justify-content-center'
									style={{ backgroundColor: 'rgb(239, 226, 244)' }}
								>
									<img src='/img/earnings.png' alt='name' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='card-body pl-0 py-4'>
									<h5 className='card-title mb-0 font-weight-bold'>
										$ {stats.length == 0 ? 0 : stats['TotalEarned']}
									</h5>
									<p className='card-text text-muted '>Earnings</p>
								</div>
							</div>
						</div>
					</div>
					<div className='card mb-3 flex-grow-1'>
						<div className='row g-0'>
							<div className='col-md-4 pl-4 d-flex justify-content-center align-items-center'>
								<div
									className='first-icon align-items-sm-center d-flex justify-content-center'
									style={{ backgroundColor: 'rgb(255, 235, 235)' }}
								>
									<img src='/img/customers.png' alt='name' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='card-body pl-0 py-4'>
									<h5 className='card-title mb-0 font-weight-bold'>
										$ {stats.length == 0 ? 0 : stats['UnpaidAmount']}
									</h5>
									<p className='card-text text-muted '>Remaining Payout</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default RefferalStats

import { toast } from 'react-toastify'
import { useState, useEffect } from "react"
import StatsService from "../../api-services/stats.service"
import DonutChart from '../../components/charts/DonutChart'

const Dashboard = () => {

  const [stats, setStats] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchStats = async () => {
    setLoading(true)

    try {
      const response = await StatsService.getDashboardStats()

      if (response.success) {
        setStats(response.data)
        setLoading(false)
      } else {
        toast.error('Couldn\'t load Stats')
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchStats()
  }, [])

  return (
    <>
      <div className="row mt-4">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                Home
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className='d-flex flex-row justify-content-evenly mb-4'>
        <div class="card border-left-primary shadow h-100 py-2 flex-grow-1 mr-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Users (Total)</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{Object.keys(stats).length === 0 ? 0 : stats.totalUsersCount}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-user fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="card border-left-primary shadow h-100 py-2 flex-grow-1 mr-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Tickets (Total)</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{Object.keys(stats).length === 0 ? 0 : stats.totalTicketCount}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-ticket fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="card border-left-primary shadow h-100 py-2 flex-grow-1 mr-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Campaigns (Total)</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{Object.keys(stats).length === 0 ? 0 : stats.totalCampainsCount}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-mail-bulk fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="card border-left-primary shadow h-100 py-2 flex-grow-1 mr-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Packages (Total)</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{Object.keys(stats).length === 0 ? 0 : stats.totalPackageCount}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-receipt fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="card border-left-primary shadow h-100 py-2 flex-grow-1">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                  Emails (Total)</div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{Object.keys(stats).length === 0 ? 0 : stats.totalEmailCount}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-envelope fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className='d-flex align-items-start flex-grow-1 w-25 mr-3'>
          <div className='card shadow flex-grow-1'>
            <a
              role='button'
              aria-expanded='true'
              data-toggle='collapse'
              href='#collapseStatsUser'
              className='d-block card-header py-3'
              aria-controls='collapseStatsUser'
            >
              <h5 className='m-0 font-weight-bold text-primary'>
                Users
              </h5>
            </a>

            <div className='collapse show' id='collapseStatsUser'>
              <div className='d-flex flex-column'>
                <div className='card-body d-flex align-items-center justify-content-center'>
                  <DonutChart
                    id='userChart'
                    statsFor='user'
                    height={'330px'}
                    active={stats.totalActiveUser}
                    inactive={stats.totalInActiveUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-start flex-grow-1 w-25 mr-3'>
          <div className='card shadow flex-grow-1'>
            <a
              role='button'
              aria-expanded='true'
              data-toggle='collapse'
              href='#collapseStatsTicket'
              aria-controls='collapseStatsTicket'
              className='d-block card-header py-3'
            >
              <h5 className='m-0 font-weight-bold text-primary'>
                Tickets
              </h5>
            </a>

            <div className='collapse show' id='collapseStatsTicket'>
              <div className='d-flex flex-column'>
                <div className='card-body d-flex align-items-center justify-content-center'>
                  <DonutChart
                    id='ticketChart'
                    height={'330px'}
                    statsFor='ticket'
                    active={stats.totalActiveTicket}
                    inactive={stats.totalinActiveTicket}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-start flex-grow-1 w-25'>
          <div className='card shadow flex-grow-1'>
            <a
              role='button'
              aria-expanded='true'
              data-toggle='collapse'
              href='#collapseStatsCampaign'
              className='d-block card-header py-3'
              aria-controls='collapseStatsCampaign'
            >
              <h5 className='m-0 font-weight-bold text-primary'>
                Warmup Campaigns
              </h5>
            </a>

            <div className='collapse show' id='collapseStatsCampaign'>
              <div className='d-flex flex-column'>
                <div className='card-body d-flex align-items-center justify-content-center'>
                  <DonutChart
                    height={'330px'}
                    id='campaignsChart'
                    statsFor='campaign'
                    active={stats.activeWcCampaignsCount}
                    inactive={stats.inactiveWcCampaignsCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

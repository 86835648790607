import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { login } from '../../store/actions/auth.action'

const Login = ({ history }) => {
	const dispatch = useDispatch()

	const [loginForm, setLoginForm] = useState({
		email: '',
		password: '',
	})
	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)

	const handleLoginFormChange = event => {
		const { name, value } = event.target
		setLoginForm({ ...loginForm, [name]: value })
	}

	const handleUserLogin = async event => {
		event.preventDefault()
		setLoading(true)
		try {
			// dispatch Login action
			await dispatch(login(loginForm, history))
		} catch (e) {
			if (e.response?.status == 422) {
				const errors = e.response.data.errors
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='d-flex flex-grow-1 align-self-center card o-hidden border-0 shadow-lg my-5'>
			<div className='card-body p-0'>
				<div className='row'>
					<div className='col-lg-6 d-none d-lg-block bg-login-image'></div>
					<div className='col-lg-6'>
						<div className='p-5'>
							<div className='text-center'>
								<h1 className='h2 text-gray-900 mb-4'>
									<strong>Inbox Better</strong>
								</h1>
							</div>
							<div className='text-center'>
								<h1 className='h4 text-gray-900 mb-4'>Admin Login</h1>
							</div>
							<form className='user' onSubmit={handleUserLogin}>
								<div className='form-group'>
									<input
										required
										type='email'
										name='email'
										placeholder='* Email'
										onChange={handleLoginFormChange}
										className='form-control form-control-user'
									/>
									{errors.hasOwnProperty('email')
										? errors.email.map(e => (
												<span className='text-danger mt-1 ml-1'>* {e} </span>
										  ))
										: ''}
								</div>
								<div className='form-group'>
									<input
										type='password'
										name='password'
										onChange={handleLoginFormChange}
										className='form-control form-control-user'
										placeholder='* Password'
										required
									/>
								</div>
								<button
									type='submit'
									disabled={loading}
									className='btn btn-primary btn-user btn-block'
								>
									Login
								</button>
							</form>
							<hr />
							<div className='text-center'>
								<Link className='small' to='/forgot-pass'>
									Forgot Password?
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login

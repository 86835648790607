import { Modal } from 'bootstrap'
import UserPlan from './UserPlan'
import LoadingBar from 'react-top-loading-bar'
import PlanService from '../../api-services/plan.service'
import UserService from '../../api-services/user.service'
import React, { useState, useRef, useEffect } from 'react'
import ValidateName from '../../helper/validators/nameValidator'
import ValidateEmail from '../../helper/validators/emailValidator'
import { toast } from 'react-toastify'

const AddUserModal = ({
	fetchUsers,
	closeModal,
	addUserModal,
	systemFeatures,
}) => {
	const ref = useRef(null)

	const [error, setError] = useState(null)
	const [planForm, setPlanForm] = useState({
		name: '',
		price: '',
		group_tag: '',
		systemFeature: [],
		duration_in_days: '',
	})

	const [userForm, setUserForm] = useState({
		name: '',
		email: '',
		password: '',
		package_id: 7,
		is_admin: false,
		is_suspended: false,
		email_verified_at: Date.now(),
	})
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({ name: [], email: [], password: [] })

	const handleUserFormChange = event => {
		event.preventDefault()
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		if (name === 'name') {
			if (value.length < 3 && value.length >= 1) {
				if (errors.name.length === 0)
					setErrors({
						...errors,
						name: [...errors.name, 'Name must be minimum 3 characters long.'],
					})
			} else {
				if (errors.name.length !== 0) {
					let arr = errors.name
					arr.shift()

					setErrors({
						...errors,
						name: arr,
					})
				}
			}
		} else if (name === 'email') {
			if (!ValidateEmail(value) && value.length >= 1) {
				if (errors.email.length === 0) {
					setErrors({
						...errors,
						email: [...errors.email, 'Invalid Email'],
					})
				}
			} else {
				if (errors.email.length !== 0) {
					let arr = errors.email
					arr.shift()

					setErrors({
						...errors,
						email: arr,
					})
				}
			}
		} else if (name === 'password') {
			if (value.length < 8 && value.length >= 1) {
				if (errors.password.length === 0)
					setErrors({
						...errors,
						password: [
							...errors.password,
							'Password must be minimum 8 characters long.',
						],
					})
			} else {
				if (errors.password.length !== 0) {
					let arr = errors.password
					arr.shift()

					setErrors({
						...errors,
						password: arr,
					})
				}
			}
		}
		setUserForm({ ...userForm, [name]: value })
	}

	const handleAddNewUser = async event => {
		event.preventDefault()

		setLoading(true)
		ref.current.continuousStart()

		if (
			errors.name.length === 0 &&
			errors.email.length === 0 &&
			errors.password.length === 0
		) {
			if (userForm.is_admin) {
				let tempRequestObj = { ...userForm, package_id: 7 }
				try {
					const response = await UserService.addNewUser(tempRequestObj)
					fetchUsers()
					closeModal(addUserModal)
				} catch (e) {
					if (e.response.status === 422) {
						setErrors(e.response.data)
					} else {
						setError(e)
					}
				} finally {
					setLoading(false)
					if (ref.current) ref.current.complete()
				}
			} else {
				let tempRequestObj = { ...planForm, ...userForm }

				delete tempRequestObj.price
				delete tempRequestObj.group_tag
				delete tempRequestObj.duration_in_days

				try {
					const response = await UserService.addNewUser(tempRequestObj)

					toast.success('User added Successfully.')
					setUserForm({
						name: '',
						email: '',
						password: '',
						package_id: 7,
						is_admin: false,
						is_suspended: false,
						email_verified_at: Date.now(),
					})
					fetchUsers()
					closeModal(addUserModal)
				} catch (e) {
					if (e.response.status === 422) {
						setErrors(e.response.data.errors)
					} else {
						setError(e)
					}
				} finally {
					setLoading(false)
					if (ref.current) ref.current.complete()
				}
			}
		}
	}

	const handlePlanFormChange = event => {
		event.preventDefault()

		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		if (name === 'undefined' || name === undefined) {
			let found = false

			planForm.systemFeature.forEach(systemFeature => {
				if (
					systemFeature.max_allowed_count !== 0 &&
					systemFeature.id === parseInt(target.dataset.id) &&
					!found
				) {
					found = true
					let tempArr = []

					tempArr = planForm.systemFeature

					// setPlanForm({
					// 	...planForm,
					// 	systemFeature: tempArr.filter(
					// 		feature => feature.id !== parseInt(target.dataset.id)
					// 	),
					// })

					//New Logic

					tempArr.forEach(singleItem => {
						if (singleItem.id === parseInt(target.dataset.id)) {
							singleItem.max_allowed_count = 0
						}
					})
					setPlanForm({ ...planForm, systemFeature: tempArr })
				}
			})

			if (!found) {
				let tempArr = []
				tempArr = planForm.systemFeature

				// tempArr.push({ id: parseInt(target.dataset.id), max_allowed_count: 0 })

				// setPlanForm({ ...planForm, systemFeature: tempArr })

				//New Logic

				tempArr.forEach(singleItem => {
					if (singleItem.id === parseInt(target.dataset.id)) {
						singleItem.max_allowed_count = 1
					}
				})

				setPlanForm({ ...planForm, systemFeature: tempArr })
			}
		} else if (name === 'max_allowed_count') {
			planForm.systemFeature.forEach(systemFeature => {
				if (systemFeature.id === parseInt(target.dataset.id)) {
					let tempArr = []
					let filteredArr = []

					tempArr = planForm.systemFeature

					filteredArr = tempArr.filter(
						feature => feature.id !== parseInt(target.dataset.id)
					)

					filteredArr.push({
						id: parseInt(target.dataset.id),
						max_allowed_count: parseInt(target.value),
					})

					setPlanForm({
						...planForm,
						systemFeature: filteredArr,
					})
				}
			})
		} else setPlanForm({ ...planForm, [name]: value })
	}

	const getCustomPackageData = async () => {
		let tempPlanObj = {
			name: '',
			price: '',
			group_tag: '',
			systemFeature: [],
			duration_in_days: '',
		}

		const response = await PlanService.getCustomPackage()

		if (response.success) {
			tempPlanObj.name = response.customPackageDetail.name
			tempPlanObj.price = response.customPackageDetail.price
			tempPlanObj.group_tag = response.customPackageDetail.group_tag
			tempPlanObj.duration_in_days =
				response.customPackageDetail.duration_in_days

			response.customPackageDetail.packagefeatures.forEach(singleFeature => {
				tempPlanObj.systemFeature.push({
					id: singleFeature.system_feature_id,
					max_allowed_count: singleFeature.max_allowed_count,
				})
			})

			setPlanForm(tempPlanObj)
		}
	}

	useEffect(() => {
		getCustomPackageData()

		return () => {
			setPlanForm({
				name: '',
				price: '',
				group_tag: '',
				systemFeature: [],
				duration_in_days: '',
			})
		}
	}, [])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<div
				tabIndex='-1'
				role='dialog'
				id='addUserModal'
				aria-hidden='true'
				className='modal fade'
				aria-labelledby='exampleModalCenterTitle'
			>
				<div
					role='document'
					style={{ maxWidth: '818px' }}
					className='modal-dialog modal-dialog-centered'
				>
					<div className='modal-content from-email-modal'>
						<div className='modal-header d-flex align-items-center justify-content-between flex-row'>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								<img src='/img/user.png' alt='' style={{ color: '#0994F8' }} />
								&nbsp;Add new user
							</h5>
							<button
								type='button'
								aria-label='Close'
								onClick={() => closeModal(addUserModal)}
								style={{ outline: 'none' }}
								className='modal-close-attachment'
							>
								<img src='/img/cross.png' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<div className='row'>
								<div className='col'>
									<h5>User Details</h5>
									<div className='form-row'>
										<div className='form-group col-md-12'>
											<label htmlFor='fullname'>
												Full Name <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												name='name'
												type='text'
												value={userForm.name}
												className='form-control'
												onChange={handleUserFormChange}
											/>
											{errors.hasOwnProperty('name')
												? errors.name.map(e => (
														<span className='text-danger mt-2 ml-1'>
															* {e}{' '}
														</span>
												  ))
												: ''}
										</div>
									</div>
									{/* Email & Password */}
									<div className='form-row'>
										<div className='form-group col-md-6'>
											<label htmlFor='email'>
												Email <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												name='email'
												type='email'
												value={userForm.email}
												className='form-control'
												onChange={handleUserFormChange}
											/>
											{errors.hasOwnProperty('email')
												? errors.email.map(e => (
														<span className='text-danger mt-2 ml-1'>
															* {e}{' '}
														</span>
												  ))
												: ''}
										</div>
										<div className='form-group col-md-6'>
											<label htmlFor='password'>
												Password <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												minLength={8}
												name='password'
												type='password'
												className='form-control'
												value={userForm.password}
												onChange={handleUserFormChange}
											/>
											{errors.hasOwnProperty('password')
												? errors.password.map(e => (
														<span className='text-danger mt-2 ml-1'>
															* {e}{' '}
														</span>
												  ))
												: ''}
										</div>
									</div>
									<div className='d-flex flex-row justify-content-center align-items-center mt-3 mb-3'>
										<div className='form-group mr-3'>
											<div className='form-check'>
												<input
													type='checkbox'
													name='is_admin'
													checked={userForm.is_admin}
													className='form-check-input'
													onChange={() => {
														setUserForm({
															...userForm,
															is_admin: !userForm.is_admin,
														})
													}}
												/>
												<label className='form-check-label' htmlFor='gridCheck'>
													Is Super Admin?
												</label>
											</div>
										</div>
										<div className='form-group d-none'>
											<div className='form-check'>
												<input
													type='checkbox'
													name='is_suspended'
													className='form-check-input'
													disabled={userForm.is_admin}
													checked={userForm.is_suspended}
													onChange={() =>
														setUserForm({
															...userForm,
															is_suspended: !userForm.is_suspended,
														})
													}
												/>
												<label className='form-check-label' htmlFor='gridCheck'>
													Is Suspended?
												</label>
											</div>
										</div>
									</div>
									{userForm.is_admin ? (
										<></>
									) : (
										<div>
											<h5>Customise Package Details</h5>
											<UserPlan
												updating={loading}
												usageFlag='addUser'
												planForm={planForm}
												setPlanForm={setPlanForm}
												systemFeatures={systemFeatures}
												// handleEditPlan={handleEditPlan}
												handlePlanFormChange={handlePlanFormChange}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={() => closeModal(addUserModal)}
								className='btn btn-secondary bg-danger border mr-2'
							>
								Close
							</button>
							<button
								type='button'
								disabled={
									loading ||
									userForm.name === '' ||
									userForm.email === '' ||
									userForm.password === '' ||
									(errors.name !== undefined && errors.name.length !== 0) ||
									(errors.email !== undefined && errors.email.length !== 0) ||
									(errors.password !== undefined &&
										errors.password.length !== 0)
								}
								onClick={handleAddNewUser}
								className='btn btn-primary from-email-add'
							>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default AddUserModal

import API from './api'

const TicketService = {
	listTicketsByUser: (id, pageNumber = 1) => {
		return API.get(`/admin/user/support-tickets/${id}?page=${pageNumber}`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				return err
			})
	},
	listMessagesByTicket: (id, pageNumber = 1) => {
		return API.get(`/admin/user/support-tickets/${id}/messages`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				return err
			})
	},
	addMessageToTicket: messageObject => {
		return API.post(`/admin/user/support-tickets/messages`, messageObject)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
        return err
			})
	},
  closeTicket: (ticketId) => {
    return API.get(`admin/user/support-tickets/${ticketId}/close`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        return err
      })
  },
}

export default TicketService

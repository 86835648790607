import API from './api'

const EmailAccountService = {
  index: url => {
    return API.get(url)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailAccountService err', err)
        throw err
      })
  },
  create: data => {
    return API.post('/admin/email-accounts', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailAccountService err', err)
        throw err
      })
  },
  update: data => {
    return API.put(`/admin/email-accounts/${data.id}`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailAccountService err', err)
        throw err
      })
  },
  test: accountId => {
    return API.get(`admin/email-accounts/test/${accountId}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailAccountService err', err)
        throw err
      })
  },
  import: file => {
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('csv-file', file)

    return API.post('/admin/email-accounts/import', formData)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailAccountService err', err)
        throw err
      })
  },
  importEmailContent: file => {
    API.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', file)

    return API.post('/admin/email-contents/import', formData)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailContentService err', err)
        throw err
      })
  },
  listemailContent: (pageNumber = 1, subjectLine = '') => {
    return API.get(`/admin/email-contents?subjectLine=${subjectLine}&page=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('EmailContentService err', err)
        throw err
      })
  },
}

export default EmailAccountService

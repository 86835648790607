import React from "react";
import { Link } from "react-router-dom";
import JobsTable from "./JobsTable";

const SystemJobs = () => {
  return (
    <>
      <div className="row mt-4">
        <div className="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link
                  to={{
                    pathname: `/`,
                  }}
                >
                  Home
                </Link>
              </li>
              <li class="breadcrumb-item">Background Processings</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div class="row">
            <div class="col-sm-6">
              <h4 class="m-0 font-weight-bold text-primary">Crone Jobs</h4>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col mx-5">
            <JobsTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemJobs;

import API from './api'

const EmailAccountProviderService = {
	index: () => {
		return API.get('/admin/email-providers')
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				console.log('EmailAccountService err', err)
				throw err
			})
	},
	create: data => {
		return API.post('/admin/email-providers', data)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				console.log('EmailAccountService err', err)
				throw err
			})
	},
}

export default EmailAccountProviderService

function ValidateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  if (inputText.match(mailformat)) {
    return true
  } else {
    return false
  }
}

export default ValidateEmail

const PlanModal = ({
	usecase,
	loading,
	planForm,
	updating,
	usageFlag,
	closeModal,
	systemFeatures,
	handleEditPlan,
	handleAddNewPlan,
	handlePlanFormChange,
}) => {
	return (
		<>
			<div
				tabIndex='-1'
				role='dialog'
				id='planModal'
				className='modal fade'
				aria-hidden='true'
				aria-labelledby='exampleModalCenterTitle'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					role='document'
					style={{ maxWidth: '818px' }}
				>
					<div className='modal-content from-email-modal'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								&nbsp;&nbsp;{' '}
								{usageFlag === 'add'
									? 'Add new Plan'
									: usecase === 'user'
									? `Edit User Plan`
									: 'Edit Plan'}
							</h5>
							<button
								type='button'
								aria-label='Close'
								onClick={closeModal}
								className='modal-close'
								style={{ outline: 'none' }}
							>
								<img src='/img/cross.png' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<div className='row p-3'>
								<div className='col'>
									<div className='form-row'>
										<div className='form-group col-md-12'>
											<label htmlFor='name'>
												Plan Name <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												type='text'
												name='name'
												value={planForm.name}
												className='form-control'
												onChange={handlePlanFormChange}
												disabled={usecase === 'user' || usageFlag === 'edit'}
											/>
										</div>
									</div>
									{/* Email & Password */}
									<div className='form-row'>
										<div className='form-group col-12'>
											<label htmlFor='price'>
												Price <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												min={0}
												required
												name='price'
												type='number'
												pattern='[0-9]*'
												value={planForm.price}
												className='form-control'
												disabled={
													planForm.price === 0 ||
													usecase === 'user' ||
													usageFlag === 'edit'
												}
												readOnly={
													planForm.price === 0 ||
													usecase === 'user' ||
													usageFlag === 'edit'
												}
												onChange={handlePlanFormChange}
												onInput={e =>
													(e.target.value = Math.abs(e.target.value))
												}
											/>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-12'>
											<label htmlFor='group_tag'>
												Type <span style={{ color: 'red' }}>*</span>
											</label>
											<select
												id='group_tag'
												name='group_tag'
												className='form-control'
												disabled={usecase === 'user' || usageFlag === 'edit'}
												onChange={handlePlanFormChange}
											>
												<option selected value=''>
													Select a Type
												</option>
												<option
													value='Trial'
													selected={planForm.group_tag === 'Trial'}
												>
													Trial
												</option>
												<option
													value='Recurring'
													selected={planForm.group_tag === 'Recurring'}
												>
													Recurring
												</option>
												<option
													value='Life Time'
													selected={planForm.group_tag === 'Life Time'}
												>
													Life Time
												</option>
											</select>
										</div>
									</div>
									{planForm.group_tag === 'Life Time' ? (
										<></>
									) : (
										<div className='form-row'>
											<div className='form-group col-12'>
												<label htmlFor='duration_in_days'>
													Billing Period <span style={{ color: 'red' }}>*</span>
												</label>
												<select
													id='duration_in_days'
													name='duration_in_days'
													className='form-control'
													disabled={
														usecase === 'user' ||
														planForm.group_tag === 'Trial' ||
														usageFlag === 'edit'
													}
													onChange={handlePlanFormChange}
												>
													<option selected value=''>
														Select a Period
													</option>
													<option
														selected={
															planForm.duration_in_days === 'Daily' ||
															planForm.duration_in_days === 1
														}
														value='1'
													>
														Daily
													</option>
													<option
														selected={
															planForm.duration_in_days === 'Weekly' ||
															planForm.duration_in_days === 7
														}
														value='7'
													>
														Weekly
													</option>
													<option
														selected={
															planForm.duration_in_days === 'Monthly' ||
															planForm.duration_in_days === 30 ||
															planForm.group_tag === 'Trial'
														}
														value='30'
													>
														Monthly
													</option>
												</select>
												{/* <input
                      min={0}
                      required
                      type='number'
                      name='duration_in_days'
                      className='form-control'
                      disabled={usecase === 'user'}
                      onChange={handlePlanFormChange}
                      value={planForm.duration_in_days}
                      onInput={e =>
                        (e.target.value = Math.abs(e.target.value))
                      }
                    /> */}
											</div>
										</div>
									)}

									<div className='row'>
										<div className='col'>
											<h5>System Features</h5>
										</div>
									</div>
									{systemFeatures !== undefined ? (
										systemFeatures.map((feature, i) => (
											<div className='row' key={i}>
												<div
													id='feature-card'
													data-id={feature.id}
													className='card col-8 mb-1 mr-1'
													style={{
														cursor: 'pointer',
														border: `${
															planForm.systemFeature !== undefined
																? planForm.systemFeature.some(
																		systemFeature =>
																			systemFeature['id'] === feature.id &&
																			systemFeature['max_allowed_count'] !== 0
																  )
																	? '1px solid #0b95f8'
																	: ''
																: ''
														}`,
														background: `${
															planForm.systemFeature !== undefined
																? planForm.systemFeature.some(
																		systemFeature =>
																			systemFeature['id'] === feature.id &&
																			systemFeature['max_allowed_count'] !== 0
																  )
																	? '#e2f1f4'
																	: ''
																: ''
														}`,
													}}
													onClick={handlePlanFormChange}
												>
													<div
														id='feature-card'
														className='row d-flex align-items-center'
														data-id={feature.id}
													>
														<div
															id='feature-card'
															data-id={feature.id}
															className='dcard_icond mx-2'
														>
															<img
																data-id={feature.id}
																id='feature-card'
																className='dcard_imgd'
																name='undefined'
																src={
																	feature.id === 1
																		? '/img/warmup-icon.png'
																		: feature.id === 2
																		? '/img/dns-checker.png'
																		: feature.id === 3
																		? '/img/spam-checker-blue.svg'
																		: feature.id === 4
																		? '/img/potential-inboxing-blue.png'
																		: feature.id === 5
																		? '/img/sequence-icon.png'
																		: ''
																}
																alt='icon'
															/>
														</div>
														<div
															className=''
															data-id={feature.id}
															id='feature-card'
														>
															<div
																id='feature-card'
																className='card-body cardbody'
																data-id={feature.id}
															>
																<h5
																	id='feature-card'
																	data-id={feature.id}
																	className='card-title font-weight-bold'
																	style={{ color: '#0B95F8' }}
																>
																	{feature.name}
																</h5>
															</div>
														</div>
													</div>
												</div>
												<div className='col'>
													{planForm.systemFeature !== undefined ? (
														// planForm.systemFeature.some(
														// 	systemFeature =>
														// 		systemFeature['id'] === feature.id
														// ) ? (
														<div className='row d-flex align-items-center justify-content-center'>
															<label
																htmlFor='allowedCount'
																className='card-title font-weight-bold'
															>
																Max Allowed
															</label>
															<input
																min={0}
																type='number'
																// defaultValue={0}
																disabled={
																	planForm.systemFeature.some(
																		systemFeature =>
																			systemFeature['id'] === feature.id &&
																			systemFeature['max_allowed_count'] !== 0
																	)
																		? false
																		: true
																}
																value={
																	planForm.systemFeature.find(
																		systemFeature =>
																			systemFeature.id === feature.id
																	) !== undefined
																		? planForm.systemFeature.find(
																				systemFeature =>
																					systemFeature.id === feature.id
																		  ).max_allowed_count
																		: 0
																}
																id='allowedCount'
																data-id={feature.id}
																name='max_allowed_count'
																onChange={handlePlanFormChange}
																onInput={e =>
																	(e.target.value = Math.abs(e.target.value))
																}
															/>
														</div>
													) : (
														// ) : (
														// 	<></>
														// )
														<></>
													)}
												</div>
											</div>
										))
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={closeModal}
								className='btn btn-secondary modal-button bg-white border text-dark mr-3'
							>
								Close
							</button>
							<button
								type='button'
								disabled={updating || loading}
								onClick={
									usageFlag === 'add' ? handleAddNewPlan : handleEditPlan
								}
								className='btn btn-primary from-email-add'
							>
								{usageFlag === 'add' ? 'Add' : 'Edit'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PlanModal

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Layout Related Components
import Header from './Header'
import Footer from './Footer'
import Sidebar from './Sidebar'
import MessageSection from '../../messages/MessageSection'

const Layout = ({ children, location, ...rest }) => {
	return (
		<div id='wrapper'>
			<Sidebar />
			<div id='content-wrapper' className='d-flex flex-column'>
				<div id='content'>
					<Header />
					<div className='container-fluid'>{children}</div>
				</div>
				<Footer />
			</div>
			<div className='right-view card shadow' id='message-section'>
				<MessageSection />
			</div>
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
}

export default connect(null, null)(withRouter(Layout))

import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import Pagination from 'react-js-pagination'
import React, { useEffect, useState } from 'react'
import ErrorLogService from '../../api-services/errorLog.service'
import { Modal } from 'bootstrap'

const Errors = () => {
	const [errors, setErrors] = useState([])
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const handlePageChange = () => {
		//make apicall on page change
	}

	const handleOpenModal = () => {
		var modalInstance = Modal.getOrCreateInstance('#viewAttachmentModal')
		modalInstance.show()
	}

	const closeModal = () => {
		var modalInstance = Modal.getInstance('#viewAttachmentModal')
		modalInstance.hide()
	}

	const fetchErrors = async () => {
		const response = await ErrorLogService.list()

		if (response) {
			setErrors(response.logs)
		} else {
			toast.error('Something went wrong...')
		}
	}

	useEffect(() => {
		fetchErrors()
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Error Logs</li>
						</ol>
					</nav>
				</div>
			</div>

			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row'>
						<div className='col-sm-6'>
							<h4 className='m-0 font-weight-bold text-primary'>Error Logs</h4>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									height: '30px',
									marginLeft: '50%',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									width={5}
									height={20}
									loading={true}
									color={'#0092FB'}
								/>
							</div>
						) : errors.length === 0 ? (
							<div className='text-center starter-inboxes'>
								<p
									className='sp mt-1'
									style={{
										fontSize: '14px',
										lineHeight: '30px',
										fontWeight: 'bold',
									}}
								>
									No Error Logs Found...
								</p>
							</div>
						) : (
							<>
								<div className='table-responsive' style={{ width: '100%' }}>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th className='text-center'>Level</th>
												<th className='text-center'>Context</th>
												<th className='text-center'>Date</th>
												<th className='text-center'>Content</th>
												{/* <th className='text-center'>Actions</th> */}
											</tr>
										</thead>
										<tbody>
											{errors.map((error, i) => (
												<tr key={i} className='text-center'>
													<td
														className='dns_email_item'
														style={{
															width: '1%',
															padding: '0.75rem',
															whiteSpace: 'nowrap',
															color:
																error.level_class === 'danger' ? 'red' : 'blue',
														}}
													>
														{error.level_img + ' ' + error.level}
													</td>
													<td
														className='dns_email_item'
														style={{
															padding: '0.75rem',
														}}
													>
														{error.context}
													</td>
													<td
														className='dns_email_item'
														style={{
															padding: '0.75rem',
														}}
													>
														{error.date}
													</td>
													<td
														className='dns_email_item'
														style={{
															padding: '0.75rem',
															wordBreak: 'break-all',
														}}
													>
														{error.text}
													</td>
													{/* <td className='dns_email_item'>
														<button
															className='btn btn-info ml-2 btn-circle btn-sm'
															onClick={handleOpenModal}
														>
															<i className='fas fa-eye'></i>
														</button>
													</td> */}
												</tr>
											))}
										</tbody>
									</table>
									<div className='float-right mr-4'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											pageRangeDisplayed={4}
											activePage={activePage}
											totalItemsCount={metaData.total}
											itemsCountPerPage={metaData.per_page}
											onChange={pageNumber => handlePageChange(pageNumber)}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default Errors

const FaqModal = ({
	loading,
	faqForm,
	updating,
	usageFlag,
	closeModal,
	handleEditFaq,
	systemFeatures,
	handleAddNewFaq,
	handleFaqFormChange,
}) => {
	return (
		<>
			{/* <LoadingBar height={5} color='#5D95EA' ref={ref} /> */}
			<div
				tabIndex='-1'
				role='dialog'
				id='faqModal'
				aria-hidden='true'
				className='modal fade'
				aria-labelledby='exampleModalCenterTitle'
			>
				<div
					role='document'
					style={{ maxWidth: '818px' }}
					className='modal-dialog modal-dialog-centered'
				>
					<div className='modal-content from-email-modal'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								&nbsp;&nbsp; {usageFlag === 'add' ? 'Add new FAQ' : `Edit FAQ`}
							</h5>
							<button
								type='button'
								aria-label='Close'
								onClick={closeModal}
								className='modal-close-attachment'
								style={{ outline: 'none' }}
							>
								<img src='/img/cross.png' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<div className='row p-3'>
								<div className='col'>
									<div className='form-row'>
										<div className='form-group col-md-12'>
											<label htmlFor='tag'>
												Tag <span style={{ color: 'red' }}>*</span>
											</label>
											<select
												id='tag'
												name='tag'
												value={faqForm.tag}
												className='form-control'
												onChange={handleFaqFormChange}
											>
												<option value=''>Select a Tag</option>
												{systemFeatures !== undefined ? (
													systemFeatures.length !== 0 ? (
														systemFeatures.map((feature, i) => (
															<option
																value={
																	feature.name === 'Warmup Email'
																		? 'warmup'
																		: feature.name ===
																		  'Email Blacklist & DNS Checker'
																		? 'dns'
																		: feature.name === 'Inbox Spam Checker'
																		? 'spam'
																		: feature.name === 'Email Grader'
																		? 'grader'
																		: feature.name === 'Sequences'
																		? 'sequences'
																		: ''
																}
															>
																{feature.name}
															</option>
														))
													) : (
														<option disabled>Loading...</option>
													)
												) : (
													<option disabled>Loading...</option>
												)}
											</select>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-12'>
											<label htmlFor='name'>
												Question <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												type='text'
												name='question'
												value={faqForm.question}
												className='form-control'
												onChange={handleFaqFormChange}
												// disabled={usecase === 'add' || usageFlag === 'edit'}
											/>
										</div>
									</div>
									{/* Email & Password */}
									<div className='form-row'>
										<div className='form-group col-12'>
											<label htmlFor='price'>
												Answer <span style={{ color: 'red' }}>*</span>
											</label>
											<textarea
												required
												rows={12}
												name='answer'
												value={faqForm.answer}
												className='form-control'
												onChange={handleFaqFormChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={closeModal}
								className='btn btn-secondary bg-danger border mr-2'
							>
								Close
							</button>
							<button
								type='button'
								disabled={
									loading ||
									updating ||
									faqForm.tag === '' ||
									faqForm.answer === '' ||
									faqForm.question === ''
								}
								onClick={usageFlag === 'add' ? handleAddNewFaq : handleEditFaq}
								className='btn btn-primary from-email-add'
							>
								{usageFlag === 'add' ? 'Add' : 'Edit'}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default FaqModal

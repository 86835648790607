import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import SettingsService from '../../api-services/settings.service'

import { ReactComponent as EyeIcon } from '../../components/assets/icons/eye-icon.svg'
import { ReactComponent as EyeSlashIcon } from '../../components/assets/icons/eye-slash-icon.svg'

const Profile = () => {
	const [errors, setErrors] = useState({
		name: [],
		password: [],
		current_password: [],
		password_confirmation: [],
	})
	const [loading, setLoading] = useState(false)
	const [profileData, setProfileData] = useState({
		name: '',
		email: '',
		password: '',
		current_password: '',
		password_confirmation: '',
	})
	const [currentPasswordVisibility, setCurrentPasswordVisibility] =
		useState(false)
	const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
		useState(false)
	const [passwordVisibility, setPasswordVisibility] = useState(false)

	const handleOnChange = e => {
		const { name, value } = e.target

		if (name === 'name') {
			if (!/^[a-zA-Z ]*$/.test(value) && errors.name.length === 0) {
				setErrors({
					...errors,
					name: [...errors.name, 'Only Alphabets allowed'],
				})
			}

			if (/^[a-zA-Z ]*$/.test(value) && errors.name.length !== 0) {
				setErrors({
					...errors,
					name: [],
				})
			}
		}

		if (name === 'current_password')
			setErrors({
				...errors,
				current_password: [],
			})

		if (name === 'password_confirmation') {
			if (value === '')
				setErrors({
					...errors,
					password_confirmation: [],
				})

			if (
				profileData.password !== value &&
				errors.password_confirmation.length === 0
			)
				setErrors({
					...errors,
					password_confirmation: [
						...errors.password_confirmation,
						"Passwords don't match",
					],
				})

			if (
				profileData.password === value &&
				errors.password_confirmation.length !== 0
			)
				setErrors({
					...errors,
					password_confirmation: [],
				})
		}

		if (name === 'password') {
			if (value === '')
				setErrors({
					...errors,
					password: [],
				})

			// if (
			// 	profileData.password_confirmation !== value &&
			// 	errors.password_confirmation.length === 0
			// )
			// 	setErrors({
			// 		...errors,
			// 		password_confirmation: [
			// 			...errors.password_confirmation,
			// 			"Passwords don't match.",
			// 		],
			// 	})

			if (
				profileData.password_confirmation === value &&
				errors.password_confirmation.length !== 0
			)
				setErrors({
					...errors,
					password_confirmation: [],
				})

			if (value.length < 8 && errors.password.length === 0)
				setErrors({
					...errors,
					password: [...errors.password, 'Password must have 8 characters.'],
				})

			if (value.length >= 8 && errors.password.length !== 0)
				setErrors({
					...errors,
					password: [],
				})
		}

		setProfileData({ ...profileData, [name]: value })
	}
	const handleSubmit = async e => {
		e.preventDefault()

		setLoading(true)

		if (
			errors.name.length === 0 &&
			errors.password.length === 0 &&
			errors.current_password.length === 0 &&
			errors.password_confirmation.length === 0
		) {
			try {
				delete profileData.email

				const response = await SettingsService.updateProfile(profileData)

				if (response.success) {
					setLoading(false)
					toast.success('Profile Updated')
					localStorage.setItem('user', JSON.stringify(response.data))
					setProfileData({
						password: '',
						current_password: '',
						password_confirmation: '',
						name: JSON.parse(localStorage.getItem('user')).name,
						email: JSON.parse(localStorage.getItem('user')).email,
					})
				} else {
					setErrors({ ...errors, ...response.errors })
				}
			} catch (error) {
				setLoading(false)
			}
		}
	}

	useEffect(() => {
		if (localStorage.getItem('user') !== null) {
			setProfileData({
				name: JSON.parse(localStorage.getItem('user')).name,
				email: JSON.parse(localStorage.getItem('user')).email,
			})
		}
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Profile</li>
						</ol>
					</nav>
				</div>
			</div>
			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row'>
						<div className='col-sm-6'>
							<h4 className='m-0 font-weight-bold text-primary'>
								Your Profile
							</h4>
						</div>
					</div>
				</div>
				<div className='d-flex flex-column flex-sm-column flex-md-row flex-lg-row align-items-center justify-content-around p-3'>
					<form
						onSubmit={handleSubmit}
						className='d-flex flex-column align-self-stretch m-1'
					>
						<div className='d-flex flex-column flex-sm-column flex-md-row flex-lg-row mt-2'>
							<div className='mr-0 mr-sm-0 mr-md-3 mr-lg-3 flex-grow-1'>
								<label htmlFor='name'>Name</label>
								<input
									id='name'
									name='name'
									type='text'
									value={profileData.name}
									className='form-control'
									onChange={handleOnChange}
								/>
								{errors.hasOwnProperty('name')
									? errors.name.map((e, i) => (
											<span key={i} className='text-danger mt-2 ml-1'>
												{e}
											</span>
									  ))
									: ''}
							</div>
							<div className='flex-grow-1 mt-2 mt-sm-2 mt-md-0 mt-lg-0'>
								<label htmlFor='email'>Email</label>
								<input
									readOnly
									id='email'
									type='text'
									className='form-control'
									value={profileData.email}
									onChange={handleOnChange}
								/>
							</div>
						</div>
						<div className='d-flex flex-column flex-sm-column flex-md-row flex-lg-row mt-2'>
							<div className='mr-0 mr-sm-0 mr-md-3 mr-lg-3 flex-grow-1'>
								<label htmlFor='current_password'>Current Password</label>
								<div className='input-group'>
									<input
										id='current_password'
										name='current_password'
										className='form-control'
										onChange={handleOnChange}
										value={profileData.current_password}
										type={currentPasswordVisibility ? 'text' : 'password'}
									/>
									<button
										type='button'
										className='btn btn-light bg-white border-none'
										onClick={() =>
											setCurrentPasswordVisibility(!currentPasswordVisibility)
										}
									>
										{currentPasswordVisibility ? <EyeIcon /> : <EyeSlashIcon />}
									</button>
								</div>
								<div className='d-flex flex-column'>
									{errors.hasOwnProperty('current_password')
										? errors.current_password.map((e, i) => (
												<span key={i} className='text-danger mt-2 ml-1'>
													{e}
												</span>
										  ))
										: ''}
								</div>
							</div>
							<div className='mr-0 mr-sm-0 mr-md-3 mr-lg-3 mt-2 mt-sm-2 mt-md-0 mt-lg-0 flex-grow-1'>
								<label htmlFor='password'>New Password</label>
								<div className='input-group'>
									<input
										id='password'
										name='password'
										className='form-control'
										onChange={handleOnChange}
										value={profileData.password}
										type={passwordVisibility ? 'text' : 'password'}
									/>
									<button
										type='button'
										className='btn btn-light bg-white'
										onClick={() => setPasswordVisibility(!passwordVisibility)}
									>
										{passwordVisibility ? <EyeIcon /> : <EyeSlashIcon />}
									</button>
								</div>
								<div className='d-flex flex-column'>
									{errors.hasOwnProperty('password')
										? errors.password.map((e, i) => (
												<span key={i} className='text-danger mt-2 ml-1'>
													{e}
												</span>
										  ))
										: ''}
								</div>
							</div>
							<div className='mt-2 mt-sm-2 mt-md-0 mt-lg-0 flex-grow-1'>
								<label htmlFor='password_confirmation'>
									Confirm New Password
								</label>
								<div className='input-group'>
									<input
										className='form-control'
										onChange={handleOnChange}
										id='password_confirmation'
										name='password_confirmation'
										value={profileData.password_confirmation}
										type={confirmPasswordVisibility ? 'text' : 'password'}
									/>
									<button
										type='button'
										className='btn btn-light bg-white'
										onClick={() =>
											setConfirmPasswordVisibility(!confirmPasswordVisibility)
										}
									>
										{confirmPasswordVisibility ? <EyeIcon /> : <EyeSlashIcon />}
									</button>
								</div>
								<div className='d-flex flex-column'>
									{errors.hasOwnProperty('password_confirmation')
										? errors.password_confirmation.map((e, i) => (
												<span key={i} className='text-danger mt-2 ml-1'>
													{e}
												</span>
										  ))
										: ''}
								</div>
							</div>
						</div>
						<div className='d-flex align-self-end justify-content-end'>
							<button
								className='btn btn-primary mt-2'
								disabled={
									profileData.name === '' ||
									errors.name.length !== 0 ||
									errors.password.length !== 0 ||
									errors.current_password.length !== 0 ||
									errors.password_confirmation.length !== 0
								}
							>
								Update
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Profile

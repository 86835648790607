import '../user/Users.scss'
import { Modal } from 'bootstrap'
import Pagination from 'react-js-pagination'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import FadeLoader from 'react-spinners/FadeLoader'
import { connect, useDispatch } from 'react-redux'
import TicketService from '../../api-services/ticket.service'
import '../../components/layouts/vertical-layout/index.scss'
import { listMessagesByTicket } from '../../store/actions/messages.action'

const Tickets = ({ messagesByTicket }) => {
	const { id } = useParams()
	const dispatch = useDispatch()

	const [userId, setUserId] = useState(id)
	const [tickets, setTickets] = useState(id)
	const [loading, setLoading] = useState(true)
	const [activePage, setActivePage] = useState(1)
	const [activeImage, setActiveImage] = useState('')
	// const [showMessages, setShowMessages] = useState(false)
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	let messageSection = document.getElementById('message-section')

	const fetchTickets = async () => {
		setLoading(true)
		try {
			const response = await TicketService.listTicketsByUser(id)

			setTickets(response.supportTickets.data)
			setMetaData({
				...metaData,
				total: response.supportTickets.total,
				per_page: response.supportTickets.per_page,
			})
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const handleCloseTicket = async ticketId => {
		try {
			const response = await TicketService.closeTicket(ticketId)

			messageSection.classList.remove('view-message-section')
			fetchTickets()
		} catch (error) {
			console.log(error)
		}
	}

	const openModal = () => {
		var modalInstance = Modal.getOrCreateInstance('#viewAttachmentModal')
		modalInstance.show()
	}

	const closeModal = () => {
		var modalInstance = Modal.getInstance('#viewAttachmentModal')
		modalInstance.hide()
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)

		const response = await TicketService.listTicketsByUser(userId, pageNumber)

		setTickets(response.supportTickets.data)
		setMetaData({
			...metaData,
			total: response.supportTickets.total,
			per_page: response.supportTickets.per_page,
		})
		setActivePage(pageNumber)

		if (response.length !== 0) {
			setLoading(false)
		}
	}

	useEffect(() => {
		setUserId(id)
		fetchTickets()
	}, [])

	useEffect(() => {
		setUserId(id)
		fetchTickets()
	}, [id])

	// useLayoutEffect(() => {
	//   if (messageSection !== null) {
	//     if (showMessages) {
	//       messageSection.classList.add('view-message-section')
	//     }
	//     else {
	//       messageSection.classList.remove('view-message-section')
	//     }
	//   }
	// }, [showMessages])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol class='breadcrumb'>
							<li class='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li class='breadcrumb-item'>Users</li>
							<li class='breadcrumb-item'>Tickets</li>
						</ol>
					</nav>
				</div>
			</div>

			<div class='card shadow mb-4'>
				<div class='card-header py-3'>
					<div class='row'>
						<div class='col-sm-6'>
							<h4 class='m-0 font-weight-bold text-primary'>Tickets</h4>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									marginLeft: '50%',
									height: '30px',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									loading={true}
									color={'#0092FB'}
									height={20}
									width={5}
								/>
							</div>
						) : tickets.length === 0 ? (
							<div className='text-center starter-inboxes'>
								<p
									className='sp mt-1'
									style={{
										fontSize: '16px',
										lineHeight: '30px',
										fontWeight: 'bold',
									}}
								>
									No Tickets from this User
								</p>
							</div>
						) : tickets !== undefined ? (
							<>
								<div className='table-responsive'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th>Actions</th>
												<th>Title</th>
												<th>Description</th>
												<th>
													Status
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
											</tr>
										</thead>
										<tbody>
											{tickets.map((ticket, i) => (
												<tr
													key={i}
													style={{
														background:
															ticket.support_ticket_messages.length === 0
																? '#2dc653'
																: '#ff4d4d',
													}}
												>
													<td className='dns_email_item d-flex align-items-center'>
														<button
															className='btn btn-info ml-2 btn-circle btn-sm'
															onClick={() => {
																localStorage.setItem(
																	'currentTicket',
																	JSON.stringify(ticket)
																)
																dispatch(listMessagesByTicket(ticket.id))
																messageSection.classList.add(
																	'view-message-section'
																)
																// setShowMessages(!showMessages)
															}}
														>
															<i class='fas fa-eye'></i>
														</button>
														&nbsp;&nbsp;
														{ticket.image === '' || ticket.image === null ? (
															<></>
														) : ticket.image.includes('.pdf') ? (
															<a
																target='_blank'
																href={`${
																	process.env.REACT_APP_INBOX_BETTER_API_URL.slice(
																		0,
																		31
																	) +
																	'/public' +
																	ticket.image
																}`}
															>
																<button className='btn btn-sm btn-primary'>
																	View Attachment
																</button>
															</a>
														) : (
															<button
																className='btn btn-sm btn-primary'
																onClick={() => {
																	setActiveImage(
																		process.env.REACT_APP_INBOX_BETTER_API_URL.slice(
																			0,
																			31
																		) +
																			'/public' +
																			ticket.image
																	)
																	openModal()
																}}
															>
																View Attachment
															</button>
														)}
														&nbsp;&nbsp;
														{
															ticket.is_closed ? (
																<></>
															) : (
																<button
																	className='btn btn-sm btn-danger'
																	onClick={() => handleCloseTicket(ticket.id)}
																>
																	Close
																</button>
															)

															// <button
															//   className="btn btn-danger ml-2 btn-circle btn-sm"
															//   onClick={() => {
															//     handleCloseTicket(ticket.id)
															//   }}
															// >
															//   <i class="far fa-times"></i>
															// </button>
														}
													</td>
													<td
														className='dns_email_item'
														style={{
															color:
																ticket.support_ticket_messages.length === 0
																	? '#ffffff'
																	: '#000000',
														}}
													>
														{ticket.title}
													</td>
													<td
														className='dns_email_item'
														style={{
															color:
																ticket.support_ticket_messages.length === 0
																	? '#ffffff'
																	: '#000000',
														}}
														dangerouslySetInnerHTML={{
															__html: ticket.description,
														}}
													></td>
													{/* <td
                              className="dns_email_item"
                            >
                              <img
                                className="img-thumbnail text-center"
                                src={`${process.env.REACT_APP_INBOX_BETTER_API_URL.slice(0, 31) + '/public' + ticket.image}`
                                }
                              />
                            </td> */}
													<td
														className='dns_email_item'
														style={{
															color:
																ticket.support_ticket_messages.length === 0
																	? '#ffffff'
																	: '#000000',
														}}
													>
														{ticket.is_closed ? 'Closed' : 'Open'}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div className='float-right mr-4'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											activePage={activePage}
											itemsCountPerPage={metaData.per_page}
											totalItemsCount={metaData.total}
											pageRangeDisplayed={4}
											onChange={pageNumber => handlePageChange(pageNumber)}
										/>
									</div>
								</div>
							</>
						) : (
							<div className='text-center starter-inboxes'>
								<p
									className='sp mt-1'
									style={{ fontSize: '14px', lineHeight: '30px' }}
								>
									Couldn't fetch tickets.
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
			<div
				tabIndex='-1'
				role='dialog'
				aria-hidden='true'
				className='modal fade'
				id='viewAttachmentModal'
				aria-labelledby='exampleModalCenterTitle'
			>
				<div
					role='document'
					style={{ minWidth: '100%' }}
					className='modal-dialog modal-fullscreen'
				>
					<div
						style={{ minHeight: '100%' }}
						className='modal-content from-email-modal'
					>
						<div className='modal-header'>
							<div className='d-flex align-items-center justify-content-between flex-row flex-grow-1'>
								<h5 className='modal-title' id='exampleModalLongTitle'>
									&nbsp;Attachment
								</h5>
								<div>
									<button
										type='button'
										aria-label='Close'
										onClick={closeModal}
										className='modal-close-attachment'
										style={{ outline: 'none' }}
									>
										<img src='/img/cross.png' alt='' />
									</button>
								</div>
							</div>
						</div>
						<div className='modal-body'>
							<div className='row'>
								<div
									className='col d-flex align-items-center justify-content-center'
									// style={{ width: 'auto', height: '100%' }}
								>
									<img className='img-fluid' src={activeImage} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = state => {
	return {
		messagesByTicket: state.messagesReducer.messages,
	}
}

export default connect(mapStateToProps, null)(Tickets)

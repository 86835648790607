import React from 'react'
import getSliceColor from './getColor'
import Progressbar from 'react-js-progressbar'
import EmailBodyCardNew from './EmailBodyCardNew'

const EmailBodyInsight = ({ bodyStructureinsight }) => {
	return (
		<>
			<div className='d-flex p-2 mb-3'>
				<div className='icon-bg d-flex'>
					<img src='/assets/img/analyzer-email-body.png' alt='' />
				</div>
				<div className='ml-3 mt-3'>
					<b>Email Body Structure Insights</b>
				</div>
			</div>

			<div
				className='row'
				style={{
					filter:
						bodyStructureinsight.percentage === 'block' ? `blur(${'5px'})` : '',
				}}
			>
				<div className='col-12 mb-3'>
					<div className='card flex-row py-2 insight-card analyzer-card'>
						<div className='col-3 ml-2 my-2 text-center'>
							<Progressbar
								size={100}
								pathWidth={30}
								trailWidth={50}
								ease={'linear'}
								pathShadow='none'
								clockwise={false}
								textStyle={{
									fontWeight: '800',
									fontSize: '44px',
								}}
								trailColor='#F2F2F2'
								pathLinecap={'round'}
								animation={{ ease: 'linear' }}
								input={
									bodyStructureinsight.percentage === 'block'
										? Math.floor(Math.random() * (90 - 10) + 10)
										: bodyStructureinsight.percentage
								}
								pathColor={getSliceColor(
									bodyStructureinsight.percentage === 'block'
										? Math.floor(Math.random() * (90 - 10) + 10)
										: bodyStructureinsight.percentage
								)}
							></Progressbar>
						</div>
						<div className='card-body'>
							<div>
								<b
									style={{
										display: 'table-cell',
										verticalAlign: 'middle',
										fontSize: '0.9vw',
										fontWeight: '700',
									}}
								>
									Overall Email Body Structure Results
								</b>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row py-3'>
				{bodyStructureinsight.details !== undefined ? (
					bodyStructureinsight.details.length === 0 ? (
						''
					) : (
						bodyStructureinsight.details.map((item, i) => (
							<EmailBodyCardNew
								key={i}
								msg={item.msg}
								help={item.help}
								name={item.name}
								color={item.color}
								description={item.msg}
								suggested={item.suggested}
								percentage={item.percentage}
								customPercentage={Math.floor(Math.random() * (90 - 10) + 10)}
							/>
						))
					)
				) : (
					<></>
				)}
			</div>
		</>
	)
}

export default EmailBodyInsight

import './message.scss'
import moment from 'moment'
import { useState } from 'react'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { API_CALL_IN_PROGRESS } from '../../store/types'
import TicketService from '../../api-services/ticket.service'
import { listMessagesByTicket } from '../../store/actions/messages.action'
import { toast } from 'react-toastify'

const MessageSection = ({ messagesByTicket, apiCallInProgress }) => {
	let messageSection = document.getElementById('message-section')

	const dispatch = useDispatch()

	const [ticketId, setTicketId] = useState(0)
	const [sending, setSending] = useState(false)
	const [messageObject, setMessageObject] = useState({
		message: '',
		support_ticket_id: 0,
	})
	const [ticketOpenStatus, setTicketOpenStatus] = useState(false)

	useEffect(() => {
		const interval = setInterval(() => {
			// if (ticketId !== 0) {
			if (!apiCallInProgress) {
				dispatch({ type: API_CALL_IN_PROGRESS })
				dispatch(listMessagesByTicket(ticketId))
			}
			// }
		}, 8000)

		return () => clearInterval(interval)
	}, [apiCallInProgress])

	const sendMessage = async e => {
		e.preventDefault()
		setSending(true)

		try {
			const response = await TicketService.addMessageToTicket(messageObject)

			dispatch(listMessagesByTicket(ticketId))
			setMessageObject({ ...messageObject, message: '' })
		} catch (error) {
			toast.error(error.response.data.message, {
				autoClose: 5000,
				draggable: true,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		} finally {
			setSending(false)
		}
	}

	useEffect(() => {
		if (messagesByTicket !== undefined) {
			if (messagesByTicket.length !== 0) {
				setMessageObject({
					...messageObject,
					support_ticket_id:
						JSON.parse(localStorage.getItem('currentTicket')) !== null
							? JSON.parse(localStorage.getItem('currentTicket')).id
							: '',
				})
				setTicketId(
					JSON.parse(localStorage.getItem('currentTicket')) !== null
						? JSON.parse(localStorage.getItem('currentTicket')).id
						: ''
				)
				setTicketOpenStatus(
					JSON.parse(localStorage.getItem('currentTicket')) !== null
						? !JSON.parse(localStorage.getItem('currentTicket')).is_closed
						: ''
				)
			} else {
				setMessageObject({
					...messageObject,
					support_ticket_id:
						JSON.parse(localStorage.getItem('currentTicket')) !== null
							? JSON.parse(localStorage.getItem('currentTicket')).id
							: '',
				})
				setTicketId(
					JSON.parse(localStorage.getItem('currentTicket')) !== null
						? JSON.parse(localStorage.getItem('currentTicket')).id
						: ''
				)
				setTicketOpenStatus(
					JSON.parse(localStorage.getItem('currentTicket')) !== null
						? !JSON.parse(localStorage.getItem('currentTicket')).is_closed
						: ''
				)
			}
		}
	}, [messagesByTicket])

	return (
		<div
			style={{
				width: '34vw',
				height: '100vh',
			}}
		>
			<div className='row mb-3'>
				<div className='col-1 text-center px-0'>
					<i
						className='fas fa-times'
						style={{ cursor: 'pointer' }}
						onClick={() => {
							messageSection.classList.remove('view-message-section')
						}}
					></i>
				</div>

				<div className='col px-0'>
					<h4 className='m-0 font-weight-bold text-primary text-center'>
						Support Chat
					</h4>
				</div>
			</div>

			<div className='row'>{}</div>

			<div
				style={{
					width: '95%',
					height: '85vh',
					overflowY: 'auto',
				}}
				className='row mb-3 ml-3 d-flex align-items-end'
			>
				<div
					className='col'
					// style={{ width: '100%' }}
					// style={{ position: 'fixed', bottom: '5.5%', width: '32%' }}
				>
					{messagesByTicket !== undefined ? (
						messagesByTicket.map((message, i) => (
							<div
								className='row'
								style={{
									justifyContent: message.user.is_admin
										? 'flex-end'
										: 'flex-start',
								}}
							>
								<div
									id={
										message.user.is_admin
											? 'message-container-sender'
											: 'message-container-reciever'
									}
								>
									<div className='col pl-0'>
										<p className='text-light p-0 m-0 fw-bold row'>
											<div className='col'>{message.message}</div>
										</p>
										<small className='text-light p-0 row'>
											<div className='col pr-0'>
												<div style={{ float: 'right' }}>
													{moment(message.created_at).format('hh:mm A')}
												</div>
											</div>
										</small>
									</div>
								</div>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div>

			{ticketOpenStatus ? (
				<div className='row'>
					<div className='col-10'>
						<input
							type='text'
							style={{ width: '100%', height: '100%' }}
							value={messageObject.message}
							onChange={e =>
								setMessageObject({ ...messageObject, message: e.target.value })
							}
						/>
					</div>
					<div className='col'>
						<button
							className='btn btn-primary'
							type='submit'
							onClick={sendMessage}
						>
							Send
						</button>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		messagesByTicket: state.messagesReducer.messages,
		apiCallInProgress: state.apiCallStatusReducer.apiCallInProgress,
	}
}

export default connect(mapStateToProps, null)(MessageSection)

import API from './api'

const PlanService = {
  list: (pageNumber = 1) => {
    return API.get(`/admin/packages?page=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  add: data => {
    return API.post('/admin/packages', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  disable: (id, data) => {
    return API.put(`/admin/packages/package-disable/${id}`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  get: id => {
    return API.get(`/admin/packages/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  delete: id => {
    return API.get(`admin/users/suspend/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  update: (id, data) => {
    return API.put(`admin/packages/${id}`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getCustomPackage: () => {
    return API.get('admin/custom-package-detail')
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  }
}

export default PlanService

import './App.css'
import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

// layout
import NoAuth from './components/layouts/no-auth'
import VerticalLayout from './components/layouts/vertical-layout'

// Import Routes
import AppRoute from './routes/route'
import { authProtectedRoutes, publicRoutes } from './routes'

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              key={idx}
              layout={NoAuth}
              path={route.path}
              isAuthProtected={false}
              component={route.component}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              exact
              key={idx}
              path={route.path}
              isAuthProtected={true}
              layout={VerticalLayout}
              component={route.component}
            />
          ))}
        </Switch>
      </Router>
    </>
  )
}

export default App

import API from './api'

const NotificationService = {
  sendMessage: data => {
    return API.post(`/send-global-notification`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('User service err', err)
        throw err
      })
  },
  getSystemNotifications: () => {
    return API.get(`admin/global-notification`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        console.log('User service err', err)
        throw err
      })
  },
  markAllNotificationsAsRead: () => {
    return API.get(`admin/view-notification`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },
  markAllTicketsAsRead: () => {
    return API.get(`admin/view-ticket`)
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        throw err
      })
  },

}

export default NotificationService
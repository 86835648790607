import API from './api'

const UserService = {
  getAllUsers: (pageNumber = 1) => {
    return API.get(`/admin/users?page=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  addNewUser: data => {
    return API.post('/admin/users', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  unblockUser: id => {
    return API.post(`/admin/unblockuser/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getBlockedUsers: () => {
    return API.get(`/admin/blocked-users`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getUserPackageDetails: userId => {
    return API.get(`/admin/get-user-package/${userId}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  updateUserPackageDetails: (userId, data) => {
    return API.post(`/admin/update-user-package-features/${userId}`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getTotalWarmUp: Id => {
    return API.get(`/admin/user-total-warmup-campains/${Id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getTotalDnsChecker: Id => {
    return API.get(`/admin/user-total-domainhealth-checked/${Id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getTotalSpamChecker: Id => {
    return API.get(`/admin/user-total-spam-checked/${Id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getWarmupAccounts: (id, pageNumber = 1) => {
    return API.get(`admin/user-warmup-campaigns/${id}?page=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  activateUser: id => {
    return API.get(`admin/users/activate/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  suspendUser: id => {
    return API.get(`admin/users/suspend/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  approveAffiliate: id => {
    return API.get(`admin/approve-affiliate-request/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  disApproveAffiliate: id => {
    return API.get(`admin/disapprove-affiliate-request/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  refferalStats: id => {
    return API.get(`/admin/user_affiliates/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  updatePayoutRate: data => {
    return API.post(`admin/payoutrates/${data.id}`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  payoutAll: (id, totalAmount) => {
    return API.post(`/admin/userpayout/${id}`, { amount: totalAmount })
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  setPayout: data => {
    return API.post(`admin/userpayoutrate?pauout_rate=${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getGraderStatsByUser: data => {
    return API.get(`admin/users/potiential-inbox-list/${data}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getUserStats: () => {
    return API.get(`admin/user-filters`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        return err
      })
  },
  bulkApprove: data => {
    return API.post(`admin/bulk-user-approve`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  bulkSuspend: data => {
    return API.post(`admin/bulk-user-suspend`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  searchUsers: searchString => {
    return API.get(`admin/admin-user-search?username=${searchString}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  getFilteredUsers: filter => {
    return API.get(
      `admin/user-record-filters?TotalUsers=${filter.totalUsers}&InactiveUsers=${filter.inActiveUsers}&TotalAffiliateUsers=${filter.totalAffiliateUsers}&ActiveUsers=${filter.activeUsers}&PendingAffiliateUsers=${filter.pendingAffiliateUsers}&ApprovedAffiliateUsers=${filter.approvedAffiliateUsers}&packageid=${filter.packageId}&username=${filter.username}&page=${filter.activePage}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        return err
      })
  },
  getFilteredUsersByPackage: (
    Trial = '',
    Basic = '',
    Buisness = '',
    LifeTimeDeal = '',
    PremiumSupport = '',
    pageNumber = 1
  ) => {
    return API.get(
      `admin/user-package-filter?Trial=${Trial}&LifeTimeDeal=${LifeTimeDeal}&Basic=${Basic}&PremiumSupport=${PremiumSupport}&Buisness=${Buisness}&page=${pageNumber}`
    )
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        return err
      })
  },
}

export default UserService

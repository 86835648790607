import API from './api'

const StatsService = {
  getDashboardStats: (data) => {
    return API.get(`/admin/dashboard/stats`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  }
}

export default StatsService

import moment from 'moment'
import React, { useEffect, useState } from 'react'

const RefferalTable = ({ stats }) => {
	return (
		<>
			<div className='container-fluid'>
				<div className='row'>
					<div className='table-responsive'>
						<table className='table table-sm'>
							<thead>
								<tr>
									<th>
										Date
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
									<th className='text-center'>
										Clicks
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
									<th className='text-center'>
										Signups
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
									<th className='text-center'>
										Earnings
										<span className='sort-controls'>
											<img className='up' src='/img/up-sort.png' />
											<img className='down' src='/img/down-sort.png' />
										</span>
									</th>
									{/* <th className='text-center'>Action</th> */}
								</tr>
							</thead>
							<tbody>
								{stats.length != 0
									? stats['DailyTracking'].map((data, i) => (
											<tr key={i}>
												<td>{moment(data.date).format('ll')}</td>
												<td className='text-center'>{data.clicks}</td>
												<td className='text-center'>{data.singup}</td>
												<td className='text-center'>$ {data.earned}</td>
												{/* <td className='text-center'>
												<button
													className='btn btn-sm btn-info'
													id={i}
													onClick={() => handelClick(data.id, i)}
													disabled={data.status === 1 ? false : true}
												>
													{data.status === 1 ? 'Payout' : 'Completed'}
												</button>
											</td> */}
											</tr>
									  ))
									: ''}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default RefferalTable

import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import AuthService from '../../api-services/auth.service'
import { Link, useHistory, useParams } from 'react-router-dom'

const ResetPass = props => {
	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [passwordResetForm, setPasswordResetForm] = useState({
		token: '',
		email: '',
		password: '',
		password_confirmation: '',
	})

	const { id } = useParams()
	const { history } = useHistory()

	const handleFormChange = event => {
		const { name, value } = event.target
		setPasswordResetForm({ ...passwordResetForm, [name]: value })
	}

	const handleResetPasswordFormSubmit = async event => {
		event.preventDefault()

		setLoading(true)
		try {
			const response = await AuthService.resetPassword(passwordResetForm)

			if (response.success !== undefined && !response.success)
				toast.error(response.message)
			else {
				toast.success('Password changed successfully.')
				setTimeout(() => {
					props.history.push('/login')
				}, 3000)
			}
		} catch (e) {
			if (e.response.status == 422) {
				const errors = e.response.data.errors
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		setPasswordResetForm({
			...passwordResetForm,
			token: id,
			email: new URLSearchParams(props.location.search).get('email'),
		})
	}, [])

	return (
		<div className='d-flex flex-grow-1 align-self-center card o-hidden border-0 shadow-lg my-5'>
			<div className='card-body p-0'>
				<div className='row'>
					<div className='col-lg-6 d-none d-lg-block bg-login-image'></div>
					<div className='col-lg-6'>
						<div className='p-5'>
							<div className='text-center'>
								<h1 className='h2 text-gray-900 mb-4'>
									<strong>Inbox Better</strong>
								</h1>
							</div>
							<div className='text-center'>
								<h1 className='h4 text-gray-900 mb-5'>Reset Password</h1>
							</div>
							<form className='user' onSubmit={handleResetPasswordFormSubmit}>
								<div className='form-group'>
									<input
										required
										disabled
										readonly
										type='email'
										name='email'
										placeholder='Email *'
										onChange={handleFormChange}
										value={passwordResetForm.email}
										className='form-control form-control-user'
									/>
									{errors.hasOwnProperty('email')
										? errors.email.map(e => (
												<span className='text-danger mt-1 ml-1'> {e} </span>
										  ))
										: ''}
								</div>
								<div className='form-group'>
									<input
										required
										name='password'
										type='password'
										placeholder='Password *'
										onChange={handleFormChange}
										value={passwordResetForm.password}
										className='form-control form-control-user'
									/>
									{errors.hasOwnProperty('password')
										? errors.password.map(e => (
												<span className='text-danger mt-1 ml-1'> {e} </span>
										  ))
										: ''}
								</div>
								<div className='form-group'>
									<input
										required
										type='password'
										onChange={handleFormChange}
										name='password_confirmation'
										placeholder='Confirm Password *'
										className='form-control form-control-user'
										value={passwordResetForm.password_confirmation}
									/>
								</div>
								<button
									type='submit'
									disabled={loading}
									className='btn btn-primary btn-user btn-block'
								>
									Submit
								</button>
							</form>
							<hr />
							<div className='text-center'>
								<Link className='small' to='/login'>
									Back to Login
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ResetPass

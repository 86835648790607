import '../Users.scss'
import React, { useState } from 'react'
import userService from '../../../api-services/user.service'
import { toast } from 'react-toastify'

const AffiliateSettings = () => {
	const [rate, setRate] = useState(0)
	const [error, setError] = useState({ affiliate: [] })
	const [loading, setLoading] = useState(false)

	const handleRateChange = e => {
		if (
			e.target.value < 0 ||
			e.target.value > 100 ||
			!/^[0-9]*$/.test(e.target.value)
		) {
			if (error.affiliate.length === 0) {
				setError({
					...error,
					affiliate: [...error.affiliate, 'Select a value from 1 and 100.'],
				})
			}
		} else {
			if (error.affiliate.length !== 0) {
				let arr = error.affiliate
				arr.shift()

				setError({
					...error,
					affiliate: arr,
				})
			}
		}
		setRate(e.target.value)
	}

	const setPayoutRate = async () => {
		setLoading(true)
		try {
			const response = await userService.setPayout(rate)

			if (response.success) {
				toast.success(response.message, {
					autoClose: 2500,
					draggable: true,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setLoading(false)
			}
		} catch (e) {
			setError(e)
			toast.error(e.response.data.message, {
				autoClose: 2500,
				draggable: true,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			setLoading(false)
		}
	}
	return (
		<>
			<div className='row my-3'>
				<div className='col-sm-4 form-group'>
					<label htmlFor='protocol' className='label-search'>
						Set default payout rate
					</label>
					<div class='input-group mb-3'>
						<input
							min={0}
							max={100}
							type='number'
							placeholder='Rate'
							class='form-control'
							onChange={handleRateChange}
							aria-describedby='button-addon2'
							aria-label="Recipient's username"
						/>
						{error.hasOwnProperty('affiliate')
							? error.affiliate.map(e => (
									<span className='text-danger mt-2 ml-1'>* {e} </span>
							  ))
							: ''}
					</div>
				</div>
				<div className='col search_btn'>
					<button
						type='button'
						id='button-addon2'
						onClick={setPayoutRate}
						class='btn btn-outline-secondary'
						disabled={loading || rate < 0 || rate > 100}
					>
						Done
					</button>
				</div>
			</div>
		</>
	)
}

export default AffiliateSettings

import UserPlan from './UserPlan'
import Referrals from './refferral/Referrals'
import WarmupStatsTable from './WarmupStatsTable'
import React, { useState, useEffect } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import { Link, useParams } from 'react-router-dom'
import UserService from '../../api-services/user.service'
import PlanService from '../../api-services/plan.service'
import SystemFeaturesService from '../../api-services/systemFeatures.service'
import { toast } from 'react-toastify'
import GraderStats from './grader_stats'

const UserStats = () => {
	const { id } = useParams()

	const [plan, setPlan] = useState({})
	const [planForm, setPlanForm] = useState({
		name: '',
		price: '',
		group_tag: '',
		systemFeature: [],
		duration_in_days: '',
	})
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [totalWarmup, setTotalWarmup] = useState(0)
	const [systemFeatures, setSystemFeatures] = useState([])
	const [warmupAccounts, setWarmupAccounts] = useState([])
	const [totalDnsChecker, setTotalDnsChecker] = useState(0)
	const [totalSpamChecker, setTotalSpamChecker] = useState(0)
	const [metaData, setMetaData] = useState({ total: 0, per_page: 0 })

	const fetchSystemFeatures = async () => {
		try {
			let tempArr = []
			const response = await SystemFeaturesService.list()

			setSystemFeatures(response.systemFeature)
		} catch (error) {
			console.log(error)
		}
	}

	const fetchData = async () => {
		setLoading(true)
		try {
			const response = await UserService.getTotalWarmUp(id)
			setTotalWarmup(response.campainsCount)
		} catch (e) {
			setError(e)
		}
		try {
			const response = await UserService.getTotalDnsChecker(id)
			setTotalDnsChecker(response.DomainHealthCount)
		} catch (e) {
			setError(e)
		}
		try {
			const response = await UserService.getTotalSpamChecker(id)
			setTotalSpamChecker(response.SpamCount)
		} catch (e) {
			setError(e)
		}
		try {
			const response = await UserService.getWarmupAccounts(id)
			let tempMetaDataObj = { per_page: 0, total: 0 }

			tempMetaDataObj.per_page = response.warmupCampains.per_page
			tempMetaDataObj.total = response.warmupCampains.total

			setWarmupAccounts(response.warmupCampains.data)
			setMetaData(tempMetaDataObj)
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
		}
	}

	const fetchPlanDetails = async () => {
		setLoading(true)
		try {
			const response = await UserService.getUserPackageDetails(id)

			let tempPlanObj = {
				name: '',
				price: '',
				systemFeature: [],
				is_disabled: false,
				duration_in_days: '',
			}

			tempPlanObj.name = response.UserPackageDetails[0].package.name
			tempPlanObj.price = response.UserPackageDetails[0].package.price
			tempPlanObj.group_tag = response.UserPackageDetails[0].package.group_tag
			tempPlanObj.is_disabled =
				response.UserPackageDetails[0].package.is_disabled
			tempPlanObj.duration_in_days =
				response.UserPackageDetails[0].package.duration_in_days
			if (response.UserPackageDetails[0].user_packagefeatures !== undefined)
				response.UserPackageDetails[0].user_packagefeatures.forEach(
					(feature, i) => {
						tempPlanObj.systemFeature.push({
							id: feature.pivot.package_feature_id,
							max_allowed_count: feature.pivot.max_allowed_count,
						})
					}
				)

			setPlanForm(tempPlanObj)
			setPlan(response.Package)
			setLoading(false)
		} catch (error) {
			setLoading(false)

			console.log(error)
		}
	}

	const handleEditPlan = async () => {
		setUpdating(true)
		try {
			let tempObj = planForm

			delete tempObj.name
			delete tempObj.price
			delete tempObj.group_tag
			delete tempObj.is_disabled
			delete tempObj.duration_in_days

			setPlanForm(tempObj)

			const response = await UserService.updateUserPackageDetails(id, tempObj)

			if (response.success) {
				toast.success(response.message)
				const modal = document.getElementById('planModal')
				document.body.classList.remove('modal-open')
				document.body.removeAttribute('style')
				let header = document.getElementsByClassName('header')
				// header[0].removeAttribute("style");
				modal.classList.remove('show')
				modal.setAttribute('aria-hidden', 'true')
				modal.style.display = 'none'
				modal.setAttribute('aria-modal', 'false')
				modal.setAttribute('data-bs-dismiss', 'modal')

				const modalBackdrops = document.getElementsByClassName('modal-backdrop')

				modalBackdrops[0].classList.remove('show')
				document.body.removeChild(modalBackdrops[0])
				fetchPlanDetails()
			}
		} catch (error) {
			setUpdating(false)
			setLoading(false)
			console.log(error)
		} finally {
			setUpdating(false)
			setLoading(false)
		}
	}

	const handlePlanFormChange = event => {
		event.preventDefault()

		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		if (name === 'undefined' || name === undefined) {
			let found = false

			planForm.systemFeature.forEach(systemFeature => {
				if (
					systemFeature.max_allowed_count !== 0 &&
					systemFeature.id === parseInt(target.dataset.id) &&
					!found
				) {
					found = true
					let tempArr = []

					tempArr = planForm.systemFeature

					// setPlanForm({
					// 	...planForm,
					// 	systemFeature: tempArr.filter(
					// 		feature => feature.id !== parseInt(target.dataset.id)
					// 	),
					// })

					//New Logic

					tempArr.forEach(singleItem => {
						if (singleItem.id === parseInt(target.dataset.id)) {
							singleItem.max_allowed_count = 0
						}
					})
					setPlanForm({ ...planForm, systemFeature: tempArr })
				}
			})

			if (!found) {
				let tempArr = []
				tempArr = planForm.systemFeature

				// tempArr.push({ id: parseInt(target.dataset.id), max_allowed_count: 0 })

				// setPlanForm({ ...planForm, systemFeature: tempArr })

				//New Logic

				tempArr.forEach(singleItem => {
					if (singleItem.id === parseInt(target.dataset.id)) {
						singleItem.max_allowed_count = 1
					}
				})

				setPlanForm({ ...planForm, systemFeature: tempArr })
			}
		} else if (name === 'max_allowed_count') {
			planForm.systemFeature.forEach(systemFeature => {
				if (systemFeature.id === parseInt(target.dataset.id)) {
					let tempArr = []
					let filteredArr = []

					tempArr = planForm.systemFeature

					filteredArr = tempArr.filter(
						feature => feature.id !== parseInt(target.dataset.id)
					)

					filteredArr.push({
						id: parseInt(target.dataset.id),
						max_allowed_count: parseInt(target.value),
					})

					setPlanForm({
						...planForm,
						systemFeature: filteredArr,
					})
				}
			})
		} else setPlanForm({ ...planForm, [name]: value })
	}

	useEffect(() => {
		fetchData()
		fetchPlanDetails()
		fetchSystemFeatures()
	}, [])

	return (
		<>
			{loading ? (
				<div
					style={{
						marginLeft: '50%',
						height: '30px',
						marginBottom: '20px',
						marginTop: '25%',
					}}
				>
					<FadeLoader loading={true} color={'#0092FB'} height={20} width={5} />
				</div>
			) : (
				<>
					<div className='row mt-4'>
						<div className='col'>
							<nav aria-label='breadcrumb'>
								<ol className='breadcrumb'>
									<li className='breadcrumb-item'>
										<Link
											to={{
												pathname: `/`,
											}}
										>
											Home
										</Link>
									</li>
									<li className='breadcrumb-item'>
										<Link
											to={{
												pathname: `/users`,
											}}
										>
											Users
										</Link>
									</li>
									<li className='breadcrumb-item active' aria-current='page'>
										User Stats
									</li>
								</ol>
							</nav>
						</div>
					</div>
					<div className='d-flex align-items-center'>
						<div className='card shadow mb-4 flex-grow-1'>
							<a
								role='button'
								aria-expanded='true'
								data-toggle='collapse'
								href='#collpaseStatisticsCard'
								className='d-block card-header py-3'
								aria-controls='collpaseStatisticsCard'
							>
								<h6 className='m-0 font-weight-bold text-primary'>
									Statistics
								</h6>
							</a>

							<div className='collapse show' id='collpaseStatisticsCard'>
								<div className='d-flex flex-column'>
									<div className='card-body d-flex align-items-center'>
										<div className='flex-grow-1 mr-2'>
											<div className='card'>
												<div className='d-flex align-items-center'>
													<div className='dcard_icond mx-2 ml-4'>
														<img
															alt='icon'
															className='dcard_imgd'
															src='/img/warmup-icon.png'
														/>
													</div>
													<div className=''>
														<div className='card-body cardbody'>
															<h5 className='card-title font-weight-bold'>
																{totalWarmup}
															</h5>
															<p className='card-text'>Total Warm-Ups</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='flex-grow-1 mr-2'>
											<div className='card'>
												<div className='d-flex align-items-center'>
													<div className='dcard_icond mx-2 ml-4'>
														<img
															alt='icon'
															className='dcard_imgd'
															src='/img/dns-checker.png'
														/>
													</div>
													<div className=''>
														<div className='card-body cardbody'>
															<h5 className='card-title font-weight-bold'>
																{totalDnsChecker}
															</h5>
															<p className='card-text'>Total Dns Checks</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='flex-grow-1'>
											<div className='card'>
												<div className='d-flex align-items-center'>
													<div className='dcard_icond mx-2 ml-4'>
														<img
															alt='icon'
															className='dcard_imgd'
															src='/img/spam-checker-blue.svg'
														/>
													</div>
													<div className=''>
														<div className='card-body cardbody'>
															<h5 className='card-title font-weight-bold'>
																{totalSpamChecker}
															</h5>
															<p className='card-text'>Total Spam Checks</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='flex-grow-1 mt-4'>
										<WarmupStatsTable
											id={id}
											metaData={metaData}
											setMetaData={setMetaData}
											warmupAccounts={warmupAccounts}
											setWarmupAccounts={setWarmupAccounts}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='d-flex align-items-center'>
						<div className='card shadow mb-4 flex-grow-1'>
							<a
								role='button'
								aria-expanded='true'
								data-toggle='collapse'
								href='#collapsePlanDetailsCard'
								className='d-block card-header py-3'
								aria-controls='collapsePlanDetailsCard'
							>
								<h6 className='m-0 font-weight-bold text-primary'>
									Plan Details
								</h6>
							</a>

							<div className='collapse show' id='collapsePlanDetailsCard'>
								<div className='d-flex flex-column'>
									<div className='card-body d-flex align-items-center'>
										<UserPlan
											usageFlag='editUser'
											planForm={planForm}
											updating={updating}
											setPlanForm={setPlanForm}
											handleEditPlan={handleEditPlan}
											systemFeatures={systemFeatures}
											handlePlanFormChange={handlePlanFormChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='d-flex align-items-center'>
						<div className='card shadow mb-4 flex-grow-1'>
							<a
								role='button'
								aria-expanded='true'
								data-toggle='collapse'
								href='#collapseGraderUsageDetailsCard'
								className='d-block card-header py-3'
								aria-controls='collapseGraderUsageDetailsCard'
							>
								<h6 className='m-0 font-weight-bold text-primary'>
									Grader Usage Details
								</h6>
							</a>

							<div
								className='collapse show'
								id='collapseGraderUsageDetailsCard'
							>
								<div className='d-flex flex-column'>
									<div className='card-body d-flex align-items-center'>
										<GraderStats />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='d-flex align-items-center'>
						<div className='card shadow mb-4 flex-grow-1'>
							<a
								role='button'
								aria-expanded='true'
								data-toggle='collapse'
								href='#collapseRefferalDetailsCard'
								className='d-block card-header py-3'
								aria-controls='collapseRefferalDetailsCard'
							>
								<h6 className='m-0 font-weight-bold text-primary'>
									Referral Details
								</h6>
							</a>

							<div className='collapse show' id='collapseRefferalDetailsCard'>
								<div className='d-flex flex-column'>
									<div className='card-body d-flex align-items-center'>
										<Referrals breadCrumbFlag={false} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default UserStats

import API from './api'

const AuthService = {
  login: data => {
    return API.post('/admin/login', data)
      .then(({ data }) => {
        setHeadersAndStorage(data)
        return data
      })
      .catch(err => {
        throw err
      })
  },

  register: data => {
    return API.post('/register', data)
      .then(({ data }) => {
        setHeadersAndStorage(data)
        return data
      })
      .catch(err => {
        throw err
      })
  },

  logout: () => {
    return API.post('/logout', {})
      .then(({ data }) => {
        API.defaults.headers['Authorization'] = ''
        localStorage.clear()
        return data
      })
      .catch(err => {
        throw err
      })
  },

  sendPasswordResetLink: (data) => {
    return API.post("/admin/password/forget", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  resetPassword: (data) => {
    return API.post("/admin/password/reset", data)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },

  updateProfile: data => {
    const headers = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    return API.post('/users/update', data, headers)
      .then(({ data }) => {
        localStorage.setItem('user', JSON.stringify(data))
        return data
      })
      .catch(err => {
        throw err
      })
  },
}

const setHeadersAndStorage = ({ token, expires_in, user }) => {
  localStorage.setItem('token', token)
  localStorage.setItem('expiresIn', expires_in)
  localStorage.setItem('user', JSON.stringify(user))
  API.defaults.headers['Authorization'] = `Bearer ${token}`
}

export default AuthService

import React, { useState, useEffect } from 'react'
import RefferalStats from './RefferalStats'
import RefferalTable from './RefferalTable'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import UserService from '../../../api-services/user.service'
import FadeLoader from 'react-spinners/FadeLoader'
import { toast } from 'react-toastify'

const Referrals = ({ breadCrumbFlag = true }) => {
	let { id } = useParams()

	const [stats, setStats] = useState([])
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [btnLoading, setBtnLoading] = useState(false)

	const getStats = async id => {
		setLoading(true)
		try {
			const response = await UserService.refferalStats(id)
			setStats(response)
		} catch (e) {
			console.log(e)
		}
		setLoading(false)
	}

	const handlePayoutAll = async () => {
		try {
			setBtnLoading(true)
			const response = await UserService.payoutAll(id, stats.UnpaidAmount)
			if (!response.success) {
				toast.error('Payout not allowed, User has not submitted W9 Form.', {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
			} else {
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				getStats(id)
			}
			setBtnLoading(false)
		} catch (e) {
			setError(e.response.Message)
			// toast.error(e.response.Message, {
			// 	autoClose: 2500,
			// 	closeOnClick: true,
			// 	position: 'top-right',
			// 	hideProgressBar: false,
			// })
		}
	}

	useEffect(() => {
		getStats(id)

		return () => {
			setStats([])
		}
	}, [])

	return (
		<>
			{breadCrumbFlag ? (
				<div className='row mt-4'>
					<div className='col'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<Link
										to={{
											pathname: `/`,
										}}
									>
										Home
									</Link>
								</li>
								<li className='breadcrumb-item'>
									<Link
										to={{
											pathname: `/users`,
										}}
									>
										Users
									</Link>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									Referrals
								</li>
							</ol>
						</nav>
					</div>
				</div>
			) : (
				<></>
			)}

			{loading ? (
				<div
					style={{
						marginLeft: '50%',
						height: '30px',
						marginBottom: '20px',
					}}
				>
					<FadeLoader loading={true} color={'#0092FB'} height={20} width={5} />
				</div>
			) : (
				<div className='d-flex flex-grow-1 flex-column'>
					<RefferalStats stats={stats} />

					{stats.DailyTracking !== undefined ? (
						stats.DailyTracking.length !== 0 ? (
							<>
								<div id='payout-btn'>
									<button
										disabled={btnLoading}
										onClick={handlePayoutAll}
										className='btn btn-success my-2 float-right'
									>
										<i class='far fa-money-bill-wave mr-1'></i>Payout Remaining
										Amount
									</button>
								</div>
								<RefferalTable stats={stats} userId={id} getStats={getStats} />
							</>
						) : (
							<h4 className='text-center'>No Payouts Remaining</h4>
						)
					) : (
						<></>
					)}
				</div>
			)}
		</>
	)
}

export default Referrals

import React, { useState, useEffect } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import { Link, useParams } from 'react-router-dom'
import InvoiceService from '../../api-services/invoice.service'

const AffiliateInvoiceDetail = () => {
	const { id } = useParams()

	const [invoice, setInvoice] = useState({})
	const [loading, setLoading] = useState(false)

	const fetchInvoiceDetails = async () => {
		setLoading(true)
		try {
			const response = await InvoiceService.get(id)

			setInvoice(response.data)
			setLoading(false)
		} catch (error) {
			setLoading(false)

			console.log(error)
		}
	}

	useEffect(() => {
		fetchInvoiceDetails()

		return () => {
			setInvoice([])
			setLoading(false)
		}
	}, [])

	return (
		<>
			{loading ? (
				<div
					style={{
						marginLeft: '50%',
						height: '30px',
						marginBottom: '20px',
						marginTop: '25%',
					}}
				>
					<FadeLoader loading={true} color={'#0092FB'} height={20} width={5} />
				</div>
			) : (
				<>
					<div className='row mt-4'>
						<div className='col'>
							<nav aria-label='breadcrumb'>
								<ol className='breadcrumb'>
									<li className='breadcrumb-item'>
										<Link
											to={{
												pathname: `/`,
											}}
										>
											Home
										</Link>
									</li>
									<li className='breadcrumb-item'>
										<Link
											to={{
												pathname: `/invoices/affiliate`,
											}}
										>
											Invoices
										</Link>
									</li>
									<li className='breadcrumb-item active' aria-current='page'>
										{id}
									</li>
								</ol>
							</nav>
						</div>
					</div>
					<div className='row'>
						<div className='col-12'>
							<div className='card'>
								<div className='card-header d-flex align-items-center justify-content-between'>
									<h4 className='mb-0' style={{ fontWeight: '600' }}>
										INB-{' '}
										{invoice.InvoiceDetails !== undefined
											? invoice.InvoiceDetails.id
											: ''}
									</h4>
								</div>
								<div className='card-body'>
									<div className='row p-3'>
										<div className='col card p-3'>
											<div className='row d-flex align-items-center justify-content-center'>
												<h4 style={{ fontWeight: '700', color: '#4C71DE' }}>
													Invoice #
												</h4>
												<h4 className='ml-2'>
													INB-
													{invoice.InvoiceDetails !== undefined
														? invoice.InvoiceDetails.id
														: ''}
												</h4>
											</div>
										</div>
										<div className='col card p-3 ml-3'>
											<div className='row d-flex align-items-center justify-content-center'>
												<h4 style={{ fontWeight: '700', color: '#4C71DE' }}>
													Remaining Amount
												</h4>
												<h4 className='ml-2'>$ {invoice.RemainingAmount}</h4>
											</div>
										</div>
									</div>
									<div className='d-flex'>
										{invoice.InvoiceDetails !== undefined ? (
											invoice.InvoiceDetails.affiliates.length === 0 ? (
												<p className='text-center'>
													No Affiliates for this Invoice
												</p>
											) : (
												<div className='col'>
													<div className='row mb-2'>
														<div className='col'>
															<h5
																className='mb-0'
																style={{ fontWeight: '600', color: '#4C71DE' }}
															>
																Affiliate Invoices in INB-
																{invoice.InvoiceDetails !== undefined
																	? invoice.InvoiceDetails.id
																	: ''}
															</h5>
														</div>
													</div>
													<div className='d-flex flex-row align-items-center'>
														<table className='table table-sm'>
															<thead>
																<tr>
																	<th scope='col' className='text-center'>
																		Clicks
																	</th>
																	<th scope='col' className='text-center'>
																		Signups
																	</th>
																	<th scope='col' className='text-center'>
																		Earned
																	</th>
																	<th scope='col' className='text-center'>
																		Payout Status
																	</th>
																</tr>
															</thead>
															<tbody>
																{invoice.InvoiceDetails !== undefined
																	? invoice.InvoiceDetails.affiliates.map(
																			(affiliate, i) => (
																				<tr>
																					<td
																						scope='row'
																						className='dns_email_item py-2 text-center'
																					>
																						{affiliate.clicks}
																					</td>
																					<td
																						scope='row'
																						className='dns_email_item py-2 text-center'
																					>
																						{affiliate.signup}
																					</td>
																					<td
																						scope='row'
																						className='dns_email_item py-2 text-center'
																					>
																						$ {affiliate.earned_amount}
																					</td>
																					<td
																						scope='row'
																						className='dns_email_item py-2 text-center'
																					>
																						{affiliate.payout_status === 0
																							? 'Payout Remaining'
																							: 'Paid'}
																					</td>
																				</tr>
																			)
																	  )
																	: ''}
															</tbody>
														</table>
													</div>
												</div>
											)
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	)
}

export default AffiliateInvoiceDetail

import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

const Alerts = ({ type, alerts }) => {
	return (
		<div
			aria-labelledby='alertsDropdown'
			className='dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in'
		>
			<h6 className='dropdown-header'>
				{type === 'notification' ? 'Alerts Center' : 'Message Center'}
			</h6>
			<div style={{ maxHeight: '250px', overflowY: 'auto' }}>
				{type === 'notification'
					? alerts
							.filter(alert => !alert.is_ticket)
							.map((alert, i) => (
								<Link
									to='#'
									key={i}
									className='dropdown-item d-flex align-items-center'
								>
									<div className='mr-3'>
										<div
											className={`icon-circle ${
												alert.global_messages[0].message === null ||
												alert.global_messages[0].message === ''
													? 'bg-success'
													: 'bg-primary'
											}`}
										>
											<i
												className={`${
													alert.global_messages[0].message === null ||
													alert.global_messages[0].message === ''
														? 'fas fa-user'
														: 'fas fa-file-alt'
												} text-white`}
											></i>
										</div>
									</div>
									<div>
										<div className='small text-gray-500'>
											{moment(
												moment.utc(alert.global_messages[0].created_at),
												'YYYYMMDD'
											).fromNow()}
										</div>
										<span
											className={`${
												!alert.view_message && !alert.is_ticket
													? 'font-weight-bold'
													: ''
											}`}
										>
											{alert.global_messages[0].message === null ||
											alert.global_messages[0].message === ''
												? alert.global_messages[0].subject
												: alert.global_messages[0].message}
										</span>
									</div>
								</Link>
							))
					: alerts
							.filter(alert => alert.is_ticket)
							.map((alert, i) => (
								<Link
									to={`/tickets/${alert.user_id}`}
									key={i}
									className='dropdown-item d-flex align-items-center'
								>
									<div className='dropdown-list-image mr-3'>
										<img
											className='img-profile rounded-circle'
											src={`https://ui-avatars.com/api/?name=${
												alert.user !== null ? alert.user.name : ''
											}&background=4E73DF&color=fff`}
										/>
									</div>
									<div
										className={`${
											!alert.view_message && alert.is_ticket
												? 'font-weight-bold'
												: ''
										}`}
									>
										<div className='text-truncate'>
											{alert.global_messages[0].message === null ||
											alert.global_messages[0].message === ''
												? alert.global_messages[0].subject
												: alert.global_messages[0].message}
										</div>
										<div className='small text-gray-500'>
											{alert.user !== null ? alert.user.name : ''} ·{' '}
											{moment(
												moment.utc(alert.global_messages[0].created_at),
												'YYYYMMDD'
											).fromNow(true)}
										</div>
									</div>
								</Link>
							))}
			</div>
		</div>
	)
}

export default Alerts

import API from './api'

const ConfigurationService = {
  add: (data) => {
    return API.post(`/admin/create-configuration`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  get: () => {
    return API.get(`/admin/get-configuration`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
}

export default ConfigurationService

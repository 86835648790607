import React from 'react'

const UserPlan = ({
	loading,
	planForm,
	updating,
	usageFlag,
	systemFeatures,
	handleEditPlan,
	handlePlanFormChange,
}) => {
	return (
		<div
			className={`flex-grow-1 flex-column ${
				usageFlag === 'addUser' ? 'p-0' : 'p-3'
			}`}
		>
			<div className='d-flex flex-row'>
				<div className='form-row flex-grow-1 mr-2'>
					<div className='form-group col-md-12'>
						<label htmlFor='name'>
							Plan Name <span style={{ color: 'red' }}>*</span>
						</label>
						<input
							required
							type='text'
							name='name'
							disabled={true}
							value={planForm.name}
							className='form-control'
							onChange={handlePlanFormChange}
						/>
					</div>
				</div>
				{/* Email & Password */}
				{usageFlag === 'editUser' ? (
					<div className='form-row flex-grow-1 mr-2'>
						<div className='form-group col-12'>
							<label htmlFor='price'>
								Price <span style={{ color: 'red' }}>*</span>
							</label>
							<input
								min={0}
								required
								name='price'
								type='number'
								disabled={true}
								readOnly={true}
								pattern='[0-9]*'
								value={planForm.price}
								className='form-control'
								onChange={handlePlanFormChange}
								onInput={e => (e.target.value = Math.abs(e.target.value))}
							/>
						</div>
					</div>
				) : (
					<></>
				)}

				<div className='form-row flex-grow-1 mr-2'>
					<div className='form-group col-12'>
						<label htmlFor='duration_in_days'>
							Duration <span style={{ color: 'red' }}>*</span>
						</label>
						<input
							required
							disabled={true}
							name='duration_in_days'
							className='form-control'
							onChange={handlePlanFormChange}
							value={
								planForm.duration_in_days === 30
									? 'Monthly'
									: planForm.duration_in_days === 1
									? 'Daily'
									: 'Weekly'
							}
							onInput={e => (e.target.value = Math.abs(e.target.value))}
						/>
					</div>
				</div>
				<div className='form-row flex-grow-1'>
					<div className='form-group col-12'>
						<label htmlFor='group_tag'>
							Type <span style={{ color: 'red' }}>*</span>
						</label>
						<select
							id='group_tag'
							disabled={true}
							name='group_tag'
							className='form-control'
							value={planForm.group_tag}
							onChange={handlePlanFormChange}
						>
							<option
							// selected
							>
								Select a Tag
							</option>
							<option
								// selected={planForm.group_tag === 'Trial'}
								value='Trial'
							>
								Trial
							</option>
							<option
								// selected={planForm.group_tag === 'Monthly'}
								value='Recurring'
							>
								Recurring
							</option>
							<option
								// selected={planForm.group_tag === 'Life Time'}
								value='Life Time'
							>
								Life Time
							</option>
							<option
								// selected={planForm.group_tag === 'Life Time'}
								value='Custom Package'
							>
								Custom Package
							</option>
						</select>
					</div>
				</div>
			</div>

			<div className='d-flex flex-column'>
				<h5>System Features</h5>
				{systemFeatures !== undefined ? (
					systemFeatures.map((feature, i) => (
						<div
							key={i}
							className='d-flex flex-sm-row flex-md-row flex-lg-row flex-column align-items-center mb-2 mb-sm-0 mb-md-0 mb-lg-0'
						>
							<div
								id='feature-card'
								data-id={feature.id}
								className='card col-12 col-sm-9 col-md-9 col-lg-9 mb-1 mr-1'
								style={{
									cursor: 'pointer',
									border: `${
										planForm.systemFeature !== undefined
											? planForm.systemFeature.some(
													systemFeature =>
														systemFeature['id'] === feature.id &&
														systemFeature['max_allowed_count'] !== 0
											  )
												? '1px solid #0b95f8'
												: ''
											: ''
									}`,
									background: `${
										planForm.systemFeature !== undefined
											? planForm.systemFeature.some(
													systemFeature =>
														systemFeature['id'] === feature.id &&
														systemFeature['max_allowed_count'] !== 0
											  )
												? '#e2f1f4'
												: ''
											: ''
									}`,
								}}
								onClick={handlePlanFormChange}
							>
								<div
									id='feature-card'
									className='row d-flex align-items-center'
									data-id={feature.id}
								>
									<div
										id='feature-card'
										data-id={feature.id}
										className='dcard_icond mx-2'
									>
										<img
											data-id={feature.id}
											id='feature-card'
											className='dcard_imgd'
											name='undefined'
											src={
												feature.id === 1
													? '/img/warmup-icon.png'
													: feature.id === 2
													? '/img/dns-checker.png'
													: feature.id === 3
													? '/img/spam-checker-blue.svg'
													: feature.id === 4
													? '/img/potential-inboxing-blue.png'
													: feature.id === 5
													? '/img/sequence-icon.png'
													: ''
											}
											alt='icon'
										/>
									</div>
									<div className='' data-id={feature.id} id='feature-card'>
										<div
											id='feature-card'
											className='card-body cardbody'
											data-id={feature.id}
										>
											<h5
												id='feature-card'
												data-id={feature.id}
												className='card-title font-weight-bold mb-0'
												style={{ color: '#0B95F8' }}
											>
												{feature.name}
											</h5>
										</div>
									</div>
								</div>
							</div>
							<div className='col '>
								{/* {planForm.systemFeature !== undefined ? (
									planForm.systemFeature.some(
										systemFeature => systemFeature['id'] === feature.id
									) ? ( */}
								<div className='d-flex flex-sm-column flex-md-column flex-lg-column flex-row align-items-center justify-content-center'>
									<label
										htmlFor='allowedCount'
										className='card-title font-weight-bold mb-0 mr-2 mr-sm-0 mr-md-0 mr-lg-0 text-center'
									>
										Max Allowed
									</label>
									<input
										min={0}
										type='number'
										style={{ width: '50%' }}
										disabled={
											planForm.systemFeature.some(
												systemFeature =>
													systemFeature['id'] === feature.id &&
													systemFeature['max_allowed_count'] !== 0
											)
												? false
												: true
										}
										value={
											planForm.systemFeature.find(
												systemFeature => systemFeature.id === feature.id
											) !== undefined
												? planForm.systemFeature.find(
														systemFeature => systemFeature.id === feature.id
												  ).max_allowed_count
												: 0
										}
										id='allowedCount'
										data-id={feature.id}
										name='max_allowed_count'
										onChange={handlePlanFormChange}
										onInput={e => (e.target.value = Math.abs(e.target.value))}
									/>
								</div>
								{/* ) : (
										<></>
									) */}
								{/* ) : (<></>
								)} */}
							</div>
						</div>
					))
				) : (
					<></>
				)}
				{usageFlag === 'editUser' ? (
					<div className='d-flex flex-shrink-1 justify-content-end'>
						<button
							type='button'
							onClick={handleEditPlan}
							disabled={updating || loading}
							className='btn btn-primary from-email-add'
						>
							<i class='far fa-edit mr-1'></i>Edit Plan Details
						</button>
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default UserPlan

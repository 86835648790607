import { toast } from 'react-toastify'
import LoadingBar from 'react-top-loading-bar'
import React, { useState, useRef, useEffect } from 'react'
import NotificationService from '../../api-services/notification.service'

const MessageModal = ({ selectedUsers, closeModal, sendMessageModal }) => {
	const ref = useRef(null)

	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [notificationForm, setNotificationForm] = useState({
		id: [],
		file: [],
		subject: '',
		message: '',
		is_mail: false,
		is_notification: true,
	})

	const handleNotificationFormChange = event => {
		event.preventDefault()
		const target = event.target
		const name = target.name
		const value = target.type === 'checkbox' ? target.checked : target.value

		if (name === 'file' && notificationForm.file.length === 0)
			setNotificationForm({
				...notificationForm,
				file: [...target.files],
			})
		else if (name === 'file' && notificationForm.file.length !== 0)
			setNotificationForm({
				...notificationForm,
				file: [...notificationForm.file, ...target.files],
			})
		else setNotificationForm({ ...notificationForm, [name]: value })
	}

	const handleSendMessage = async event => {
		event.preventDefault()
		setLoading(true)
		ref.current.continuousStart()
		// setNotificationForm({ ...notificationForm, id: selectedUsers })

		try {
			const notificationFormData = new FormData()

			for (var i = 0; i < notificationForm.id.length; i++) {
				notificationFormData.append('id[]', notificationForm.id[i])
			}
			// notificationFormData.append('id', notificationForm.id)
			for (var i = 0; i < notificationForm.file.length; i++) {
				notificationFormData.append('file[]', notificationForm.file[i])
			}
			// notificationFormData.append('file', ...notificationForm.file)
			notificationFormData.append('subject', notificationForm.subject)
			notificationFormData.append('message', notificationForm.message)
			notificationFormData.append('is_mail', notificationForm.is_mail)
			notificationFormData.append(
				'is_notification',
				notificationForm.is_notification
			)

			const response = await NotificationService.sendMessage(
				notificationFormData
			)

			if (response.success) {
				toast.success('Message sent successfully.')
				setNotificationForm({
					...notificationForm,
					file: [],
					subject: '',
					message: '',
					is_mail: false,
					is_notification: true,
				})
			} else {
				toast.error(response.message)
			}

			closeModal(sendMessageModal)
		} catch (e) {
			console.log(e)

			if (e.response.status === 422) {
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	useEffect(() => {
		if (selectedUsers.length !== 0)
			setNotificationForm({ ...notificationForm, id: selectedUsers })
	}, [selectedUsers])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<div
				tabIndex='-1'
				role='dialog'
				aria-hidden='true'
				className='modal fade'
				id='notificationModal'
				aria-labelledby='exampleModalCenterTitle'
			>
				<div
					className='modal-dialog modal-dialog-centered'
					role='document'
					style={{ maxWidth: '818px' }}
				>
					<div className='modal-content from-email-modal'>
						<div className='modal-header'>
							<h5 className='modal-title' id='exampleModalLongTitle'>
								<i className='far fa-bell mr-2'></i>Send Notification
							</h5>
							<button
								type='button'
								aria-label='Close'
								style={{ outline: 'none' }}
								className='modal-close-attachment'
								onClick={() => closeModal(sendMessageModal)}
							>
								<img src='/img/cross.png' alt='' />
							</button>
						</div>
						<div className='modal-body'>
							<div className='row p-3'>
								<div className='col'>
									<label htmlFor='email'>
										Type <span style={{ color: 'red' }}>*</span>
									</label>
									<div className='row p-3 d-flex justify-content-between align-items-center'>
										<div className='col text-center'>
											<input
												type='checkbox'
												id='is_notification'
												checked={notificationForm.is_notification}
												onChange={() => {
													setNotificationForm({
														...notificationForm,
														is_notification: !notificationForm.is_notification,
													})
												}}
											/>
											<label htmlFor='is_notification' className='ml-2'>
												Notification
											</label>
										</div>
										<div className='col text-center'>
											<input
												id='is_mail'
												type='checkbox'
												checked={notificationForm.is_mail}
												onChange={() => {
													setNotificationForm({
														...notificationForm,
														is_mail: !notificationForm.is_mail,
													})
												}}
											/>
											<label htmlFor='is_mail' className='ml-2'>
												Email
											</label>
										</div>
									</div>
									<div className='form-row'>
										<div className='form-group col-md-12'>
											<label htmlFor='name'>
												Subject <span style={{ color: 'red' }}>*</span>
											</label>
											<input
												required
												type='text'
												name='subject'
												className='form-control'
												value={notificationForm.subject}
												onChange={handleNotificationFormChange}
											/>
										</div>
									</div>

									<div className='form-row'>
										<div className='form-group col-md-12'>
											<label htmlFor='email'>Message</label>
											<textarea
												required
												type='text'
												name='message'
												className='form-control'
												value={notificationForm.message}
												onChange={handleNotificationFormChange}
											/>
										</div>
									</div>
									{notificationForm.is_notification ||
									(!notificationForm.is_notification &&
										!notificationForm.is_mail) ? (
										<></>
									) : (
										<>
											<div className='form-row'>
												<div className='form-group col-md-12 mb-0'>
													<label htmlFor='file-upload' className='col-12 px-0'>
														Attachments
													</label>
													<label
														htmlFor='file'
														id='file-upload'
														className='btn btn-primary btn-sm'
													>
														<i className='fas fa-link mr-2'></i>
														Select Files
														<input
															required
															multiple
															id='file'
															type='file'
															name='file'
															className='form-control'
															style={{ display: 'none' }}
															onChange={handleNotificationFormChange}
														/>
													</label>
												</div>
											</div>
											<div className='form-row'>
												<div
													className='d-flex flex-row flex-wrap align-items-center p-1'
													style={{ width: '100%' }}
													// style={{ minHeight: '137px', maxHeight: 'min-content' }}
												>
													{notificationForm.file.length === 0 ? (
														<p
															className='text-center flex-fill'
															style={{ fontWeight: 'bold' }}
														>
															Select Attachments to preview...
														</p>
													) : (
														notificationForm.file.map((singleFile, i) => (
															<div
																style={{ maxHeight: '340px', width: '140px' }}
																className='align-self-start'
															>
																<div
																	key={i}
																	style={{
																		maxHeight: '100%',
																		margin: 'auto',
																		maxWidth: '100%',
																		display: 'block',
																		position: 'relative',
																	}}
																	className='mb-2'
																>
																	<i
																		style={{
																			top: '6px',
																			right: '10px',
																			cursor: 'pointer',
																			position: 'absolute',
																		}}
																		className='fas fa-times text-danger opacity-25'
																		onClick={() =>
																			setNotificationForm({
																				...notificationForm,
																				file: notificationForm.file.filter(
																					stateFile => stateFile !== singleFile
																				),
																			})
																		}
																	></i>
																	<img
																		alt='...'
																		src={URL.createObjectURL(singleFile)}
																		className='img-thumbnail mr-1 rounded'
																	/>
																</div>
															</div>
														))
													)}
												</div>
											</div>
										</>
									)}
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								onClick={() => closeModal(sendMessageModal)}
								className='btn btn-secondary bg-danger border mr-2'
							>
								Close
							</button>
							<button
								type='button'
								onClick={handleSendMessage}
								disabled={
									loading ||
									(!notificationForm.is_mail &&
										!notificationForm.is_notification) ||
									notificationForm.subject === ''
								}
								className='btn btn-primary from-email-add'
							>
								Send Message
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default MessageModal

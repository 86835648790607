import './Users.scss'
import { Modal } from 'bootstrap'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import AddUserModal from './AddUserModal'
import Pagination from 'react-js-pagination'
import FadeLoader from 'react-spinners/FadeLoader'
import UserService from '../../api-services/user.service'
import PlanService from '../../api-services/plan.service'
import React, { useEffect, useState, useRef } from 'react'
import MessageModal from '../../components/messages/MessageModal'
import SystemFeaturesService from '../../api-services/systemFeatures.service'

const filterInitialState = {
	username: '',
	activePage: 1,
	packageId: '',
	totalUsers: 1,
	activeUsers: '',
	inActiveUsers: '',
	totalAffiliateUsers: '',
	pendingAffiliateUsers: '',
	approvedAffiliateUsers: '',
}

const Users = () => {
	const timerRef = useRef(null)

	const [stats, setStats] = useState({
		TotalUsers: 0,
		ActiveUsers: 0,
		InactiveUsers: 0,
		TotalAffiliateUsers: 0,
		PendingAffiliateUsers: 0,
		ApprovedAffiliateUsers: 0,
	})
	const [users, setUsers] = useState([])
	const [plans, setPlans] = useState([])
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [metaData, setMetaData] = useState([])
	const [activePage, setActivePage] = useState(1)
	const [updatedRate, setUpdatedRate] = useState()
	const [selectAll, setSelectAll] = useState(false)
	const [searchFlag, setSearchFlag] = useState(false)
	const [btnLoading, setBtnLoading] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [packageFilter, setPackageFilter] = useState('')
	const [selectedUsers, setSelectedUsers] = useState([])
	const [systemFeatures, setSystemFeatures] = useState([])
	const [filter, setFilter] = useState(filterInitialState)
	const [payoutBtnLoading, setPayoutBtnLoading] = useState(true)

	const addUserModal = document.getElementById('addUserModal')
	const sendMessageModal = document.getElementById('notificationModal')

	// timerRef.current = setTimeout(() => {
	// 	setSearchFlag(false)
	// }, 2000)

	const fetchSystemFeatures = async () => {
		try {
			const response = await SystemFeaturesService.list()

			setSystemFeatures(response.systemFeature)
		} catch (error) {
			console.log(error)
		}
	}

	// useEffect(() => {
	// 	if (searchFlag) clearTimeout(timerRef.current)
	// 	else {
	// 		setSearchFlag(false)
	// 		fetchFilteredUsers()
	// 	}

	// 	return () => {
	// 		clearTimeout(timerRef.current)
	// 	}
	// }, [searchFlag])

	const fetchPlans = async () => {
		setLoading(true)
		try {
			const response = await PlanService.list()

			setPlans(response.data)
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
		}
	}

	const fetchUsers = async () => {
		setLoading(true)
		try {
			const response = await UserService.getAllUsers()

			setUsers(response.data)
			setMetaData(response.meta)
			setSelectedUsers([])
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
		}
	}
	const fetchUserStats = async () => {
		setLoading(true)
		try {
			const statsResponse = await UserService.getUserStats()

			setStats(statsResponse)
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
		}
	}

	const handlePageChange = async pageNumber => {
		setActivePage(pageNumber)
		setFilter({ ...filter, activePage: pageNumber })
	}

	const handleUserStatus = async (id, status) => {
		if (status) {
			try {
				setBtnLoading(true)
				const response = await UserService.activateUser(id)
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setBtnLoading(false)
				fetchUsers()
			} catch (e) {
				setError(e)
			}
		} else {
			try {
				setBtnLoading(true)
				const response = await UserService.suspendUser(id)
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setBtnLoading(false)
				fetchUsers()
			} catch (e) {
				setError(e)
			}
		}
	}

	const handleAffiliateStatus = async (id, status) => {
		if (status == 0) {
			try {
				setBtnLoading(true)
				const response = await UserService.approveAffiliate(id)
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setBtnLoading(false)
				fetchUsers()
			} catch (e) {
				setError(e)
			}
		} else {
			try {
				setBtnLoading(true)
				const response = await UserService.disApproveAffiliate(id)
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setBtnLoading(false)
				fetchUsers()
			} catch (e) {
				setError(e)
			}
		}
	}

	const inputRate = e => {
		setPayoutBtnLoading(false)
		setUpdatedRate(e.target.value)
	}

	const handlePayoutRate = async id => {
		try {
			const response = await UserService.updatePayoutRate({
				payout_rates: updatedRate,
				id,
			})
			toast.success(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			fetchUsers()
			setPayoutBtnLoading(true)
		} catch (e) {
			toast.error(e.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			setPayoutBtnLoading(true)
		}
	}

	const cancelInput = id => {
		const btns = document.getElementById(id)
		if (btns.style.visibility === 'initial') btns.style.visibility = 'hidden'
	}

	const showBtn = id => {
		const btns = document.getElementById(id)
		if (btns.style.visibility === 'hidden') btns.style.visibility = 'initial'
	}

	const fetchFilteredUsers = async () => {
		setLoading(true)

		try {
			let perPage = 0
			let totalPages = 0

			const response = await UserService.getFilteredUsers(filter)

			setSelectAll(false)
			setSelectedUsers([])
			setUsers(response.users.data)

			perPage = response.users.per_page
			totalPages = response.users.total

			setMetaData({ per_page: perPage, total: totalPages })
		} catch (error) {
			toast.error(error.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		} finally {
			setLoading(false)
		}
	}

	const handleBulkAction = async usageFlag => {
		if (usageFlag === 'approve') {
			try {
				const response = await UserService.bulkApprove({ users: selectedUsers })
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				fetchFilteredUsers()
			} catch (e) {
				toast.error(e.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
			}
		} else if (usageFlag === 'suspend') {
			try {
				const response = await UserService.bulkSuspend({ users: selectedUsers })
				toast.success(response.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				fetchFilteredUsers()
			} catch (e) {
				toast.error(e.message, {
					autoClose: 2500,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
			}
		} else if (usageFlag === 'sendMessage') {
			// try {
			// 	const response = await NotificationService.bulkSuspend({ users: selectedUsers })
			// 	toast.success(response.message, {
			// 		autoClose: 2500,
			// 		closeOnClick: true,
			// 		position: 'top-right',
			// 		hideProgressBar: false,
			// 	})
			// 	fetchUsers()
			// } catch (e) {
			// 	toast.error(e.message, {
			// 		autoClose: 2500,
			// 		closeOnClick: true,
			// 		position: 'top-right',
			// 		hideProgressBar: false,
			// 	})
			// }
		}
	}

	const openModal = modal => {
		var modalInstance = Modal.getOrCreateInstance(modal)
		modalInstance.show()
	}

	const closeModal = modal => {
		var modalInstance = Modal.getInstance(modal)
		modalInstance.hide()
	}

	useEffect(() => {
		if (filter) {
			fetchFilteredUsers()
		}
	}, [filter, filter.username])

	useEffect(() => {
		if (selectAll) {
			let tempArr = []

			users.forEach(user => {
				tempArr.push(user.id)
			})
			setSelectedUsers(tempArr)
		} else {
			setSelectedUsers([])
		}
	}, [selectAll])

	useEffect(() => {
		fetchUserStats()
		fetchFilteredUsers()
		fetchSystemFeatures()
		fetchPlans()
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Users</li>
						</ol>
					</nav>
				</div>
			</div>

			<div className='pl-3'>
				<div className='d-flex flex-column'>
					<div className='d-flex flex-column justify-content-center'>
						<div className='d-flex flex-row my-2 justify-content-center'>
							<div
								className='mr-2'
								onClick={() => {
									setFilter({
										...filter,
										activePage: 1,
										totalUsers: 1,
										activeUsers: '',
										inActiveUsers: '',
										totalAffiliateUsers: '',
										pendingAffiliateUsers: '',
										approvedAffiliateUsers: '',
									})
								}}
								style={{
									width: '300px',
									pointerEvents: filter.totalUsers === 1 ? 'none' : 'initial',
								}}
							>
								<div
									onClick={() => {
										setFilter({
											...filter,
											activePage: 1,
											totalUsers: 1,
											activeUsers: '',
											inActiveUsers: '',
											totalAffiliateUsers: '',
											pendingAffiliateUsers: '',
											approvedAffiliateUsers: '',
										})
									}}
									className={`box ${
										filter.totalUsers === 1 ? 'selected-filter' : ''
									}`}
								>
									<span className='digit'>{stats.TotalUsers}</span>{' '}
									<span className='text-clr'>Total Users</span>
								</div>
							</div>
							<div
								className='mr-2'
								onClick={() => {
									setFilter({
										...filter,
										activePage: 1,
										totalUsers: '',
										activeUsers: 1,
										inActiveUsers: '',
										totalAffiliateUsers: '',
										pendingAffiliateUsers: '',
										approvedAffiliateUsers: '',
									})
								}}
								style={{
									pointerEvents: filter.activeUsers === 1 ? 'none' : 'initial',
									width: '300px',
								}}
							>
								<div
									onClick={() => {
										setFilter({
											...filter,
											activePage: 1,
											totalUsers: '',
											activeUsers: 1,
											inActiveUsers: '',
											totalAffiliateUsers: '',
											pendingAffiliateUsers: '',
											approvedAffiliateUsers: '',
										})
									}}
									className={`box ${
										filter.activeUsers === 1 ? 'selected-filter' : ''
									}`}
								>
									<span className='digit'>{stats.ActiveUsers}</span>{' '}
									<span className='text-clr-multiple'>Active Users</span>
								</div>
							</div>
							<div
								className=''
								onClick={() => {
									setFilter({
										...filter,
										activePage: 1,
										totalUsers: '',
										activeUsers: '',
										inActiveUsers: 1,
										totalAffiliateUsers: '',
										pendingAffiliateUsers: '',
										approvedAffiliateUsers: '',
									})
								}}
								style={{
									pointerEvents:
										filter.inActiveUsers === 1 ? 'none' : 'initial',
									width: '300px',
								}}
							>
								<div
									onClick={() => {
										setFilter({
											...filter,
											activePage: 1,
											totalUsers: '',
											activeUsers: '',
											inActiveUsers: 1,
											totalAffiliateUsers: '',
											pendingAffiliateUsers: '',
											approvedAffiliateUsers: '',
										})
									}}
									className={`box ${
										filter.inActiveUsers === 1 ? 'selected-filter' : ''
									}`}
								>
									<span className='digit'>{stats.InactiveUsers}</span>{' '}
									<span className='text-clr-multiple'>Suspended Users</span>
								</div>
							</div>
						</div>

						<div className='d-flex flex-row mb-5 justify-content-center'>
							<div
								className='mr-2'
								onClick={() => {
									setFilter({
										...filter,
										activePage: 1,
										totalUsers: '',
										activeUsers: '',
										inActiveUsers: '',
										totalAffiliateUsers: 1,
										pendingAffiliateUsers: '',
										approvedAffiliateUsers: '',
									})
								}}
								style={{
									pointerEvents:
										filter.totalAffiliateUsers === 1 ? 'none' : 'initial',
									width: '300px',
								}}
							>
								<div
									onClick={() => {
										setFilter({
											...filter,
											activePage: 1,
											totalUsers: '',
											activeUsers: '',
											inActiveUsers: '',
											totalAffiliateUsers: 1,
											pendingAffiliateUsers: '',
											approvedAffiliateUsers: '',
										})
									}}
									className={`box ${
										filter.totalAffiliateUsers === 1 ? 'selected-filter' : ''
									}`}
								>
									<span className='digit'>{stats.TotalAffiliateUsers}</span>{' '}
									<span className='text-clr'>Total Affiliate Users</span>
								</div>
							</div>
							<div
								className='mr-2'
								onClick={() => {
									setFilter({
										...filter,
										activePage: 1,
										totalUsers: '',
										activeUsers: '',
										inActiveUsers: '',
										totalAffiliateUsers: '',
										pendingAffiliateUsers: '',
										approvedAffiliateUsers: 1,
									})
								}}
								style={{
									pointerEvents:
										filter.approvedAffiliateUsers === 1 ? 'none' : 'initial',
									width: '300px',
								}}
							>
								<div
									onClick={() => {
										setFilter({
											...filter,
											activePage: 1,
											totalUsers: '',
											activeUsers: '',
											inActiveUsers: '',
											totalAffiliateUsers: '',
											pendingAffiliateUsers: '',
											approvedAffiliateUsers: 1,
										})
									}}
									className={`box ${
										filter.approvedAffiliateUsers === 1 ? 'selected-filter' : ''
									}`}
								>
									<span className='digit'>{stats.ApprovedAffiliateUsers}</span>{' '}
									<span className='text-clr-multiple'>
										Approved Affiliate Users
									</span>
								</div>
							</div>
							<div
								className=''
								onClick={() => {
									setFilter({
										...filter,
										activePage: 1,
										totalUsers: '',
										activeUsers: '',
										inActiveUsers: '',
										totalAffiliateUsers: '',
										pendingAffiliateUsers: 1,
										approvedAffiliateUsers: '',
									})
								}}
								style={{
									pointerEvents:
										filter.pendingAffiliateUsers === 1 ? 'none' : 'initial',
									width: '300px',
								}}
							>
								<div
									onClick={() => {
										setFilter({
											...filter,
											activePage: 1,
											totalUsers: '',
											activeUsers: '',
											inActiveUsers: '',
											totalAffiliateUsers: '',
											pendingAffiliateUsers: 1,
											approvedAffiliateUsers: '',
										})
									}}
									className={`box ${
										filter.pendingAffiliateUsers === 1 ? 'selected-filter' : ''
									}`}
								>
									<span className='digit'>{stats.PendingAffiliateUsers}</span>{' '}
									<span className='text-clr-multiple'>
										Pending Affiliate Users
									</span>
								</div>
							</div>
						</div>
					</div>
					<div className='d-flex flex-row justify-content-center mb-2'>
						<div className='d-flex flex-column mr-2'>
							<label
								className='text-center'
								htmlFor='filter-select'
								style={{ fontWeight: 'bold' }}
							>
								Filter by Package
							</label>
							<select
								id='filter-select'
								className='form-control'
								value={filter.packageId}
								style={{ width: '300px' }}
								onChange={e => {
									setFilter({
										...filter,
										activePage: 1,
										packageId: e.target.value,
									})
								}}
							>
								<option value=''>Select a Package</option>
								{plans.length === 0 ? (
									<p>Loading</p>
								) : (
									plans.map((plan, i) => (
										<option key={i} value={plan.id}>
											{plan.name}
										</option>
									))
								)}
							</select>
						</div>

						<div className='d-flex justify-content-center mb-2'>
							<div className='d-flex flex-column'>
								<label className='text-center' style={{ fontWeight: 'bold' }}>
									Search By Email
								</label>
								<div
									className='input-group mb-3 form-control p-0 bg-white'
									style={{ width: '300px' }}
								>
									<span
										id='basic-addon1'
										style={{ border: 'none' }}
										className='input-group-text bg-white py-0'
									>
										<i className='fas fa-search'></i>
									</span>
									<input
										type='text'
										className='py-0'
										placeholder='Search'
										aria-label='Username'
										value={filter.username}
										aria-describedby='basic-addon1'
										onChange={e => {
											setTimeout(
												param => {
													setFilter({
														...filter,
														username: param,
													})
												},
												25,
												e.target.value
											)
										}}
										style={{ border: 'none', outline: 'none', width: '85%' }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row d-flex align-items-center justify-content-between'>
						<div className='d-flex align-items-center justify-content-between'>
							<div>
								<h4 className='m-0 font-weight-bold text-primary mr-4'>
									Users
								</h4>
							</div>
							<div>
								<button
									disabled={selectedUsers.length === 0}
									className='btn btn-success btn-sm mr-2'
									onClick={() => handleBulkAction('approve')}
								>
									<i className='fas fa-check mr-2'></i>
									Approve
								</button>
								<button
									disabled={selectedUsers.length === 0}
									className='btn btn-danger btn-sm mr-2'
									onClick={() => handleBulkAction('suspend')}
								>
									<i className='fas fa-times mr-2'></i>
									Suspend
								</button>
								<button
									onClick={() => openModal(sendMessageModal)}
									disabled={selectedUsers.length === 0}
									className='btn btn-primary btn-sm mr-2'
								>
									<i className='fas fa-paper-plane mr-2'></i>
									Send Message
								</button>
								<button
									onClick={() => {
										setFilter(filterInitialState)
									}}
									className='btn btn-secondary btn-sm'
									disabled={
										filter.username === '' &&
										filter.packageId === '' &&
										filter.totalUsers === '' &&
										filter.activeUsers === '' &&
										filter.inActiveUsers === '' &&
										filter.totalAffiliateUsers === '' &&
										filter.pendingAffiliateUsers === '' &&
										filter.approvedAffiliateUsers === ''
									}
								>
									<i class='fas fa-filter mr-2'></i>
									Clear Filters
								</button>
							</div>
						</div>
						<div className='text-right'>
							<button
								type='button'
								onClick={() => openModal(addUserModal)}
								className='btn btn-sm c-btn'
								style={{
									outline: 'none',
									backgroundColor: '#0994F8',
								}}
							>
								<i class='far fa-plus mr-2'></i>
								Add New user
							</button>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									height: '30px',
									marginLeft: '50%',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									width={5}
									height={20}
									loading={true}
									color={'#0092FB'}
								/>
							</div>
						) : users.length == 0 ? (
							<div className='text-center starter-inboxes'>
								<p
									className='sp mt-1'
									style={{ fontSize: '20px', lineHeight: '30px' }}
								>
									<b>No Users Found</b>
								</p>
							</div>
						) : (
							<>
								<div className='table-responsive'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th>
													<div className='ml-2'>
														<input
															type='checkbox'
															className='ml-1'
															id='selectAllCheckBox'
															onChange={() => {
																setSelectAll(!selectAll)
															}}
														/>
													</div>
												</th>
												<th>Actions</th>
												<th>
													Name
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th>
													Email
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th className='text-center'>
													Suspended
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th className='text-center'>
													Affiliate Action
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th className='text-center'>
													Payout rate
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
											</tr>
										</thead>
										{users.map((item, i) => (
											<tbody key={i}>
												<tr>
													<td>
														<div className='mt-2 ml-2'>
															<input
																type='checkbox'
																checked={selectedUsers.includes(item.id)}
																onChange={() => {
																	if (!selectedUsers.includes(item.id)) {
																		setSelectedUsers([
																			...selectedUsers,
																			item.id,
																		])
																	} else {
																		setSelectedUsers(
																			selectedUsers.filter(
																				singleUser => singleUser !== item.id
																			)
																		)
																	}
																}}
															/>
														</div>
													</td>
													<td className='dns_email_item d-flex flex-lg-row flex-md-column flex-sm-column flex-column align-items-center'>
														{/* <button className='btn btn-danger btn-circle btn-sm'>
															<i className='fas fa-ban'></i>
														</button> */}
														{/* <button
															data-toggle='modal'
															data-target='#planModal'
															onClick={() => {
																setUserIdToUpdate(item.id)
																fetchPlanDetails(item.id)
															}}
															className='btn btn-primary ml-2 btn-circle btn-sm'
														>
															<i className='fas fa-pencil-alt'></i>
														</button> */}
														<div classame='d-flex flex-row'>
															<Link
																className='user-link'
																to={{
																	pathname: `/users/userStats/${item.id}`,
																}}
															>
																<button className='btn btn-info ml-2 btn-circle btn-sm'>
																	<i className='fas fa-eye'></i>
																</button>
															</Link>
															<Link
																className='user-link'
																to={{
																	pathname: `/tickets/${item.id}`,
																}}
															>
																<button className='btn btn-primary ml-2 btn-circle btn-sm'>
																	<i className='fas fa-ticket-alt'></i>
																</button>
															</Link>
														</div>
														&nbsp;&nbsp;
														{item.is_admin ? (
															''
														) : (
															<button
																className={`btn btn-sm ${
																	item.is_suspended
																		? 'btn-success'
																		: 'btn-danger'
																}`}
																onClick={() =>
																	handleUserStatus(item.id, item.is_suspended)
																}
																disabled={btnLoading}
															>
																{item.is_suspended ? 'Approve' : 'Suspend'}
															</button>
														)}
													</td>
													<td className='dns_email_item'>{item.name}</td>
													<td className='dns_email_item'>{item.email}</td>
													<td className='dns_email_item text-center'>
														{item.is_suspended ? 'Yes' : 'No'}
													</td>
													<td className='dns_email_item text-center'>
														{/* <Link
															className='user-link'
															to={{
																pathname: `/users/userStats/${item.id}/referrals`,
															}}
														>
															<button className='btn btn-info ml-2 btn-circle btn-sm'>
																<i className='fas fa-eye'></i>
															</button>
														</Link>
														&nbsp;&nbsp; */}
														{item.affiliate_status == null ? (
															''
														) : (
															<button
																className={`btn btn-sm ${
																	item.affiliate_status === 0
																		? 'btn-success'
																		: 'btn-danger'
																}`}
																onClick={() =>
																	handleAffiliateStatus(
																		item.id,
																		item.affiliate_status
																	)
																}
																disabled={btnLoading}
															>
																{item.affiliate_status === 0
																	? 'Approve'
																	: 'Disapprove'}
															</button>
														)}
													</td>
													<td className='dns_email_item'>
														<div className='flex-row d-flex justify-content-end'>
															<div>
																<input
																	type='text'
																	onChange={inputRate}
																	className='editable-input'
																	onClick={() => showBtn(i)}
																	defaultValue={`${item.payout_rates}`}
																/>
																%
															</div>
															<div
																className='ml-2'
																id={i}
																style={{
																	// display: 'none',
																	visibility: 'hidden',
																}}
															>
																<button
																	disabled={payoutBtnLoading}
																	className='btn btn-sm btn-primary input-btn'
																	onClick={() => handlePayoutRate(item.id)}
																>
																	<i className='fa fa-check'></i>
																</button>
																&nbsp;
																<button
																	onClick={() => cancelInput(i)}
																	className='btn btn-sm btn-danger input-btn'
																>
																	<i className='fas fa-times'></i>
																</button>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										))}
									</table>
									<div className='float-right mr-4'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											pageRangeDisplayed={4}
											activePage={activePage}
											totalItemsCount={metaData.total}
											itemsCountPerPage={metaData.per_page}
											onChange={pageNumber => handlePageChange(pageNumber)}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			<AddUserModal
				plans={plans}
				closeModal={closeModal}
				fetchUsers={fetchUsers}
				addUserModal={addUserModal}
				systemFeatures={systemFeatures}
			/>
			<MessageModal
				closeModal={closeModal}
				selectedUsers={selectedUsers}
				sendMessageModal={sendMessageModal}
			/>
		</>
	)
}

export default Users

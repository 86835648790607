import API from './api'
import axios from 'axios'

const FaqService = {
  list: (pageNumber = 1) => {
    return axios.get(process.env.REACT_APP_INBOX_BETTER_API_URL + `/faqs/all?page=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  add: data => {
    return API.post('/admin/addfaqs', data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  get: id => {
    return API.get(`/admin/packages/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  delete: id => {
    return API.get(`admin/users/suspend/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
  update: (data) => {
    return API.post(`/admin/editfaqs/${data.id}`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  },
}

export default FaqService

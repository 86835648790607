import axios from 'axios'
import store from '../store'
import { toast } from 'react-toastify'
import { logout } from '../store/actions/auth.action'

const API = axios.create({
	baseURL: process.env.REACT_APP_INBOX_BETTER_API_URL,
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
	},
})

API.interceptors.response.use(
	res => {
		return res
	},
	err => {
		if (err.response.status === 401) {
			toast.error(err.response.data.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			// store.dispatch(logout())

			// localStorage.removeItem('user')
			// localStorage.removeItem('token')
			// localStorage.removeItem('expiresIn')
		}

		if (err.response.status !== 401) {
			// toast.error(err.response.data.message, {
			// 	autoClose: 2500,
			// 	closeOnClick: true,
			// 	position: 'top-right',
			// 	hideProgressBar: false,
			// })
			throw err
		}
	}
)

export default API

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const Layout = ({ children, location, ...rest }) => {
	return (
		<>
			<div className='container vh-100 d-flex align-items-center'>
				<div className='flex-grow-1'>{children}</div>
			</div>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
}

export default connect(null, null)(withRouter(Layout))

import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import UserService from '../../api-services/user.service'
import FadeLoader from 'react-spinners/FadeLoader'
import moment from 'moment'

const WarmupStatsTable = ({
	id,
	metaData,
	setMetaData,
	warmupAccounts,
	setWarmupAccounts,
}) => {
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)

	const handlePageChange = async pageNumber => {
		setLoading(true)
		const response = await UserService.getWarmupAccounts(id, pageNumber)
		setWarmupAccounts(response.warmupCampains.data)
		setMetaData(response.warmupCampains)
		setActivePage(pageNumber)
		if (response.length !== 0) {
			setLoading(false)
		}
	}
	return (
		<>
			{loading ? (
				<div
					style={{
						marginLeft: '50%',
						height: '30px',
						marginBottom: '20px',
						marginTop: '17%',
					}}
				>
					<FadeLoader loading={true} color={'#0092FB'} height={20} width={5} />
				</div>
			) : (
				<>
					<table className='table table-sm flex-grow-1'>
						<thead>
							<tr style={{ fontSize: '18px', lineHeight: '23px' }}>
								<th>
									<b>Inbox</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
								<th>
									<b>Total Sent</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
								<th>
									<b>Total Inboxed</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
								<th>
									<b>Interactions</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
								<th>
									<b>Spam Rate</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
								<th>
									<b>Created</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
								<th>
									<b>Status</b>
									<span className='sort-controls'>
										<img className='up' src='/img/up-sort.png' />
										<img className='down' src='/img/down-sort.png' />
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{warmupAccounts.map((warmupAccount, i) => (
								<tr key={i}>
									<td className='inbox-item-name'>
										{warmupAccount.campaign_name}
									</td>
									<td className='inbox-item'>
										{warmupAccount.stats !== null
											? warmupAccount.stats.emails_sent != null
												? warmupAccount.stats.emails_sent
												: 0
											: 'Loading...'}
									</td>
									<td className='inbox-item'>
										{warmupAccount.stats !== null
											? warmupAccount.stats.total_inboxed != null
												? warmupAccount.stats.total_inboxed
												: 0
											: 'Loading...'}
									</td>
									<td className='inbox-item'>
										{warmupAccount.stats !== null
											? warmupAccount.stats.interactions != null
												? warmupAccount.stats.interactions
												: 0
											: 'Loading...'}
									</td>
									<td className='inbox-item'>
										{warmupAccount.stats !== null
											? warmupAccount.stats.spam_rate != null
												? warmupAccount.stats.spam_rate + '%'
												: 0
											: 'Loading...'}{' '}
									</td>
									<td className='inbox-item'>
										{moment(warmupAccount.created_at).format('MMM D, YYYY')}
									</td>
									<td
										className='inbox-item'
										style={{
											display: 'flex',
											position: 'relative',
										}}
									>
										<div
											className={
												warmupAccount.is_active === 1
													? 'circle-green'
													: 'circle-yellow'
											}
										></div>
										<span
											style={{
												position: 'absolute',
												left: '25px',
												bottom: '19px',
											}}
										>
											&nbsp;&nbsp;
											{warmupAccount.is_active === 1 ? 'Active' : 'Paused'}
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className='col-2 offset-10'>
						<Pagination
							itemClass='page-item'
							linkClass='page-link'
							activePage={activePage}
							itemsCountPerPage={metaData.per_page}
							totalItemsCount={metaData.total}
							pageRangeDisplayed={4}
							onChange={pageNumber => handlePageChange(pageNumber)}
						/>
					</div>
				</>
			)}
		</>
	)
}

export default WarmupStatsTable

import { Modal } from 'bootstrap'
import PlanModal from './PlanModal'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import UserService from '../../api-services/user.service'
import PlanService from '../../api-services/plan.service'
import SystemFeaturesService from '../../api-services/systemFeatures.service'

const Plan = () => {
	const [plans, setPlans] = useState([])
	const [error, setError] = useState('')
	const [errors, setErrors] = useState({})
	const [planForm, setPlanForm] = useState({
		name: '',
		price: '',
		group_tag: '',
		systemFeature: [],
		duration_in_days: '',
	})
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [btnLoading, setBtnLoading] = useState(false)
	const [systemFeatures, setSystemFeatures] = useState([])
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const modalInstance = document.getElementById('planModal')

	const closeModal = () => {
		const modal = Modal.getInstance(modalInstance)

		modal.hide()
	}

	const openModal = () => {
		const modal = Modal.getOrCreateInstance(modalInstance)

		modal.show()
	}

	const fetchPlans = async () => {
		setLoading(true)
		try {
			let tempMetaDataObj = { per_page: 0, total: 0 }

			const response = await PlanService.list()
			tempMetaDataObj.per_page = response.per_page
			tempMetaDataObj.total = response.total

			setPlans(response.data)

			setMetaData(tempMetaDataObj)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}

	const fetchSystemFeatures = async () => {
		try {
			const response = await SystemFeaturesService.list()

			setSystemFeatures(response.systemFeature)

			let remainingFeatures = response.systemFeature.filter(
				singleFeature => singleFeature.id !== planForm.systemFeature
			)

			let tempArr = []

			remainingFeatures.forEach(singleFeature => {
				tempArr.push({
					id: singleFeature.id,
					max_allowed_count: 0,
				})
			})

			setPlanForm({
				...planForm,
				systemFeature: [...planForm.systemFeature, ...tempArr],
			})
		} catch (error) {
			console.log(error)
		}
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)
		try {
			let tempMetaDataObj = { per_page: 0, total: 0 }

			const response = await PlanService.list(pageNumber)
			tempMetaDataObj.per_page = response.per_page
			tempMetaDataObj.total = response.total

			setPlans(response.data)
			setMetaData(tempMetaDataObj)
			setActivePage(pageNumber)
			if (response.length !== 0) {
				setLoading(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const handleUserStatus = async (id, status) => {
		if (status) {
			try {
				setBtnLoading(true)
				const response = await UserService.activateUser(id)
				toast.success(response.message, {
					autoClose: 5000,
					draggable: true,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setBtnLoading(false)
				fetchPlans()
			} catch (e) {
				setError(e)
			}
		} else {
			try {
				setBtnLoading(true)
				const response = await UserService.suspendUser(id)
				toast.success(response.message, {
					autoClose: 5000,
					draggable: true,
					closeOnClick: true,
					position: 'top-right',
					hideProgressBar: false,
				})
				setBtnLoading(false)
				fetchPlans()
			} catch (e) {
				setError(e)
			}
		}
	}

	const handlePlanFormChange = event => {
		// event.preventDefault()

		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		if (name === 'undefined' || name === undefined) {
			let found = false

			planForm.systemFeature.forEach(systemFeature => {
				if (
					systemFeature.max_allowed_count !== 0 &&
					systemFeature.id === parseInt(target.dataset.id) &&
					!found
				) {
					found = true
					let tempArr = []

					tempArr = planForm.systemFeature

					// setPlanForm({
					// 	...planForm,
					// 	systemFeature: tempArr.filter(
					// 		feature => feature.id !== parseInt(target.dataset.id)
					// 	),
					// })

					//New Logic

					tempArr.forEach(singleItem => {
						if (singleItem.id === parseInt(target.dataset.id)) {
							singleItem.max_allowed_count = 0
						}
					})

					setPlanForm({ ...planForm, systemFeature: tempArr })
				}
			})

			if (!found) {
				let tempArr = []
				tempArr = planForm.systemFeature

				// tempArr.push({ id: parseInt(target.dataset.id), max_allowed_count: 0 })

				// setPlanForm({ ...planForm, systemFeature: tempArr })

				//New Logic

				tempArr.forEach(singleItem => {
					if (singleItem.id === parseInt(target.dataset.id)) {
						singleItem.max_allowed_count = 1
					}
				})

				setPlanForm({ ...planForm, systemFeature: tempArr })
			}
		} else if (name === 'max_allowed_count') {
			planForm.systemFeature.forEach(systemFeature => {
				if (systemFeature.id === parseInt(target.dataset.id)) {
					let tempArr = []
					let filteredArr = []

					tempArr = planForm.systemFeature

					filteredArr = tempArr.filter(
						feature => feature.id !== parseInt(target.dataset.id)
					)

					filteredArr.push({
						id: parseInt(target.dataset.id),
						max_allowed_count: parseInt(target.value),
					})

					setPlanForm({
						...planForm,
						systemFeature: filteredArr,
					})
				}
			})
		} else if (name === 'group_tag') {
			if (value === 'Life Time') {
				setPlanForm({ ...planForm, duration_in_days: 0, group_tag: value })
				// setPlanForm({ ...planForm, [name]: value })
			} else if (value === 'Trial') {
				setPlanForm({ ...planForm, duration_in_days: 30, group_tag: value })
				// setPlanForm({ ...planForm, [name]: value })
			} else {
				setPlanForm({ ...planForm, [name]: value })
			}
		} else {
			setPlanForm({ ...planForm, [name]: value })
		}
	}

	const handleAddNewPlan = async event => {
		event.preventDefault()
		setLoading(true)

		// let remainingFeatures = systemFeatures.filter(
		// 	singleFeature => singleFeature.id !== planForm.systemFeature
		// )

		// let tempArr = []

		// remainingFeatures.forEach(singleFeature => {
		// 	tempArr.push({
		// 		id: singleFeature.id,
		// 		max_allowed_count: 0,
		// 	})
		// })

		// setPlanForm({
		// 	...planForm,
		// 	systemFeature: [...planForm.systemFeature, ...tempArr],
		// })

		try {
			const response = await PlanService.add(planForm)

			if (response.success) {
				fetchPlans()
				closeModal()
			}
		} catch (e) {
			if (e.response.status === 422) {
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	const disablePlan = async plan => {
		let tempPlan = {}

		tempPlan = plan

		tempPlan.is_disabled = !tempPlan.is_disabled

		const response = await PlanService.disable(plan.id, {
			is_disabled: tempPlan.is_disabled,
		})

		if (response.success) fetchPlans()
	}

	useEffect(() => {
		fetchPlans()
		fetchSystemFeatures()

		return () => {
			setPlans([])
			setPlanForm({})
			setLoading(false)
		}
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Plans</li>
						</ol>
					</nav>
				</div>
			</div>

			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row'>
						<div className='col-sm-6'>
							<h4 className='m-0 font-weight-bold text-primary'>Plans</h4>
						</div>
						<div className='col text-right d-none'>
							<button
								type='button'
								onClick={openModal}
								className='btn btn-sm c-btn'
								style={{
									outline: 'none',
									backgroundColor: '#0994F8',
								}}
							>
								<i class='far fa-plus mr-2'></i>
								Add New Plan
							</button>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									marginLeft: '50%',
									height: '30px',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									loading={true}
									color={'#0092FB'}
									height={20}
									width={5}
								/>
							</div>
						) : plans.length === 0 ? (
							<div className='text-center starter-inboxes'>
								<b>No Plans Found</b>
							</div>
						) : (
							<>
								<div className='table-responsive'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th>Actions</th>
												<th>
													Name
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th>
													Duration
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th>
													Price
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th>
													Status
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
											</tr>
										</thead>
										{plans.map((plan, i) => (
											<tbody key={i}>
												<tr>
													<td className='dns_email_item'>
														{/* <button className='btn btn-primary ml-2 btn-circle btn-sm'>
															<i className='fas fa-pencil-alt'></i>
														</button> */}
														<Link
															className='user-link'
															to={{
																pathname: `/plans/${plan.id}`,
															}}
														>
															<button className='btn btn-info ml-2 btn-circle btn-sm'>
																<i className='fas fa-eye'></i>
															</button>
														</Link>
														&nbsp;&nbsp;
														<button
															className={`btn btn-sm ${
																plan.is_disabled === 1
																	? 'btn-success'
																	: 'btn-danger'
															}`}
															onClick={() => disablePlan(plan)}
															disabled={btnLoading}
														>
															{plan.is_disabled === 1 ? 'Activate' : 'Disable'}
														</button>
													</td>
													<td className='dns_email_item'>{plan.name}</td>
													<td className='dns_email_item'>
														{plan.duration_in_days + ' days'}
													</td>
													<td className='dns_email_item'>$ {plan.price}</td>
													<td className='dns_email_item'>
														{plan.is_disabled ? 'Disabled' : 'Active'}
													</td>
												</tr>
											</tbody>
										))}
									</table>
									<div className='float-right mr-4'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											pageRangeDisplayed={4}
											activePage={activePage}
											totalItemsCount={metaData.total}
											itemsCountPerPage={metaData.per_page}
											onChange={pageNumber => handlePageChange(pageNumber)}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
			{/* <PlanModal
				usageFlag='add'
				loading={loading}
				planForm={planForm}
				closeModal={closeModal}
				systemFeatures={systemFeatures}
				handleAddNewPlan={handleAddNewPlan}
				handlePlanFormChange={handlePlanFormChange}
			/> */}
		</>
	)
}

export default Plan

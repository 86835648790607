import { toast } from 'react-toastify'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AuthService from '../../api-services/auth.service'

const ForgotPass = () => {
	const [email, setEmail] = useState('')
	const [error, setError] = useState(null)
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)

	const handleFormChange = event => {
		const { name, value } = event.target
		setEmail(value)
	}

	const handleSubmit = async event => {
		event.preventDefault()

		setLoading(true)
		try {
			const response = await AuthService.sendPasswordResetLink({ email: email })

			toast.success('Password Reset Link sent...')
		} catch (e) {
			if (e.response.status == 422) {
				const errors = e.response.data.errors
				setErrors(errors)
			} else {
				setError(e)
			}
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='d-flex flex-grow-1 align-self-center card o-hidden border-0 shadow-lg my-5'>
			<div className='card-body p-0'>
				<div className='row'>
					<div className='col-lg-6 d-none d-lg-block bg-login-image'></div>
					<div className='col-lg-6'>
						<div className='p-5'>
							<div className='text-center'>
								<h1 className='h2 text-gray-900 mb-4'>
									<strong>Inbox Better</strong>
								</h1>
							</div>
							<div className='text-center'>
								<h1 className='h4 text-gray-900 mb-5'>Reset Password</h1>
							</div>
							<form className='user' onSubmit={handleSubmit}>
								<div className='form-group'>
									<input
										required
										type='email'
										name='email'
										value={email}
										placeholder='Email *'
										onChange={handleFormChange}
										className='form-control form-control-user'
									/>
									{errors.hasOwnProperty('email')
										? errors.email.map(e => (
												<span className='text-danger mt-1 ml-1'> {e} </span>
										  ))
										: ''}
								</div>
								<button
									type='submit'
									disabled={loading}
									className='btn btn-primary btn-user btn-block'
								>
									Submit
								</button>
							</form>
							<hr />
							<div className='text-center'>
								<Link className='small' to='/login'>
									Back to Login
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ForgotPass

import React from "react";
import "./potentialInboxing.scss";
import Progressbar from "react-js-progressbar";
import WordCloud from "./WordCloud";
import getSliceColor from "./getColor";

const SubjectHeadlineInsight = ({ subjectHeadLineInsight }) => {
  return (
    <>
      <div className="d-flex p-2 mb-3">
        <div className="icon-bg d-flex">
          <img src="/assets/img/analyzer-subject-insight.png" alt="" />
        </div>
        <div className="ml-3 mt-3">
          <b>Subject Headline Insight</b>
        </div>
      </div>
      <div className="card flex-row py-3 analyzer-card">
        <div className="row p-4">
          <div className="col-2 text-center">
            <Progressbar
              input={subjectHeadLineInsight.percentage}
              pathWidth={30}
							pathShadow='none'
              pathColor={getSliceColor(subjectHeadLineInsight.percentage)}
              trailWidth={50}
              trailColor="#F2F2F2"
              animation={{ ease: "linear" }}
              ease={"linear"}
              size={100}
              pathLinecap={"round"}
              textStyle={{
                fontWeight: "800",
                fontSize: "44px",
              }}
              clockwise={false}
            ></Progressbar>
          </div>
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 col-10 pt-2">
            <div className="text-style" style={{ fontSize: "20px" }}>
              <b>Overall Results</b>
            </div>
            <div
              className="mt-2 text-style"
              dangerouslySetInnerHTML={{
                __html: subjectHeadLineInsight.msg,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="row mt-4 mx-1">
        <div className="d-flex p-2">
          <div className="ml-3 mt-3">
            <span className="text-style" style={{ fontSize: "14px" }}>
              {subjectHeadLineInsight.suggestion}
            </span>
          </div>
        </div>
      </div>
      <div className="row m-4">
        <WordCloud words={subjectHeadLineInsight.words} />
      </div>
    </>
  );
};

export default SubjectHeadlineInsight;

import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'
import ConfigurationService from '../../../api-services/configuration.service'

const InviteCode = () => {
	const [loading, setLoading] = useState(false)
	const [accessCode, setAccessCode] = useState('')

	const handleSaveCode = async e => {
		e.preventDefault()
		setLoading(true)

		try {
			const formData = new FormData()

			formData.append('access_code', accessCode)

			const response = await ConfigurationService.add(formData)

			if (response.success) {
				toast.success(response.message)
				fetchInviteCode()
			} else {
				toast.error(response.message)
			}

			setLoading(false)
		} catch (e) {
			toast.error(e)
			throw e
		} finally {
			setLoading(false)
		}
	}

	const fetchInviteCode = async () => {
		setLoading(true)

		try {
			const response = await ConfigurationService.get()

			if (response.configuration) {
				setAccessCode(response.configuration.access_code)
			}
			setLoading(false)
		} catch (e) {
			toast.error(e)
			throw e
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchInviteCode()
	}, [])

	return (
		<div className='d-flex flex-grow-0 align-items-center justify-content-center card o-hidden border-0 shadow-lg'>
			<div className='card-body p-0'>
				<div className='py-4 px-2'>
					<div className='text-center'>
						<h1 className='h2 text-gray-900 mb-4'>
							<strong>Invitation Code</strong>
						</h1>
					</div>
					<form className='user' onSubmit={handleSaveCode}>
						<div className='form-group'>
							<input
								required
								type='text'
								maxLength={16}
								name='access_code'
								value={accessCode}
								placeholder='* Access Code'
								onChange={e => setAccessCode(e.target.value)}
								className='form-control form-control-user'
							/>
							{/* {errors.hasOwnProperty('email')
										? errors.email.map(e => (
												<span className='text-danger mt-1 ml-1'> {e} </span>
										  ))
										: ''} */}
						</div>
						<button
							type='submit'
							disabled={loading || accessCode === '' || accessCode.length < 16}
							className='btn btn-primary btn-user btn-block'
						>
							Save Code
						</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default InviteCode

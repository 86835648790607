import React from 'react'
import { Redirect } from 'react-router-dom'

// Public
import Login from '../pages/auth/Login'

// Protected
import Faq from '../pages/faq'
import Tickets from '../pages/ticket'
import Plan from '../pages/plan/index'
import Users from '../pages/user/index'
import Errors from '../pages/error/index'
import ViewPlan from '../pages/plan/ViewPlan'
import UserStats from '../pages/user/UserStats'
import Profile from '../pages/profile/Profile'
import BlockedUsers from '../pages/user/blocked'
import ForgotPass from '../pages/auth/ForgotPass'
import Dashboard from '../pages/dashboard/index.jsx'
import SystemJobs from '../pages/system_jobs/SystemJobs'
import Referrals from '../pages/user/refferral/Referrals'
import InviteCode from '../pages/configuration/invite-code'
import EmailAccount from '../pages/email_account/EmailAccount'
import AffiliateInvoices from '../pages/invoice/AffiliateInvoices'
import EmailContentList from '../pages/email_content/EmailContentList'
import AffiliateInvoiceDetail from '../pages/invoice/AffiliateInvoiceDetail'
import AffiliateSettings from '../pages/user/affiliate_setting/AffiliateSettings'
import ResetPass from '../pages/auth/ResetPass'

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },

  { path: '/profile', component: Profile },

  { path: '/users', component: Users },
  { path: '/users/userStats/:id', component: UserStats },
  { path: '/users/userStats/:id/referrals', component: Referrals },
  { path: '/users/blocked', component: BlockedUsers },

  { path: '/plans', component: Plan },
  { path: '/plans/:id', component: ViewPlan },

  { path: '/tickets/:id', component: Tickets },

  { path: '/system-jobs', component: SystemJobs },

  { path: '/faqs', component: Faq },

  { path: '/errors', component: Errors },

  { path: '/invitation-code', component: InviteCode },

  // { path: '/errors/:id', component: Faq },
  // { path: '/faqs/:id', component: SystemJobs },

  { path: '/affiliate-settings', component: AffiliateSettings },

  { path: '/invoices/affiliate', component: AffiliateInvoices },
  { path: '/invoices/affiliate/:id', component: AffiliateInvoiceDetail },

  { path: '/email-accounts/system-email-accounts', component: EmailAccount },
  { path: '/email-accounts/email-content', component: EmailContentList },

  { path: '/', exact: true, component: () => <Redirect to='/dashboard' /> },
]

const publicRoutes = [{ path: '/login', component: Login }, { path: '/forgot-pass', component: ForgotPass }, { path: '/password/reset/:id', component: ResetPass }]

export { authProtectedRoutes, publicRoutes }

import '../Users.scss'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import UserService from '../../../api-services/user.service'

const BlockedUsers = () => {
	const [users, setUsers] = useState([])
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [activePage, setActivePage] = useState(1)
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const fetchBlockedUsers = async () => {
		setLoading(true)

		let perPage = 0
		let totalPages = 0

		try {
			const response = await UserService.getBlockedUsers()

			setUsers(response.user.data)

			perPage = response.user.per_page
			totalPages = response.user.total

			setMetaData({ per_page: perPage, total: totalPages })
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
		}
	}

	const handlePageChange = async pageNumber => {
		setActivePage(pageNumber)
	}

	const unblockUser = async id => {
		const response = await UserService.unblockUser(id)

		if (response.success) {
			toast.success(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
			fetchBlockedUsers()
		} else {
			toast.error(response.message, {
				autoClose: 2500,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		}
	}

	useEffect(() => {
		fetchBlockedUsers()
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Blocked Users</li>
						</ol>
					</nav>
				</div>
			</div>

			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row d-flex align-items-center justify-content-between'>
						<div className='d-flex align-items-center justify-content-between'>
							<div>
								<h4 className='m-0 font-weight-bold text-primary mr-4'>
									Blocked Users
								</h4>
							</div>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									height: '30px',
									marginLeft: '50%',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									width={5}
									height={20}
									loading={true}
									color={'#0092FB'}
								/>
							</div>
						) : users.length == 0 ? (
							<div className='text-center starter-inboxes'>
								<p
									className='sp mt-1'
									style={{ fontSize: '20px', lineHeight: '30px' }}
								>
									<b>No Users Blocked</b>
								</p>
							</div>
						) : (
							<>
								<div className='table-responsive'>
									<table className='table table-sm'>
										<thead>
											<tr>
												{/* <th>
													<input
														type='checkbox'
														className='ml-1'
														id='selectAllCheckBox'
														onChange={() => {
															setSelectAll(!selectAll)
														}}
													/>
												</th> */}
												<th className='text-center'>Actions</th>
												<th>
													Name
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th>
													E-mail
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th>
													IP Address 1
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
												<th>
													IP Address 2
													<span className='sort-controls'>
														<img className='up' src='/img/up-sort.png' />
														<img className='down' src='/img/down-sort.png' />
													</span>
												</th>
											</tr>
										</thead>
										{users.map((item, i) => (
											<tbody key={i}>
												<tr>
													{/* <td>
														<input
															type='checkbox'
															checked={selectedUsers.includes(item.id)}
															onChange={() => {
																if (!selectedUsers.includes(item.id)) {
																	setSelectedUsers([...selectedUsers, item.id])
																} else {
																	setSelectedUsers(
																		selectedUsers.filter(
																			singleUser => singleUser !== item.id
																		)
																	)
																}
															}}
														/>
													</td> */}
													<td className='dns_email_item d-flex flex-lg-row flex-md-column flex-sm-column flex-column align-items-center justify-content-center'>
														{/* <button className='btn btn-danger btn-circle btn-sm'>
															<i className='fas fa-ban'></i>
														</button> */}
														{/* <button
															data-toggle='modal'
															data-target='#planModal'
															onClick={() => {
																setUserIdToUpdate(item.id)
																fetchPlanDetails(item.id)
															}}
															className='btn btn-primary ml-2 btn-circle btn-sm'
														>
															<i className='fas fa-pencil-alt'></i>
														</button> */}
														<div classame='d-flex flex-row'>
															{item.is_blocked ? (
																<button
																	disabled={loading}
																	className={`btn btn-sm ml-2 btn-primary`}
																	onClick={() => unblockUser(item.id)}
																>
																	Unban
																</button>
															) : (
																''
															)}
														</div>
													</td>
													<td className='dns_email_item'>{item.name}</td>
													<td className='dns_email_item'>{item.email}</td>
													<td className='dns_email_item'>{item.ipaddress_1}</td>
													<td className='dns_email_item'>{item.ipaddress_2}</td>
												</tr>
											</tbody>
										))}
									</table>
									<div className='float-right mr-4'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											pageRangeDisplayed={4}
											activePage={activePage}
											totalItemsCount={metaData.total}
											itemsCountPerPage={metaData.per_page}
											onChange={pageNumber => handlePageChange(pageNumber)}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default BlockedUsers

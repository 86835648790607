import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LoadingBar from 'react-top-loading-bar'
import React, { useState, useRef } from 'react'
import { logout } from '../../../store/actions/auth.action'
import Alerts from '../../alert_section/Alerts'
import { useEffect } from 'react'
import NotificationService from '../../../api-services/notification.service'

const Header = ({ history }) => {
	const ref = useRef(null)

	const dispatch = useDispatch()

	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [systemNotifications, setSystemNotifications] = useState([])

	const handleLogout = async event => {
		event.preventDefault()
		setLoading(true)
		ref.current.continuousStart()
		try {
			// dispatch logout action
			await dispatch(logout(history))
			history.push('/login')
		} catch (e) {
			setError(e)
		} finally {
			setLoading(false)
			if (ref.current) ref.current.complete()
		}
	}

	const fetchNotifications = async () => {
		const response = await NotificationService.getSystemNotifications()

		if (response.success) {
			setSystemNotifications(response.messages.reverse())
		} else {
			console.log(response.message)
		}
	}

	const markAllNotificationsAsRead = async () => {
		if (
			systemNotifications.filter(
				notification => !notification.view_message && !notification.is_ticket
			).length !== 0
		) {
			const response = await NotificationService.markAllNotificationsAsRead()

			if (response.success) {
				fetchNotifications()
			}
		}
	}

	const markAllTicketsAsRead = async () => {
		if (
			systemNotifications.filter(
				notification => !notification.view_message && notification.is_ticket
			).length !== 0
		) {
			const response = await NotificationService.markAllTicketsAsRead()

			if (response.success) {
				fetchNotifications()
			}
		}
	}

	useEffect(() => {
		fetchNotifications()
	}, [])

	return (
		<>
			<LoadingBar height={5} color='#5D95EA' ref={ref} />
			<nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
				<button
					id='sidebarToggleTop'
					className='btn btn-link d-md-none rounded-circle mr-3'
				>
					<i className='fa fa-bars'></i>
				</button>

				{/* <form className='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
					<div className='input-group'>
						<input
							type='text'
							className='form-control bg-light border-0 small'
							placeholder='Search for...'
							aria-label='Search'
							aria-describedby='basic-addon2'
						/>
						<div className='input-group-append'>
							<button className='btn btn-primary' type='button'>
								<i className='fas fa-search fa-sm'></i>
							</button>
						</div>
					</div>
				</form> */}

				<ul className='navbar-nav ml-auto'>
					<li className='nav-item dropdown no-arrow d-sm-none'>
						<a
							href='#'
							role='button'
							id='searchDropdown'
							aria-haspopup='true'
							aria-expanded='false'
							data-toggle='dropdown'
							className='nav-link dropdown-toggle'
						>
							<i className='fas fa-search fa-fw'></i>
						</a>

						<div
							className='dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in'
							aria-labelledby='searchDropdown'
						>
							<form className='form-inline mr-auto w-100 navbar-search'>
								<div className='input-group'>
									<input
										type='text'
										aria-label='Search'
										placeholder='Search for...'
										aria-describedby='basic-addon2'
										className='form-control bg-light border-0 small'
									/>
									<div className='input-group-append'>
										<button className='btn btn-primary' type='button'>
											<i className='fas fa-search fa-sm'></i>
										</button>
									</div>
								</div>
							</form>
						</div>
					</li>

					<li className='nav-item dropdown no-arrow mx-1'>
						<a
							href='#'
							role='button'
							id='alertsDropdown'
							aria-haspopup='true'
							aria-expanded='false'
							data-toggle='dropdown'
							onClick={markAllNotificationsAsRead}
							className='nav-link dropdown-toggle'
						>
							<i className='fas fa-bell fa-fw'></i>
							{systemNotifications.filter(
								notification =>
									!notification.view_message && !notification.is_ticket
							).length === 0 ? (
								<></>
							) : (
								<span className='badge badge-danger badge-counter'>
									{
										systemNotifications.filter(
											alert => !alert.view_message && !alert.is_ticket
										).length
									}
								</span>
							)}
						</a>
						<Alerts type={'notification'} alerts={systemNotifications} />
					</li>

					<li className='nav-item dropdown no-arrow mx-1'>
						<a
							href='#'
							role='button'
							aria-haspopup='true'
							aria-expanded='false'
							id='messagesDropdown'
							data-toggle='dropdown'
							onClick={markAllTicketsAsRead}
							className='nav-link dropdown-toggle'
						>
							<i className='fas fa-envelope fa-fw'></i>

							{systemNotifications.filter(
								notification =>
									!notification.view_message && notification.is_ticket
							).length === 0 ? (
								<></>
							) : (
								<span className='badge badge-danger badge-counter'>
									{
										systemNotifications.filter(
											alert => !alert.view_message && alert.is_ticket
										).length
									}
								</span>
							)}
						</a>

						<Alerts type={'message'} alerts={systemNotifications} />

						{/* <div
							className='dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in'
							aria-labelledby='messagesDropdown'
						>
							<h6 className='dropdown-header'>Message Center</h6>
							<a className='dropdown-item d-flex align-items-center' href='#'>
								<div className='dropdown-list-image mr-3'>
									<img
										className='rounded-circle'
										src='https://source.unsplash.com/fn_BT9fwg_E/60x60'
										alt=''
									/>
									<div className='status-indicator bg-success'></div>
								</div>
								<div className='font-weight-bold'>
									<div className='text-truncate'>
										Hi there! I am wondering if you can help me with a problem
										I've been having.
									</div>
									<div className='small text-gray-500'>Emily Fowler · 58m</div>
								</div>
							</a>
							<a className='dropdown-item d-flex align-items-center' href='#'>
								<div className='dropdown-list-image mr-3'>
									<img
										className='rounded-circle'
										src='https://source.unsplash.com/AU4VPcFN4LE/60x60'
										alt=''
									/>
									<div className='status-indicator'></div>
								</div>
								<div>
									<div className='text-truncate'>
										I have the photos that you ordered last month, how would you
										like them sent to you?
									</div>
									<div className='small text-gray-500'>Jae Chun · 1d</div>
								</div>
							</a>
							<a className='dropdown-item d-flex align-items-center' href='#'>
								<div className='dropdown-list-image mr-3'>
									<img
										className='rounded-circle'
										src='https://source.unsplash.com/CS2uCrpNzJY/60x60'
										alt=''
									/>
									<div className='status-indicator bg-warning'></div>
								</div>
								<div>
									<div className='text-truncate'>
										Last month's report looks great, I am very happy with the
										progress so far, keep up the good work!
									</div>
									<div className='small text-gray-500'>Morgan Alvarez · 2d</div>
								</div>
							</a>
							<a className='dropdown-item d-flex align-items-center' href='#'>
								<div className='dropdown-list-image mr-3'>
									<img
										className='rounded-circle'
										src='https://source.unsplash.com/Mv9hjnEUHR4/60x60'
										alt=''
									/>
									<div className='status-indicator bg-success'></div>
								</div>
								<div>
									<div className='text-truncate'>
										Am I a good boy? The reason I ask is because someone told me
										that people say this to all dogs, even if they aren't
										good...
									</div>
									<div className='small text-gray-500'>
										Chicken the Dog · 2w
									</div>
								</div>
							</a>
							<a
								className='dropdown-item text-center small text-gray-500'
								href='#'
							>
								Read More Messages
							</a>
						</div> */}
					</li>

					<div className='topbar-divider d-none d-sm-block'></div>

					<li className='nav-item dropdown no-arrow'>
						<a
							href='#'
							role='button'
							id='userDropdown'
							aria-haspopup='true'
							aria-expanded='false'
							data-toggle='dropdown'
							className='nav-link dropdown-toggle'
						>
							<span className='mr-2 d-none d-lg-inline text-gray-600 small'>
								{JSON.parse(localStorage.getItem('user')).name}
							</span>
							<img
								className='img-profile rounded-circle'
								src={`https://ui-avatars.com/api/?name=${
									JSON.parse(localStorage.getItem('user')).name
								}&background=4E73DF&color=fff`}
							/>
						</a>

						<div
							className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
							aria-labelledby='userDropdown'
						>
							<Link className='dropdown-item' to='/profile'>
								<i className='fas fa-user fa-sm fa-fw mr-2 text-gray-400'></i>
								Profile
							</Link>
							{/* <a className='dropdown-item' href='#'>
								<i className='fas fa-cogs fa-sm fa-fw mr-2 text-gray-400'></i>
								Settings
							</a>
							<a className='dropdown-item' href='#'>
								<i className='fas fa-list fa-sm fa-fw mr-2 text-gray-400'></i>
								Activity Log
							</a> */}
							<div className='dropdown-divider'></div>
							<a
								href='#'
								data-toggle='modal'
								onClick={handleLogout}
								className='dropdown-item'
								data-target='#logoutModal'
							>
								<i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
								Logout
							</a>
						</div>
					</li>
				</ul>
			</nav>
		</>
	)
}

export default Header

import React from 'react'
import getSliceColor from './getColor'

const ProgressBar = ({ report }) => {
	return (
		<>
			<div className='row d-flex justify-content-between my-4'>
				<div className='col'>{report == null ? '' : report.name}</div>
				<div className='col-3 text-right'>
					{report == null ? 0 : report.percentage}%
				</div>
			</div>
			<div className='progress' style={{ borderRadius: '15px' }}>
				<div
					className='progress-bar'
					role='progressbar'
					style={{
						width: `${report == null ? 0 : report.percentage}%`,
						backgroundColor: getSliceColor(
							report == null ? 0 : report.percentage
						),
						borderRadius: '15px',
					}}
					aria-valuemin='0'
					aria-valuemax='100'
				>
					{report == null ? 0 : report.percentage}
				</div>
			</div>
		</>
	)
}

export default ProgressBar

import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
	const switchSidebarMode = () => {
		let sidebar = document.getElementById('accordionSidebar')
		let navItems = document.getElementsByClassName('nav-link')
		let sidebarIcons = document.getElementsByClassName('sidebar-icon')

		if (sidebar.classList.contains('toggled')) {
			sidebar.classList.remove('toggled')
			Array.from(navItems)
				.slice(0, 8)
				.forEach(navItem => {
					navItem.classList.add('flex-row')
					navItem.classList.remove('flex-column')
				})
			Array.from(sidebarIcons).forEach(icon => {
				icon.classList.add('mr-2')
			})
		} else {
			sidebar.classList.add('toggled')
			Array.from(navItems)
				.slice(0, 8)
				.forEach(navItem => {
					navItem.classList.remove('flex-row')
					navItem.classList.add('flex-column')
				})
			Array.from(sidebarIcons).forEach(icon => {
				icon.classList.remove('mr-2')
			})
		}
	}

	return (
		<ul
			id='accordionSidebar'
			style={{ transition: 'all 0.2s linear' }}
			className='navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'
		>
			<Link
				to='/dashboard'
				className='sidebar-brand d-flex align-items-center justify-content-center'
			>
				<div className='sidebar-brand-icon'>
					<i className='fas fa-envelope-square'></i>
				</div>
				<div className='sidebar-brand-text mx-3'>Inbox Better</div>
			</Link>

			<hr className='sidebar-divider my-0' />

			<li className='nav-item active'>
				<Link className='nav-link d-flex align-items-center' to='/dashboard'>
					<i className='fas fa-fw fa-tachometer-alt'></i>
					<span>Dashboard</span>
				</Link>
			</li>

			<hr className='sidebar-divider' />

			<div className='sidebar-heading'>Admin</div>

			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#manageUsers'
					aria-controls='manageUsers'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon fas fa-fw fa-users  mr-2'></i>
					<span className='d-flex flex-grow-1'>Users</span>
				</a>
				<div
					id='manageUsers'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Users:</h6>
						<Link className='collapse-item' to='/users'>
							All Users
						</Link>
						<Link className='collapse-item' to='/users/blocked'>
							Blocked Users
						</Link>
						<Link className='collapse-item' to='/affiliate-settings'>
							Affiliate Users setting
						</Link>
					</div>
				</div>
			</li>

			{/* Warm Up Domain */}
			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#warmUpDomain'
					aria-controls='warmUpDomain'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon far fa-envelope mr-2'></i>
					<span className='d-flex flex-grow-1'>Emails</span>
				</a>
				<div
					id='warmUpDomain'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Email Accounts:</h6>
						<Link
							className='collapse-item'
							to='/email-accounts/system-email-accounts'
						>
							System Email Accounts
						</Link>
						<Link className='collapse-item' to='/email-accounts/email-content'>
							Email Content Services
						</Link>
					</div>
				</div>
			</li>

			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#manageJobs'
					aria-controls='manageSettings'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon fas fa-fw fa-cog mr-2'></i>
					<span className='d-flex flex-grow-1'>System Jobs</span>
				</a>
				<div
					id='manageJobs'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Jobs:</h6>
						<Link className='collapse-item' to='/system-jobs'>
							Background Processings
						</Link>
					</div>
				</div>
			</li>

			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#managePlans'
					aria-controls='managePlans'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon far fa-tag mr-2'></i>
					<span className='d-flex flex-grow-1'>Plans</span>
				</a>
				<div
					id='managePlans'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Plans:</h6>
						<Link className='collapse-item' to='/plans'>
							Subscription Plans
						</Link>
					</div>
				</div>
			</li>

			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#manageInvoices'
					aria-controls='manageInvoices'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon far fa-file-invoice-dollar mr-2'></i>
					<span className='d-flex flex-grow-1'>Invoices</span>
				</a>
				<div
					id='manageInvoices'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Invoices:</h6>
						<Link className='collapse-item' to='/invoices/affiliate'>
							Affiliate Invoices
						</Link>
					</div>
				</div>
			</li>

			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#manageFAQs'
					aria-controls='manageInvoices'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon far fa-question-circle mr-2'></i>
					<span className='d-flex flex-grow-1'>FAQ</span>
				</a>
				<div
					id='manageFAQs'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage FAQs:</h6>
						<Link className='collapse-item' to='/faqs'>
							System FAQs
						</Link>
					</div>
				</div>
			</li>

			<li className='nav-item'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#manageConfigurations'
					aria-controls='manageConfigurations'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon far fa-exclamation-triangle mr-2'></i>
					<span className='d-flex flex-grow-1'>Configs</span>
				</a>
				<div
					id='manageConfigurations'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Configs:</h6>
						<Link className='collapse-item' to='/invitation-code'>
							Invitation Code
						</Link>
					</div>
				</div>
			</li>

			<li className='nav-item d-none'>
				<a
					href='#'
					aria-expanded='true'
					data-toggle='collapse'
					data-target='#manageErrorLogs'
					aria-controls='manageErrorLogs'
					className='nav-link collapsed d-flex align-items-center'
				>
					<i className='sidebar-icon far fa-exclamation-triangle mr-2'></i>
					<span className='d-flex flex-grow-1'>Error Logs</span>
				</a>
				<div
					id='manageErrorLogs'
					className='collapse'
					aria-labelledby='headingTwo'
					data-parent='#accordionSidebar'
				>
					<div className='bg-white py-2 collapse-inner rounded'>
						<h6 className='collapse-header'>Manage Error Logs:</h6>
						<Link className='collapse-item' to='/errors'>
							Error Logs
						</Link>
					</div>
				</div>
			</li>

			<div className='text-center d-none d-md-inline'>
				<button
					id='sidebarToggle'
					onClick={switchSidebarMode}
					className='rounded-circle border-0'
				></button>
			</div>
		</ul>
	)
}

export default Sidebar

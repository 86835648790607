import API from './api'

const InvoiceService = {
  list: (pageNumber = 1) => {
    return API.get(`/admin/inovice?page=${pageNumber}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        return err
      })
  },
  get: (id) => {
    return API.get(`/admin/inovice/${id}`)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        return err
      })
  }
}

export default InvoiceService

import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import React, { useEffect, useState } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import SystemFeaturesService from '../../api-services/systemFeatures.service'
import InvoiceService from '../../api-services/invoice.service'

const AffiliateInvoices = () => {
	const [error, setError] = useState('')
	const [errors, setErrors] = useState({})
	const [invoices, setInvoices] = useState([])
	const [loading, setLoading] = useState(false)
	const [activePage, setActivePage] = useState(1)
	const [btnLoading, setBtnLoading] = useState(false)
	const [metaData, setMetaData] = useState({ per_page: 0, total: 0 })

	const fetchInvoices = async () => {
		setLoading(true)
		try {
			let tempMetaDataObj = { per_page: 0, total: 0 }

			const response = await InvoiceService.list()
			tempMetaDataObj.per_page = response.Inovice.per_page
			tempMetaDataObj.total = response.Inovice.total

			setInvoices(response.Inovice.data)

			setMetaData(tempMetaDataObj)
			setLoading(false)
		} catch (error) {
			setLoading(false)
			console.log(error)
		}
	}

	const handlePageChange = async pageNumber => {
		setLoading(true)
		try {
			let tempMetaDataObj = { per_page: 0, total: 0 }

			const response = await InvoiceService.list(pageNumber)
			tempMetaDataObj.per_page = response.Inovice.per_page
			tempMetaDataObj.total = response.Inovice.total

			setInvoices(response.Inovice.data)
			setMetaData(tempMetaDataObj)
			setActivePage(pageNumber)
			if (response.length !== 0) {
				setLoading(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchInvoices()

		return () => {
			setInvoices([])

			setLoading(false)
		}
	}, [])

	return (
		<>
			<div className='row mt-4'>
				<div className='col'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<Link
									to={{
										pathname: `/`,
									}}
								>
									Home
								</Link>
							</li>
							<li className='breadcrumb-item'>Invoices</li>
						</ol>
					</nav>
				</div>
			</div>

			<div className='card shadow mb-4'>
				<div className='card-header py-3'>
					<div className='row'>
						<div className='col-sm-6'>
							<h4 className='m-0 font-weight-bold text-primary'>
								Affiliate Invoices
							</h4>
						</div>
					</div>
				</div>
				<div className='row my-4'>
					<div className='col-sm-12'>
						{loading ? (
							<div
								style={{
									marginLeft: '50%',
									height: '30px',
									marginBottom: '20px',
								}}
							>
								<FadeLoader
									loading={true}
									color={'#0092FB'}
									height={20}
									width={5}
								/>
							</div>
						) : invoices.length === 0 ? (
							<div className='text-center starter-inboxes'>
								<div className='dns_sbg'>
									<img src='/img/inbox-anim-bg.gif' />
								</div>
								<p className='mt-4 sp fw-bold'>
									<b>Admin Panel</b>
								</p>
								<p
									className='sp mt-1'
									style={{ fontSize: '14px', lineHeight: '30px' }}
								>
									Are Your Emails going to Inbox, Promotions or Spam? <br />
									Use our free email deliverability tester to see where your
									<br />
									emails are being delivered.
									<span>Help</span>
								</p>
							</div>
						) : (
							<>
								<div className='table-responsive'>
									<table className='table table-sm'>
										<thead>
											<tr>
												<th className='text-center'>Invoice #</th>
												<th className='text-center'>Name</th>
												<th className='text-center'>
													Amount
													<span className='sort-controls'>
														<img
															className='up'
															src='/img/up-sort.png'
															alt='sortByAscendIcon'
														/>
														<img
															className='down'
															src='/img/down-sort.png'
															alt='sortByDescendIcon'
														/>
													</span>
												</th>
												<th className='text-center'>Actions</th>
											</tr>
										</thead>
										{invoices
											.filter(invoice => invoice.amount !== 0)
											.map((invoice, i) => (
												<tbody key={i}>
													<tr className='text-center'>
														<td className='dns_email_item'>INB-{invoice.id}</td>
														<td className='dns_email_item'>
															{invoice.user !== null ? invoice.user.name : ''}
														</td>
														<td className='dns_email_item'>
															$ {invoice.amount}
														</td>
														<td className='dns_email_item'>
															<Link
																className='user-link'
																to={{
																	pathname: `/invoices/affiliate/${invoice.id}`,
																}}
															>
																<button className='btn btn-info ml-2 btn-circle btn-sm'>
																	<i className='fas fa-eye'></i>
																</button>
															</Link>
														</td>
													</tr>
												</tbody>
											))}
									</table>
									<div className='float-right mr-4'>
										<Pagination
											itemClass='page-item'
											linkClass='page-link'
											pageRangeDisplayed={4}
											activePage={activePage}
											totalItemsCount={metaData.total}
											itemsCountPerPage={metaData.per_page}
											onChange={pageNumber => handlePageChange(pageNumber)}
										/>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default AffiliateInvoices

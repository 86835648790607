import './systemJobs.scss'
import { toast } from 'react-toastify'
import React, { useState, useEffect } from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import CroneJobsService from '../../api-services/croneJobs.service'

const JobsTable = () => {
	const [itemId, setItemId] = useState()
	const [buttons, setButton] = useState([])
	const [loading, setLoading] = useState(false)
	const [jobResponse, setJobResponse] = useState([])
	const [btnLoading, setBtnLoading] = useState(false)
	const [dropdownItems, setDropdownItems] = useState([])

	const fetchData = async () => {
		setLoading(true)
		try {
			const response = await CroneJobsService.getDropdownItems()
			setDropdownItems(response.data)
			setLoading(false)
		} catch (e) {
			console.log(e)
			setLoading(false)
		}
	}

	const handleSelect = e => {
		dropdownItems.map(item => {
			if (item.warmup_type === e.target.value) {
				setItemId(item.id)
				setButton(item.buttons)
			} else if (e.target.value === 'Select Provider') {
				setItemId(undefined)
			}
		})
	}

	const handleButton = async cmd => {
		setBtnLoading(true)
		try {
			const response = await CroneJobsService.sendButtonUrl(cmd)
			setJobResponse(response.message)
			setBtnLoading(false)
			toast.success(response.message, {
				autoClose: 5000,
				draggable: true,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		} catch (e) {
			// console.log(e)
			setBtnLoading(false)
			toast.error(e.response.data.message, {
				autoClose: 5000,
				draggable: true,
				closeOnClick: true,
				position: 'top-right',
				hideProgressBar: false,
			})
		}
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			{loading ? (
				<div
					style={{
						marginLeft: '50%',
						height: '30px',
						marginBottom: '20px',
					}}
				>
					<FadeLoader loading={true} color={'#0092FB'} height={20} width={5} />
				</div>
			) : (
				<>
					<div className='row'>
						<div className='col-12'>
							<select onChange={handleSelect} className='dropdown-jobs'>
								<option>Select Provider</option>
								{dropdownItems.map(item => (
									<option id={item.id}>{item.warmup_type}</option>
								))}
							</select>
						</div>
					</div>
					<div className='row mt-4'>
						{itemId != undefined &&
							Object.keys(buttons).map(btn => (
								<div className='col-sm-12 my-3'>
									<button
										className='btn btn-sm job-btn'
										onClick={() => handleButton(buttons[btn])}
										disabled={btnLoading}
									>
										{btn === 'sync_marketing_campaign'
											? 'Sync Marketing Campaign'
											: btn === 'track_marketing_stats'
											? 'Track Marketing Stats'
											: btn === 'warmup_schedule_command'
											? 'Warmup Schedule Command'
											: ''}
									</button>
								</div>
							))}
					</div>
				</>
			)}
		</>
	)
}

export default JobsTable

import API from './api'

const ErrorLogService = {
	list: (pageNumber = 1) => {
		return API.get(`/admin/system-logs?page=${pageNumber}`)
			.then(({ data }) => {
				return data
			})
			.catch(err => {
				return err
			})
	}
}

export default ErrorLogService

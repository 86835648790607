import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./style.scss";

const LogsPanel = ({ show, setShow, accounts, id }) => {
  const node = useRef();
  const [logs, setLogs] = useState([]);

  const handleClosePanel = () => {
    setShow(false);
  };

  const handleClick = (e) => {
    if (node.current.contains(e.target)) {
      return;
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    if (show) {
      let open = document.querySelector(".email-view");
      open.classList.remove("close-view");
      open.classList.add("email-view-open");
    } else {
      let close = document.querySelector(".email-view");
      close.classList.remove("email-view-open");
      close.classList.add("close-view");
    }
  }, [show]);

  useEffect(() => {
    accounts.map((acc) => {
      if (acc.id == id) {
        setLogs(acc.logs);
      }
    });
  }, [id]);
  console.log(id, "iddddd");
  console.log(logs, "logssss");
  return (
    <>
      <div className="container email-view" ref={node}>
        <div
          style={{
            backgroundColor: "#0994F8",
            height: "50px",
            paddingTop: "10px",
          }}
        >
          <span
            className="float left ml-4"
            style={{ color: "white", fontSize: "20px" }}
          >
            <i
              className="far fa-question-circle mr-3"
              style={{
                color: "white",
                fontSize: "25px",
                fontWeight: "lighter",
              }}
            ></i>
            Logs
          </span>
          <span
            id="closeBtn"
            onClick={handleClosePanel}
            className="float-right mr-4"
          >
            <i
              className="fas fa-times"
              style={{
                color: "white",
                fontSize: "25px",
                fontWeight: "lighter",
              }}
            ></i>
          </span>
        </div>
        <div className="main-container mt-n5">
          <ul className="logs">
            {logs.map((log) => (
              <li key={log.id}>
                {log.exception} [{moment(log.created_at).toString()}]
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <ul className="account-log" id={"log-" + acc.id}>
          {acc.logs.map((log) => (
            <li key={log.id}>
              {log.exception} [{moment(log.created_at).toString()}]
            </li>
          ))}
        </ul> */}
    </>
  );
};

export default LogsPanel;

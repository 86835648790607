import API from './api'

const SettingsService = {
  updateProfile: (data) => {
    return API.put(`/admin/settings/profile`, data)
      .then(({ data }) => {
        return data
      })
      .catch(err => {
        throw err
      })
  }
}

export default SettingsService
